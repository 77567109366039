const config = {
  development: {
    firebaseConfig: {
      apiKey: 'AIzaSyAOk_D9OcSPDyE82Qp17gZL_9CKrGQ3xIE',
      authDomain: 'merajuthati-46807.firebaseapp.com',
      projectId: 'merajuthati-46807',
      storageBucket: 'merajuthati-46807.appspot.com',
      messagingSenderId: '256466087612',
      appId: '1:256466087612:web:ab6460c28f423fa3d42ab9',
      measurementId: 'G-C3FJKGFC8L'
    }
  },
  production: {
    firebaseConfig: {
      apiKey: 'AIzaSyAOk_D9OcSPDyE82Qp17gZL_9CKrGQ3xIE',
      authDomain: 'merajuthati-46807.firebaseapp.com',
      projectId: 'merajuthati-46807',
      storageBucket: 'merajuthati-46807.appspot.com',
      messagingSenderId: '256466087612',
      appId: '1:256466087612:web:ab6460c28f423fa3d42ab9',
      measurementId: 'G-C3FJKGFC8L'
    }
  }
} as any;

export default config;
