import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './CustomCard.module.css';

interface CustomCardProp {
  className?: string;
  imgSrc: string;
  title: string;
  desc: string;
  onClick(): void;
}

class CustomCard extends React.Component<CustomCardProp> {
  render() {
    return (
      <div className={styles.card + ' ' + this.props.className}>
        <div className={styles.imgBackground}>
          <img src={this.props.imgSrc} className={styles.cardImage} alt={'Profile picture of ' + this.props.title} />
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button} onClick={this.props.onClick}>View profile</div>
        </div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id='custom-card-tooltip'>{this.props.title}</Tooltip>}
        >
          <div className={styles.title}>{this.props.title}</div>
        </OverlayTrigger>
        <div className={styles.desc}>{this.truncateString(this.props.desc)}</div>
        {this.props.children}
      </div>
    );
  }

  private truncateString(input: string, maxLength = 120): string {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input;
  }
}

export default CustomCard;
