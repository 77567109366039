import React from 'react';
import styles from './IconButton.module.css';

interface Option {
  disable?: boolean;
  height?: string;
  width?: string;
}

interface IconButtonProp {
    alt: string;
    option?: Option;
    src: string;
    onClick(): void;
}
class IconButton extends React.Component<IconButtonProp> {
    iconButtonClassName() {
      const disable = this.props.option?.disable ? styles.disable : '';
      return `${styles.clickable} ${disable}`;
    }

    imageClassName() {
      const height = this.props.option?.height ? this.props.option.height : '';
      const width = this.props.option?.width ? this.props.option.width : '';
      return `${styles.icon} ${height} ${width}`;
    }

    render() {
        return (
            <div onClick={() => this.props.onClick.apply(undefined)} className={this.iconButtonClassName()}>
                <img src={this.props.src} alt={this.props.alt} className={this.imageClassName()}/>
            </div>
        );
    }
}

export default IconButton;
