import { gql } from '@apollo/client';

const GET_COST_DISBURSEMENT_BY_DATERANGE = gql`
query GetCostDisbursement($start: DateTime, $end: DateTime) {
  costDisbursements(where: { date_gte: $start, date_lte: $end }) {
      date ,
      description,
      category,
      amount,
      currency
  }
}
`;

export default GET_COST_DISBURSEMENT_BY_DATERANGE;
