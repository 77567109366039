import React from 'react';
import styles from './CustomFormToggle.module.css';

interface Option {
  option1: string,
  option2: string,
  height?: string,
  width?: string
}

interface CustomFormToggleProps {
  errorMessage?: string;
  name: string;
  value: string;
  option: Option;
  onChange: (type: string, input: CustomFormToggleState) => void;
  validator?: (input: string) => boolean;
}

interface CustomFormToggleState {
  isValid: boolean,
  isDirty: boolean,
  value: string
}

class CustomFormToggle extends React.Component<CustomFormToggleProps, CustomFormToggleState> {
  constructor(props: CustomFormToggleProps) {
    super(props);
    this.state = {
      isDirty: false,
      isValid: true,
      value: ''
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  private containerClassNames() {
    const container = this.props.option ? '' : styles.container;
    const height = this.props.option ? this.props.option.height : '';
    const width = this.props.option ? this.props.option.width : '';
    return `${container} ${height} ${width}`;
  }

  private toggleWrapperClassNames() {
    const error = !this.state.isValid ? styles.errorToggleWrapper : '';
    return `${styles.toggleWrapper} ${error}`;
  }

  private separatorClassNames() {
    let error;
    if (this.state.isDirty && this.state.isValid) {
      error = styles.activeSeparator;
    } else if (this.state.isDirty) {
      error = styles.errorSeparator;
    }
    return `${styles.separator} ${error}`;
  }

  private toggleOptionClassNames(position: string) {
    const activeToggle = position === this.state.value ? styles.activeToggle : '';
    const borderPosition = position === this.props.option.option1 ? styles.toggleFirst : styles.toggleSecond;
    const error = !this.state.isValid ? styles.errorToggle : '';
    return `${styles.toggleOption} ${activeToggle} ${borderPosition} ${error}`;
  }

  private errorMessage() {
    return (
      <div className={styles.errorMessage}>{this.props.errorMessage}</div>
    );
  }

  private handleOnChange() {
    this.props.onChange(this.props.name, this.state);
  }

  render() {
    return (
      <div className={this.containerClassNames()}>
        <label
          className={this.toggleWrapperClassNames()}
          onSubmit={() => {
            this.setState({
              isValid: this.props.validator ? this.props.validator(this.state.value) : true
            }, this.handleOnChange);
          }}
        >
          <span
            className={this.toggleOptionClassNames(this.props.option.option1)}
            onClick={() => {
              this.setState({
                isDirty: true,
                isValid: true,
                value: this.props.option.option1
              }, this.handleOnChange);
            }}
          >
            {this.props.option.option1}
          </span>
          <div className={this.separatorClassNames()}></div>
          <span
            className={this.toggleOptionClassNames(this.props.option.option2)}
            onClick={() => {
              this.setState({
                isDirty: true,
                isValid: true,
                value: this.props.option.option2
              }, this.handleOnChange);
            }}
          >
            {this.props.option.option2}
          </span>
        </label>
        {!this.state.isValid ? this.errorMessage() : undefined}
      </div>
    );
  }
}

export default CustomFormToggle;

