import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TermsAndAgreement.module.css';

interface TermsAndAgreementProp {
  i18n: any;
}


class TermsAndAgreement extends React.Component<TermsAndAgreementProp> {
  render() {
    return (
      <div className='page-container'>
        <h1 className={styles.header}>{this.props.i18n.t('terms-and-agreement:title')}</h1>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header1')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description1Part1')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description1Part2')}</div>
        <div>{this.props.i18n.t('terms-and-agreement:description1Part3')}</div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header2')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description2')}</div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header3')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description3')}</div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header4')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description4')}</div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header5')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description5')}</div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header6')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description6Part1')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description6Part2')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description6Part3')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description6Part4')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description6Part5')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description6Part6')}</div> <br />

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header7')}</h2>
        <div>
          {this.props.i18n.t('terms-and-agreement:description7Part1')}
          <ul>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part2')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part3')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part4')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part5')}
            </li>
          </ul>
        </div>
        <div>
          {this.props.i18n.t('terms-and-agreement:description7Part6')}
          <ul>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part7')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part8')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part9')}
            </li>
          </ul>
        </div>
        <div>
          {this.props.i18n.t('terms-and-agreement:description7Part10')}
          <ul>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part11')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part12')}
            </li>
            <li>
              {this.props.i18n.t('terms-and-agreement:description7Part13')}
            </li>
          </ul>
        </div>
        <div>{this.props.i18n.t('terms-and-agreement:description7Part14')}</div>
        <div>
          {this.props.i18n.t('terms-and-agreement:description7Part15')}
          <Link to={{ pathname: 'mailto: contact@merajuthati.org' }} target='_blank'>
            {this.props.i18n.t('terms-and-agreement:description7Part16')}
          </Link>
          {this.props.i18n.t('terms-and-agreement:description7Part17')}
        </div>

        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header8')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description8')}</div>


        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header9')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description9')}</div>


        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header10')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description10')}</div>


        <h2 className={styles.subHeader}>{this.props.i18n.t('terms-and-agreement:header11')}</h2>
        <div>{this.props.i18n.t('terms-and-agreement:description11Part1')}</div> <br />
        <div>{this.props.i18n.t('terms-and-agreement:description11Part2')}</div>
      </div>
    );
  }
}

export default TermsAndAgreement;
