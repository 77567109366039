import { gql } from '@apollo/client';

export const GET_CLIENTS_BY_ID = gql(`
  query GetClients($id: String) {
    clients(
      where: { id_eq: $id }
    ) {
      name,
      age,
      description,
      raised,
      status,
      story,
      goal,
      numberOfSponsors,
      profilePicture {
        name,
        url
      },
      isComplete,
      cost_breakdowns {
        programType,
        amount,
        category
      },
      client_updates {
        title,
        description,
        date
      }
    }
  }
`);

export const GET_CLIENTS_BY_ISCOMPLETE = gql(`
  query GetClients($limit: Int, $isComplete: Boolean) {
    clients(
      limit: $limit,
      where: { isComplete_eq: $isComplete }
    ) {
      name,
      age,
      description,
      raised,
      goal,
      numberOfSponsors,
      profilePicture {
        name,
        url
      },
      isComplete
    }
  }
`);
