import React from 'react';
import styles from './TextButton.module.css';

interface TextButtonProps {
  id: string;
  type: 'button' | 'submit';
  label: string
  onClick?(): void;
  ariaLabel: string;
}

class TextButton extends React.Component<TextButtonProps> {
  render() {
    return (
      <button
        id={this.props.id}
        type={this.props.type}
        aria-label={this.props.ariaLabel}
        className={styles.textBtn}
        onClick={this.props.onClick}>
        {this.props.label}
      </button>
    );
  }
}

export default TextButton;
