import React, { createRef } from 'react';
import styles from './ExpansionPanel.module.css';

interface ExpansionPanelProps {
  header: string;
}

interface ExpansionPanelState {
  expand: boolean;
}

class ExpansionPanel extends React.Component<ExpansionPanelProps, ExpansionPanelState> {
    arrowRef: React.RefObject<HTMLImageElement>
    bodyRef: React.RefObject<HTMLDivElement>

    constructor(props: ExpansionPanelProps) {
      super(props);
      this.state = {
        expand: false
      };
      this.arrowRef = React.createRef();
      this.bodyRef = React.createRef();
    }

    toggleExpand = () => {
      if (!this.state.expand) {
        this.arrowRef.current!.className = styles.imageExpanded;
        this.bodyRef.current!.className = styles.bodyExpanded;
        this.setState ({
          expand: true
        });
      } else {
        this.arrowRef.current!.className = styles.imageNotExpanded;
        this.bodyRef.current!.className = styles.bodyNotExpanded;
        this.setState ({
          expand: false
        });
      }
    }

    render() {
        return (
            <div>
              <div className={styles.header}>
                <div className={styles.row}  onClick={this.toggleExpand} >
                  {this.props.header}
                  <img className={styles.image} src={process.env.PUBLIC_URL + '/assets/icons/dropdown.svg'} ref={this.arrowRef}/>
                </div>
                <div className={styles.body} ref={this.bodyRef}>
                  {this.props.children}
                </div>
              </div>
            </div>
        );
    }
}

export default ExpansionPanel;
