import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './ThankYou.module.css';
import NewsletterPopup from '../../components/newsletter/popup/NewsletterPopup';

interface ThankYouProp {
  i18n: any;
}

class ThankYou extends React.Component<ThankYouProp> {
  donation: any;
  name: any;
  newsletterPopupRef: any;

  constructor(props: ThankYouProp) {
    super(props);
    const url = new URL(window.location.toString());
    const params = new URLSearchParams(url.search);
    if (params.has('name')) {
      this.name = params.get('name');
    }
    if (params.has('donate')) {
      this.donation = params.get('donate');
    }
    this.newsletterPopupRef = React.createRef();
  }

  render() {
    return (
      <div className='page-container'>
        <h1 className='header-1'>
          {this.props.i18n.t('thank-you:title')}
          {this.name}!
        </h1>
        <Row className={styles.reverseWrap}>
          <Col xs="12" lg="6">
            <div className={styles.donation}>
              {this.props.i18n.t('thank-you:donation1')}
              &nbsp;
              <span className={styles.donate}>IDR {this.donation}</span>
              &nbsp;
              {this.props.i18n.t('thank-you:donation2')}
            </div>
            <div className={styles.update}>
              {this.props.i18n.t('thank-you:update')}
            </div>
            <Col className={styles.col}>
              <Link to={{ pathname: 'https://www.instagram.com/yayasanmerajuthati/' }}
                target="_blank"
                className={styles.button}>
                <img className={styles.thankYouIcons}
                  src={process.env.PUBLIC_URL + '/assets/icons/instagram-primary.svg'}
                  alt="instagram icon" />
                @yayasanmerajuthati
              </Link>
              <div onClick={() => this.showNewsletterPopup()} className={styles.button}>
                <img className={styles.thankYouIcons}
                  src={process.env.PUBLIC_URL + '/assets/icons/newsletter-primary.svg'}
                  alt="newsletter icon" />
                {this.props.i18n.t('thank-you:newsletter')}
              </div>
            </Col>
          </Col>
          <Col xs="12" lg="6">
            <img className={styles.thankYouImage} src={process.env.PUBLIC_URL + '/assets/img/thank-you/thank-you-illust.svg'}
              alt='Thank you image' />
          </Col>
        </Row>
        {<NewsletterPopup ref={this.newsletterPopupRef} isPopupShown={false} i18n={this.props.i18n} />}
      </div>
    );
  }

  private showNewsletterPopup = () => {
    this.newsletterPopupRef.current.handleShow();
  }
}

export default ThankYou;
