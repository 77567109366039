export const breakpoints = {
  small: 577,
  medium: 992,
  large: 1200,
  xlarge: Infinity,
};

export enum Breakpoint {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge'
}
