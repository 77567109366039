import { gql } from '@apollo/client';

export const GET_PROVIDER_BY_ID = gql`
query GetProviderDetails($id: String) {
  providers(where: {id: $id}) {
    acceptInsurance,
    address,
    age,
    description,
    email,
    ethnicity,
    gender,
    language,
    licensure,
    location,
    locationLink,
    name,
    phone,
    profilePicture {
      name,
      url
    },
    religion,
    specialties {
      description
    },
    treatmentModality,
    treatments {
      type
    },
    website
  }
}
`;
