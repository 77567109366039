import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_MH_STRAPI_ENDPOINT + '/graphql',
  cache: new InMemoryCache()
});

export default apolloClient;
