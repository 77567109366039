import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './ContactUs.module.css';
import ExpandableButton from '../../components/expandable-button/ExpandableButton';
import { Link } from 'react-router-dom';

interface ContactUsProps {
  i18n: any;
}



class ContactUs extends React.Component<ContactUsProps> {

  render() {
    return (
      <div className='page-container'>
        <h1 className={styles.title}>{this.props.i18n.t('contact-us:title')}</h1>
        <Row className={styles.reverseWrap}>
          <Col xs={12} lg={6}>
            <div>
              <p className={styles.sectionDescription1}>{this.props.i18n.t('contact-us:description1')}
                <br />
                {this.props.i18n.t('contact-us:description2')}
              </p>
            </div>

            <div>
              <p className={styles.sectionDescription2}>{this.props.i18n.t('contact-us:section1.description1')}</p>
            </div>

            <div className={styles.buttonRow}>
              <Link to={{ pathname: 'mailto: contact@merajuthati.org' }} target='_blank'>
                <ExpandableButton id='contact-us-email' ariaLabel='Contact Us Email'
                  imgSrc={process.env.PUBLIC_URL + '/assets/img/contact-us/email.svg'}
                  imgAlt='email-image'
                  firstLine='Email' secondLine='contact@merajuthati.org'>
                </ExpandableButton>
              </Link>
              <Link to={{ pathname: 'https://www.instagram.com/yayasanmerajuthati/' }} target='_blank'>
                <ExpandableButton id='contact-us-instagram' ariaLabel='Contact Us Instagram'
                  imgSrc={process.env.PUBLIC_URL + '/assets/img/contact-us/instagram.svg'}
                  imgAlt='instagram-image'
                  firstLine='Instagram' secondLine='@yayasanmerajuthati' >
                </ExpandableButton>
              </Link>
              <Link to={{ pathname: 'https://www.facebook.com/yayasan.merajut.hati' }} target='_blank'>
                <ExpandableButton id='contact-us-facebook' ariaLabel='Contact Us Facebook'
                  imgSrc={process.env.PUBLIC_URL + '/assets/img/contact-us/facebook.svg'}
                  imgAlt='facebook-image'
                  firstLine='Facebook' secondLine='Merajut Hati' >
                </ExpandableButton>
              </Link>
            </div>
          </Col>
          <Col>
            <img className={styles.contactUsImage} src={process.env.PUBLIC_URL + '/assets/img/contact-us/screening-test-landing.svg'}
              alt='contact-us-image' />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContactUs;
