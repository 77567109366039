import React from 'react';
import styles from './Spinner.module.css';

interface SpinnerProps {
  option?: Option
}

interface Option {
  height?: string;
  width?: string;
  border?: string;
}
class Spinner extends React.Component<SpinnerProps> {
  spinnerClassNames = () => {
    const height = this.props.option ? this.props.option.height : styles.spinnerHeight;
    const width = this.props.option ? this.props.option.width : styles.spinnerWidth;
    const border = this.props.option ? this.props.option.border : styles.spinnerBorder;
    return `${styles.spinner} ${height} ${width} ${border}`;
  }

  render() {
    return (
      <div className={this.spinnerClassNames()}></div>
    );
  }
}

export default Spinner;
