import 'bootstrap/dist/css/bootstrap.min.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { BreakpointsProvider } from 'react-with-breakpoints';
import config from './config';
import './index.css';
import MerajutHati from './MerajutHati';
import reportWebVitals from './reportWebVitals';
import LoadingScreen from './pages/loading-screen/LoadingScreen';
import { breakpoints } from './utils/breakpoint';

const appConfig = config[process.env.NODE_ENV];

const firebaseConfig = appConfig['firebaseConfig'];
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <Suspense fallback={<LoadingScreen />}>
    <BrowserRouter>
      <BreakpointsProvider breakpoints={breakpoints}>
        <MerajutHati />
      </BreakpointsProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
