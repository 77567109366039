import React from 'react';

interface CheckProp {
  className?: string;
}

class Cross extends React.Component<CheckProp> {
  render() {
    return (
      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.316956 0.347749C0.752956 -0.0982512 1.35996 -0.133251 1.89296 0.347749L5.80096 4.09475L9.70896 0.347749C10.242 -0.133251 10.85 -0.0982512 11.283 0.347749C11.719 0.792749 11.691 1.54475 11.283 1.96275C10.877 2.38075 6.58796 6.46475 6.58796 6.46475C6.48582 6.5706 6.36341 6.65479 6.22802 6.71229C6.09263 6.7698 5.94705 6.79943 5.79996 6.79943C5.65286 6.79943 5.50728 6.7698 5.37189 6.71229C5.2365 6.65479 5.11409 6.5706 5.01196 6.46475C5.01196 6.46475 0.724956 2.38075 0.316956 1.96275C-0.0920442 1.54475 -0.119044 0.792749 0.316956 0.347749Z" fill="#38383B" />
        <path d="M11.2827 10.4517C10.8467 10.8977 10.2397 10.9327 9.70665 10.4517L5.79865 6.70469L1.89065 10.4517C1.35765 10.9327 0.749653 10.8977 0.316653 10.4517C-0.119347 10.0067 -0.0913466 9.25469 0.316653 8.83669C0.722654 8.41869 5.01165 4.33469 5.01165 4.33469C5.11379 4.22884 5.2362 4.14465 5.37159 4.08714C5.50698 4.02964 5.65256 4.00001 5.79965 4.00001C5.94675 4.00001 6.09233 4.02964 6.22772 4.08714C6.36311 4.14465 6.48552 4.22884 6.58765 4.33469C6.58765 4.33469 10.8747 8.41869 11.2827 8.83669C11.6917 9.25469 11.7187 10.0067 11.2827 10.4517Z" fill="#38383B" />
      </svg>
    );
  }
}

export default Cross;
