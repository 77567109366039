import { arc, pie, select } from 'd3';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './DonutChart.module.css';

interface DonutChartProps {
  colorCategory: { [key: string]: any };
  donutChartData: DonutChartData[];
  selectedCategory: string;
}

interface DonutChartData {
  amount: number;
  category: string;
  color?: string;
}

class DonutChart extends React.Component<DonutChartProps> {
  render() {
    const { colorCategory, donutChartData, selectedCategory } = this.props;
    console.log(colorCategory, donutChartData, selectedCategory);
    const height = 300;
    const width = 350;
    const donutRadius = height / 2;
    const centerX = width / 2;
    const textBoxWidth = 150;
    const textBoxHeight = 80;
    const color = new Map(Object.entries(colorCategory));

    const clonedData = donutChartData.map(a => ({ ...a }));
    const filteredData = clonedData.filter((datum: DonutChartData, index: number, self: DonutChartData[]) => {
      const firstOfTypeIndex = self.findIndex((t) => t.category === datum.category);
      const duplicateFilter = index !== firstOfTypeIndex;
      const categoryFilter = datum.category !== selectedCategory && selectedCategory !== 'all_expenses';
      if (duplicateFilter || categoryFilter) {
        self[firstOfTypeIndex].amount += datum.amount;
        return false;
      }
      self[firstOfTypeIndex].color = color.get(datum.category);
      return true;
    });

    if (filteredData.length === 0) {
      filteredData[0] = { amount: 1, category: '', color: '#D9D9D9' }; /* default gray color */
    }

    const totalData = filteredData.reduce((accumulator: number, datum: DonutChartData) => accumulator + datum.amount, 0);
    const processedData = pie()(filteredData.map((datum: DonutChartData) => datum.amount));
    const totalCost = filteredData[0].category !== '' ? totalData : 0;

    return (
      <div className={styles.chartContainer}>
        <div className={styles.donutChartContainer}>
          <svg className={styles.donutChart} viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <g transform={`translate(${centerX}, ${donutRadius})`}>
              <foreignObject x={-(textBoxWidth / 2)} y={-(textBoxHeight / 2)} width={textBoxWidth} height={textBoxHeight}>
                <div className={styles.totalCost}>
                  <div>Total Cost</div>
                  <div className={styles.totalCostLabel}>{`IDR${this.formatNumber(totalCost)}`}</div>
                </div>
              </foreignObject>
              {processedData.map((datum, index) => { /* Donut Chart sections */
                const cleanedDatum = {
                  'startAngle': datum.startAngle + (Math.PI),
                  'endAngle': datum.endAngle + (Math.PI),
                  'innerRadius': donutRadius - (donutRadius * 2 / 5),
                  'outerRadius': donutRadius
                };
                const centerPoint = arc().centroid(cleanedDatum);
                const fillColor = filteredData[index].color;
                const percentageNumber = filteredData[index].category !== '' ? `${Math.round(datum.value / totalData * 100)}%` : null;

                return (
                  <g key={index}>
                    <path d={`${arc()(cleanedDatum)}`} fill={fillColor}></path>
                    <text className={styles.percentage} x={centerPoint[0]} y={centerPoint[1]} color='white'
                      dominantBaseline='middle' textAnchor='middle'>{percentageNumber}</text>
                  </g>
                );
              })}
            </g>
          </svg>
        </div>
        <div className={styles.legendContainer}>
          {filteredData[0].category !== '' ? this.donutChartLegend(filteredData) : null}
        </div>
      </div>
    );
  }

  private donutChartLegend = (data: DonutChartData[]) => {
    return (
      <div>
        <Row className={styles.legendHeader}>
          <Col className={styles.legendHeaderText}>
            Legend
          </Col>
          <Col className={styles.colorBoxContainer}>
            {data.map((datum, index) => {
              return <div key={index} style={{ backgroundColor: datum.color }} className={styles.colorBox}></div>;
            })}
          </Col>
        </Row>
        <div className={styles.legendContent}>
          {data.map((datum, index) => {
            return (
              <div key={index} className={styles.legendContentText}>
                <div style={{ color: datum.color }}>
                  {datum.category.split('_').map(a => a[0].toUpperCase() + a.substring(1)).join(' ')}
                </div>
                <div>
                  IDR{this.formatNumber(datum.amount)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  private formatNumber(amount: number) {
    return amount.toLocaleString();
  }
}

export default DonutChart;
