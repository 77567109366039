import React from 'react';
import styles from './LoadingScreen.module.css';

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className={styles.contain}>
        <div className={styles.spin}></div>
      </div>
    );
  }
}

export default LoadingScreen;
