import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ShowAt } from 'react-with-breakpoints';
import ExpansionPanel from '../../components/expansion-panel/ExpansionPanel';
import styles from './Faq.module.css';


enum FaqCategory {
  GENERAL = 'General',
  GET_INVOLVED = 'Get Involved',
  FINANCE = 'Finance',
  SPONSORSHIP = 'Sponsorship'
}

interface FaqProps {
  i18n: any;
}

interface FaqState {
  category: FaqCategory;
}

class Faq extends React.Component<FaqProps, FaqState> {
  constructor(props: FaqProps) {
    super(props);
    this.state = {
      category: FaqCategory.GENERAL
    };
  }

  render() {
    const CATEGORIES = Object.values(FaqCategory);

    return (
      <div className='page-container'>
        <div className={styles.headerContainer}>
          <h1 className={styles.title}>Frequently Asked Questions</h1>
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/faq/faq.svg`} alt='faq-img'
            className={styles.headerImg} />
        </div>
        <Row>
          {/* Tablet and PC */}
          <ShowAt breakpoint='mediumAndAbove'>
            <Col xs={3} className={styles.categoryContainer}>
              {
                CATEGORIES.map(category => {
                  return (
                    <div
                      key={category}
                      className={this.getCategoryClassName(category)}
                      onClick={() => this.setState({ category })}>
                      {this.getTranslatedCategory(category)}
                    </div>
                  );
                })
              }
            </Col>
            <Col xs={9}>
              {this.getQnAByCategory(this.state.category)}
            </Col>
          </ShowAt>
          {/* Smartphone */}
          <ShowAt breakpoint='small'>
            <Col xs={12} className={styles.categoryContainer}>
              {
                CATEGORIES.map(category => {
                  return (
                    <div key={category} >
                      <h3>{this.getTranslatedCategory(category)}</h3>
                      {this.getQnAByCategory(category)}
                    </div>
                  );
                })
              }
            </Col>
          </ShowAt>
        </Row>
      </div >
    );
  }

  private getCategoryClassName(category: FaqCategory) {
    return `${styles.category} ${this.getActiveClassName(category)}`;
  }

  private getActiveClassName(category: FaqCategory) {
    return this.state.category === category ? styles.categoryActive : null;
  }

  private getQnAByCategory(category: FaqCategory) {
    switch (category) {
      case FaqCategory.GET_INVOLVED:
        return this.getGetInvolvedQnA();
      case FaqCategory.FINANCE:
        return this.getFinanceQnA();
      case FaqCategory.SPONSORSHIP:
        return this.getSponsorshipQnA();
      default:
        return this.getGeneralQnA();
    }
  }

  private getGeneralQnA() {
    return (
      <>
        <ExpansionPanel header={this.props.i18n.t('faq:generalQuestion1')}>
          {this.props.i18n.t('faq:generalAnswer1Part1')}
          <br /><br />
          {this.props.i18n.t('faq:generalAnswer1Part2')}
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:generalQuestion2')}>
          {this.props.i18n.t('faq:generalAnswer2Part1')}
          &nbsp;<Link to='/aboutmentalhealth'>{this.props.i18n.t('faq:generalAnswer2Part2')}</Link>.
        </ExpansionPanel>
      </>
    );
  }

  private getGetInvolvedQnA() {
    return (
      <>
        <ExpansionPanel header={this.props.i18n.t('faq:getInvolvedQuestion1')}>
          {this.props.i18n.t('faq:getInvolvedAnswer1Part1')}
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:getInvolvedQuestion2')}>
          {this.props.i18n.t('faq:getInvolvedAnswer2Part1')}
          <br /><br />
          {this.props.i18n.t('faq:getInvolvedAnswer2Part2')}
        </ExpansionPanel>

        <ExpansionPanel
          header={this.props.i18n.t('faq:getInvolvedQuestion3')}>
          {this.props.i18n.t('faq:getInvolvedAnswer3Part1')} <Link to={{ pathname: 'mailto: contact@merajuthati.org' }}
            target='_blank'>{this.props.i18n.t('faq:getInvolvedAnswer3Part2')}</Link>{this.props.i18n.t('faq:getInvolvedAnswer3Part3')}
        </ExpansionPanel>
      </>
    );
  }

  private getFinanceQnA() {
    return (
      <>
        <ExpansionPanel header={this.props.i18n.t('faq:financeQuestion1')}>
          {this.props.i18n.t('faq:financeAnswer1Part1')}
          <br /><br />
          {this.props.i18n.t('faq:financeAnswer1Part2')} <Link to='/fundothers'>{this.props.i18n.t('faq:financeAnswer1Part3')}</Link>.&nbsp;
          {this.props.i18n.t('faq:financeAnswer1Part4')} <Link to='/donate'>{this.props.i18n.t('faq:financeAnswer1Part5')}</Link>.
          <br /><br />
          {this.props.i18n.t('faq:financeAnswer1Part6')}
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:financeQuestion2')}>
          {this.props.i18n.t('faq:financeAnswer2Part1')}
          <br /><br />
          <ul>
            <li>{this.props.i18n.t('faq:financeAnswer2Part2')}</li>
            <li>{this.props.i18n.t('faq:financeAnswer2Part3')}</li>
            <li>{this.props.i18n.t('faq:financeAnswer2Part4')}</li>
            <li>{this.props.i18n.t('faq:financeAnswer2Part5')}</li>
            <li>{this.props.i18n.t('faq:financeAnswer2Part6')}</li>
          </ul>

          {this.props.i18n.t('faq:financeAnswer2Part7')} <Link to='/donate'>{this.props.i18n.t('faq:financeAnswer2Part8')}</Link>.
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:financeQuestion3')}>
          {this.props.i18n.t('faq:financeAnswer3Part1')}
        </ExpansionPanel>
      </>
    );
  }

  private getSponsorshipQnA() {
    return (
      <>
        <ExpansionPanel header={this.props.i18n.t('faq:sponsorshipQuestion1')}>
          {this.props.i18n.t('faq:sponsorshipAnswer1Part1')}
          <br /><br />
          {this.props.i18n.t('faq:sponsorshipAnswer1Part2')}
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:sponsorshipQuestion2')}>
          {this.props.i18n.t('faq:sponsorshipAnswer2Part1')}
        </ExpansionPanel>

        <ExpansionPanel header={this.props.i18n.t('faq:sponsorshipQuestion3')}>
          {this.props.i18n.t('faq:sponsorshipAnswer3Part1')}
        </ExpansionPanel>
      </>
    );
  }

  private getTranslatedCategory(category: FaqCategory) {
    switch (category) {
      case FaqCategory.GET_INVOLVED:
        return this.props.i18n.t('faq:getInvolved');
      case FaqCategory.FINANCE:
        return this.props.i18n.t('faq:finance');
      case FaqCategory.SPONSORSHIP:
        return this.props.i18n.t('faq:sponsorship');
      default:
        return this.props.i18n.t('faq:general');
    }
  }
}

export default Faq;
