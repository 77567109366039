import React from 'react';

interface GeneralDonationProfileProp {
  className?: string;
}

class GeneralDonationProfile extends React.Component<GeneralDonationProfileProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label="general-donation-profile" viewBox="0 0 112 136" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3093:3517)">
          <rect width="112px" height="136px" rx="12" fill="#B4D1C5" />
          <ellipse cx="24.5" cy="29.5" rx="68.5" ry="69.5" fill="#EEE9B6" />
          <path d="M115.743 114.47C90.7188 107.26 69.1903 87.5069 54.2333 66.601C37.4979 43.2095 33.8984 10.5814 33.8984 -17.3906" stroke="white" strokeWidth="2" strokeLinecap="round" />
          <circle cx="56" cy="42" r="24" fill="#4A8870" />
          <path d="M18 102C18 84.3269 32.3269 70 50 70H62C79.6731 70 94 84.3269 94 102V136H18V102Z" fill="#4A8870" />
        </g>
        <defs>
          <clipPath id="clip0_3093:3517">
            <rect width="112px" height="136px" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default GeneralDonationProfile;
