import { TFunction } from 'i18next';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Facebook from '../../assets/svg/Facebook/Facebook';
import Instagram from '../../assets/svg/Instagram/Instagram';
import Youtube from '../../assets/svg/Youtube/Youtube';

import styles from './Maintenance.module.css';

class Maintenance extends React.Component {

  render() {
    return (
      <Translation ns="maintenance">
        {
          (t: TFunction) => {
            return (
              <div className={`page-container ${styles.maintenancePageContainer}`}>
                <Row className={styles.maintenanceContentContainer}>
                  <Col xs={12} lg={6}>
                    <div>
                      <img src={process.env.PUBLIC_URL + '/assets/mh-logo.svg'} alt='merajut-hati-logo' width={120} height={50} />
                    </div>
                    <div className={styles.header}>
                      {t('header')}
                    </div>
                    <div className={styles.description}>
                      {t('description1')}
                    </div>
                    <div className={styles.description}>
                      {t('description2')}
                    </div>
                    <div className={styles.socialMediaContainer}>
                      <Link
                        to={{ pathname: 'https://www.instagram.com/yayasanmerajuthati/' }}
                        target='_blank'
                      >
                        <Instagram />
                      </Link>
                      <Link
                        to={{ pathname: 'https://www.youtube.com/channel/UChOdh-bBjrAnhvcoNdwAtqw' }}
                        target='_blank'
                      >
                        <Youtube />
                      </Link>
                      <Link
                        to={{ pathname: 'https://www.facebook.com/yayasan.merajut.hati' }}
                        target='_blank'
                      >
                        <Facebook />
                      </Link>
                    </div>
                  </Col>
                  <Col className={styles.maintenanceImgContainer} lg={6}>
                    <img src={process.env.PUBLIC_URL + '/assets/img/maintenance/maintenance.svg'} alt='maintenance' />
                  </Col>
                </Row>
              </div>
            );
          }
        }
      </Translation>
    );
  }
}

export default Maintenance;
