import React from 'react';
import styles from './ExpandableButton.module.css';

interface ExpandableButtonProp {
  id: string;
  imgSrc: string;
  imgAlt: string;
  onClick?(): void;
  ariaLabel: string;
  firstLine: string;
  secondLine: string;
}

class ExpandableButton extends React.Component<ExpandableButtonProp> {
  render() {
      return (
        <button
          id={this.props.id}
          onClick={() => this.props.onClick ? this.props.onClick.apply(undefined) : null}
          aria-label={this.props.ariaLabel}
          className={styles.expandable}
        >
          <img src={this.props.imgSrc} className={styles.icon} alt={this.props.imgAlt}/>
          <div className={styles.desc}>
            <div className={styles.firstLine}>
              {this.props.firstLine}
            </div>
            <div className={styles.secondLine}>
              {this.props.secondLine}
            </div>
          </div>
        </button>

      );
  }
}

export default ExpandableButton;
