import React from 'react';

interface WebsiteProp {
  className?: string;
}

class Website extends React.Component<WebsiteProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label='webiste' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.796 14.2C16.884 13.474 16.95 12.748 16.95 12C16.95 11.252 16.884 10.526 16.796 9.8H20.514C20.69 10.504 20.8 11.241 20.8 12C20.8 12.759 20.69 13.496 20.514 14.2H16.796ZM14.849 20.316C15.509 19.095 16.015 17.775 16.367 16.4H19.612C18.5463 18.2351 16.8555 19.6252 14.849 20.316ZM14.574 14.2H9.426C9.316 13.474 9.25 12.748 9.25 12C9.25 11.252 9.316 10.515 9.426 9.8H14.574C14.673 10.515 14.75 11.252 14.75 12C14.75 12.748 14.673 13.474 14.574 14.2ZM12 20.756C11.087 19.436 10.35 17.973 9.899 16.4H14.101C13.65 17.973 12.913 19.436 12 20.756ZM7.6 7.6H4.388C5.44274 5.75994 7.13228 4.36764 9.14 3.684C8.48 4.905 7.985 6.225 7.6 7.6ZM4.388 16.4H7.6C7.985 17.775 8.48 19.095 9.14 20.316C7.13649 19.6248 5.44933 18.2345 4.388 16.4ZM3.486 14.2C3.31 13.496 3.2 12.759 3.2 12C3.2 11.241 3.31 10.504 3.486 9.8H7.204C7.116 10.526 7.05 11.252 7.05 12C7.05 12.748 7.116 13.474 7.204 14.2H3.486ZM12 3.233C12.913 4.553 13.65 6.027 14.101 7.6H9.899C10.35 6.027 11.087 4.553 12 3.233ZM19.612 7.6H16.367C16.0227 6.2376 15.5129 4.92249 14.849 3.684C16.873 4.377 18.556 5.774 19.612 7.6ZM12 1C5.917 1 1 5.95 1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C5.24327 20.7996 6.4559 21.6099 7.79048 22.1627C9.12506 22.7155 10.5555 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 10.5555 22.7155 9.12506 22.1627 7.79048C21.6099 6.4559 20.7996 5.24327 19.7782 4.22183C18.7567 3.20038 17.5441 2.39013 16.2095 1.83733C14.8749 1.28452 13.4445 1 12 1Z" fill="#38383C" />
      </svg>
    );
  }
}

export default Website;
