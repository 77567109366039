import React from 'react';
import styles from './Checkbox.module.css';

interface CheckboxProp {
  label: string;
  onChange: (state: any) => void;
}

interface CheckboxState {
  checked: boolean
}

class CheckBox extends React.Component<CheckboxProp, CheckboxState> {
  buttonRef: React.RefObject<HTMLDivElement>

  constructor(props: CheckboxProp) {
    super(props);
    this.state = {
      checked: false
    };
    this.buttonRef = React.createRef();
  }

  componentDidUpdate(prevProp: CheckboxProp, prevState: CheckboxState): void {
     if (this.state.checked !== prevState.checked) {
      this.props.onChange(this.state.checked);
      if (this.state.checked) {
        this.buttonRef.current!.className = styles.buttonChecked;
      } else {
        this.buttonRef.current!.className = styles.button;
      }
     }
  }

  toggleCheckbox = () => {
    if (!this.state.checked) {
      this.setState({
        checked: true
      });
    } else {
      this.setState({
        checked: false
      });
    }
  }

  render() {
    return (
      <div className={styles.container} onClick={this.toggleCheckbox}>
        <div className={styles.button} ref={this.buttonRef}>
          <img className={styles.image} src={process.env.PUBLIC_URL + '/assets/icons/white-checkmark.svg'} />
        </div>
        <div className={styles.label}>{this.props.label}</div>
      </div>
    );
  }
}

export default CheckBox;
