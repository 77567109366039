import React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';

import styles from './Home.module.css';
import CustomCarousel from '../../components/custom-carousel/CustomCarousel';
import CustomButton from '../../components/custom-button/CustomButton';
import ProgressBar from '../../components/progress-bar/ProgressBar';

import client from '../../utils/apollo';
import { Client } from '../../utils/model/client';
import { TidakSendiriLagi } from '../../utils/model/tidakSendiriLagi';

import { GET_CLIENTS_BY_ISCOMPLETE } from '../../utils/queries/clients';
import { ApolloQueryResult } from '@apollo/client';
import { Breakpoint } from '../../utils/breakpoint';
import { BreakpointContext } from 'react-with-breakpoints';
import GET_TIDAK_SENDIRI_LAGIS from '../../utils/queries/tidakSendiriLagis';

interface HomeProp {
  i18n: any;
  history: any;
}

interface HomeState {
  berbagiHatiClients: Client[],
  tidakSendiriLagis: TidakSendiriLagi[]
}

class Home extends React.Component<HomeProp, HomeState> {

  private currentBreakpoint = '';

  constructor(props: HomeProp) {
    super(props);
    this.state = {
      berbagiHatiClients: [],
      tidakSendiriLagis: []
    };
  }

  componentDidMount() {
    let berbagiHatiClients: Client[] = [];
    let tidakSendiriLagis: TidakSendiriLagi[] = [];
    client.query({
      query: GET_CLIENTS_BY_ISCOMPLETE,
      variables: {
        limit: 6,
        isComplete: false
      }
    })
      .then((results: ApolloQueryResult<any>) => {
        berbagiHatiClients = results.data.clients as Client[];
      })
      .then(() => {
        return client.query({
          query: GET_TIDAK_SENDIRI_LAGIS,
          variables: {
            limit: 6
          }
        });
      })
      .then((results: ApolloQueryResult<any>) => {
        tidakSendiriLagis = results.data.tidakSendiriLagis as TidakSendiriLagi[];
        this.setState({
          berbagiHatiClients,
          tidakSendiriLagis
        });
      });
  }

  render() {
    return (
      <BreakpointContext.Consumer>
        {value => {
          this.currentBreakpoint = value.currentBreakpoint;
          return (<div className={styles.pageContainer}>
            {this.renderBanner()}
            {this.renderWhatWeDo()}
          </div>);
        }
        }
      </BreakpointContext.Consumer>
    );
  }

  private renderBanner() {
    return (
      <>
        <Container className={styles.banner}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/home/banner-background.svg'})` }}>
          <div className={styles.bannerTitle}>
            {this.props.i18n.t('home:bannerTitle')}
          </div>
          <Row className={styles.bannerContent}>
            <Col xs={12} lg={6}>
              <img className={styles.bannerImage} src={`${process.env.PUBLIC_URL}/assets/img/home/banner.svg`} alt="Banner" />
            </Col>
            <Col xs={12} lg={6}>
              <div className={styles.bannerSubtitle}>
                {this.props.i18n.t('home:bannerSubtitle')}
              </div>
              <div className={styles.bannerDescription}>
                {this.props.i18n.t('home:bannerDescription')}
              </div>
              <div className={styles.bannerButton}>
                <CustomButton id='home-learn-more-tsl' type='button' label={this.props.i18n.t('home:bannerLearnMore')}
                  onClick={() => this.navigateToTSL()} ariaLabel='TSL Learn More' colorType='primary'>
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  private renderWhatWeDo() {
    return (
      <>
        {/* Berbagi Hati */}
        <div className={styles.whatWeDoContainer1}>
          <h1 className={styles.whatWeDoHeader}>{this.props.i18n.t('home:whatWeDoTitle')}</h1>
          <div className={styles.whatWeDoSmallSubtitle}>01</div>
          <div className={styles.whatWeDoSubtitle}>Berbagi Hati</div>
          <div className={styles.whatWeDoSubtitleDesc}>
            {this.props.i18n.t('home:whatWeDoBerbagiHatiDesc')}
          </div>
          <div className={styles.whatWeDoSponsorOthers}>{this.props.i18n.t('home:whatWeDoSponsorOthers')}</div>
          <div className={styles.whatWeDoSmallSubtitle}>
            {this.props.i18n.t('home:whatWeDoSponsorOthersDesc1')}<br />
            {this.props.i18n.t('home:whatWeDoSponsorOthersDesc2')}
          </div>
        </div>

        {/* Berbagi Hati Client Carousel */}
        <div className={styles.whatWeDoContainer2}>
          <CustomCarousel content={this.renderBerbagiHatiCarouselContent()}
            actionButtonText={this.props.i18n.t('home:berbagiHatiCarouselViewAll')}
            actionButtonOnClick={this.viewAllBerbagiHati} actionButtonIconURL={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`}
            isDisplayButton={true} />
          <div className={styles.berbagiHatiDisclaimerTitle}>Disclaimer</div>
          <div className={styles.berbagiHatiDisclaimer}>
            {this.props.i18n.t('home:berbagiHatiDisclaimer')}
          </div>
        </div>

        {/* Apply Berbagi Hati */}
        <div className={styles.whatWeDoContainer3}>
          <div className={styles.applyBerbagiHatiHeader}>{this.props.i18n.t('home:applyForSponsorship')}</div>
          <Row>
            <Col xs={12} lg={4} className={styles.applyBerbagiHatiCampaign}>
              {this.props.i18n.t('home:applyBerbagiHatiCampaign')}
            </Col>
            <Col xs={12} lg={8}>
              <div className={styles.applyBerbagiHatiDescription}>
                {this.props.i18n.t('home:applyBerbagiHatiDescription')}
              </div>
              <CustomButton
                id='home-view-apply-berbagi-hati'
                type='button'
                colorType='secondary'
                label={this.props.i18n.t('home:viewApplyBerbagiHati')}
                ariaLabel='View Apply Berbagi Hati'
                onClick={() => this.navigateToApplyBerbagiHati()} />
            </Col>
          </Row>
        </div>

        {/* Tidak Sendiri Lagi */}
        <div className={styles.whatWeDoContainer4}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/home/tsl-background.svg'})` }}>
          <div className={styles.whatWeDoSmallSubtitle}>02</div>
          <div className={styles.whatWeDoSubtitle}>#TidakSendiriLagi</div>
          <div className={styles.tslDescription}>
            {this.props.i18n.t('home:tslDescription')}
          </div>
          <div className={styles.tslTestimonyTitle}>
            {this.props.i18n.t('home:tslTestimonyTitle')}
          </div>
          <CustomCarousel content={this.renderTSLCarouselContent()}
            actionButtonText={this.props.i18n.t('home:tslShareYourStory')}
            actionButtonOnClick={this.navigateToTSL} actionButtonIconURL={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`}
            isDisplayButton={true} />
        </div>

        {/* Get Involved */}
        <div className={styles.getInvolvedContainer}>
          <div className={styles.getInvolvedSubtitle}>{this.props.i18n.t('home:getInvolved')}</div>
          <div className={styles.getInvolvedDescription}>
            {this.props.i18n.t('home:getInvolvedDescription')}
          </div>
          <CustomCarousel content={this.renderGetInvolvedCarouselContent()} isDisplayButton={false} showIndicator={true} />
        </div>
      </>
    );
  }

  private viewAllBerbagiHati = () => {
    this.props.history.push('/berbagihati');
  }

  private navigateToTSL = () => {
    this.props.history.push('/tidaksendirilagi');
  }

  private navigateToApplyBerbagiHati() {
    this.props.history.push('/applyberbagihati');
  }

  private navigateToPage = (pageName: string) => {
    this.props.history.push(`/${pageName}`);
  }

  private navigateToOpenJobPosition = () => {
    this.props.history.push('/jobs');
  }

  private renderBerbagiHatiCarouselContent() {
    if (this.currentBreakpoint === Breakpoint.XLARGE
      || this.currentBreakpoint === Breakpoint.LARGE) {
      return this.getPCBerbagiHatiContent();
    } else {
      return this.getMobileBerbagiHatiContent();
    }
  }

  private getMobileBerbagiHatiContent() {
    return (
      <>
        {this.state.berbagiHatiClients.map((client: Client, index: number) => {
          return (
            <Carousel.Item key={index}>
              <div className={styles.carouselItemContainer}>
                {this.getClient(client)}
              </div>
            </Carousel.Item>
          );
        })}
      </>
    );
  }

  private getPCBerbagiHatiContent() {
    const clientsCarouselItems = [];
    const numItemsPerRow = 4;
    for (let i = 0; i < this.state.berbagiHatiClients.length; i += numItemsPerRow) {
      const clients = [];
      for (let j = 0; j < numItemsPerRow; j++) {
        if (j === 0) {
          clients.push(this.state.berbagiHatiClients[j]);
        } else if (this.state.berbagiHatiClients[i + j]) {
          clients.push(this.state.berbagiHatiClients[i + j]);
        }
      }
      clientsCarouselItems.push(clients);
    }

    return (
      <>
        {
          clientsCarouselItems.map((clients: Client[], index: number) => {
            return (
              <Carousel.Item key={index}>
                <div className={styles.carouselItemContainer}>
                  {clients.map((client: Client) => this.getClient(client))}
                </div>
              </Carousel.Item>
            );
          })
        }
      </>
    );
  }

  private getClient(client: Client) {
    return (
      <div className={styles.clientContainer} key={client.name}>
        <div className={styles.clientProfilePictureContainer}>
          <img className={styles.clientProfilePicture}
            src={client.profilePicture.url}
            alt={client.profilePicture.name} />
        </div>
        <div className={styles.clientContentContainer}>
          <div className={styles.clientDescriptionContainer}>
            <div className={styles.clientTitle}>{client.name}</div>
            <div className={styles.clientDescription}>
              {this.truncateString(client.description)}
            </div>
          </div>
          <ProgressBar progress={Math.floor(client.raised / client.goal * 100)} />
          <div className={styles.clientReport}>
            IDR{this.formatNumber(client.raised)}&ensp;
            <span className={styles.clientReportLight}>{this.props.i18n.t('home:clientRaisedOutOf')}</span>&ensp;
            {this.formatNumber(client.goal)}&ensp;
            <span className={styles.clientReportLight}>{this.props.i18n.t('home:clientGoal')}</span>
          </div>
        </div>
      </div>
    );
  }

  private formatNumber(amount: number) {
    return amount.toLocaleString();
  }

  private renderTSLCarouselContent() {
    if (this.currentBreakpoint === Breakpoint.XLARGE
      || this.currentBreakpoint === Breakpoint.LARGE) {
      return this.getPCTSLContent();
    } else {
      return this.getMobileTSLContent();
    }
  }

  private getMobileTSLContent() {
    return (
      <>
        {this.state.tidakSendiriLagis.map((thumbnail: TidakSendiriLagi, index: number) => {
          return (
            <Carousel.Item key={index}>
              <div className={styles.carouselItemContainer}>
                {this.getThumbnail(thumbnail)}
              </div>
            </Carousel.Item>
          );
        })}
      </>
    );
  }

  private getPCTSLContent() {
    const tslCarouselItems = [];
    for (let i = 0; i < this.state.tidakSendiriLagis.length; i += 3) {
      const thumbnails = [];
      thumbnails.push(this.state.tidakSendiriLagis[i]);
      if (this.state.tidakSendiriLagis[i + 1]) {
        thumbnails.push(this.state.tidakSendiriLagis[i + 1]);
      }
      if (this.state.tidakSendiriLagis[i + 2]) {
        thumbnails.push(this.state.tidakSendiriLagis[i + 2]);
      }
      tslCarouselItems.push(thumbnails);
    }

    return (
      <>
        {
          tslCarouselItems.map((thumbnails: TidakSendiriLagi[], index: number) => {
            return (
              <Carousel.Item key={index}>
                <div className={styles.carouselItemContainer}>
                  {thumbnails.map((thumbnail: TidakSendiriLagi) => this.getThumbnail(thumbnail))}
                </div>
              </Carousel.Item>
            );
          })
        }
      </>
    );
  }

  private getThumbnail(thumbnail: TidakSendiriLagi) {
    return (
      <div className={styles.thumbnailContainer} key={thumbnail.id}
        onClick={() => window.open(thumbnail.instagramURL, '_blank')}>
        <img src={thumbnail.cover.url} className={styles.thumbnailImage}
          alt={`${process.env.REACT_APP_MH_STRAPI_ENDPOINT}${thumbnail.cover.name}`} />
      </div>
    );
  }

  private renderGetInvolvedCarouselContent() {
    const getInvolvedCarouselContent = [
      {
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/home/get-involved-1.svg`,
        imageAlt: 'get involved 1',
        textTitle: this.props.i18n.t('home:getInvolvedTextTitle1'),
        textDescription: this.props.i18n.t('home:getInvolvedTextDescription1'),
        actionBtn: {
          label: this.props.i18n.t('home:getInvolvedActionBtnLabel1'),
          ariaLabel: 'Get Involved Explore Vacancies',
          onClick: () => this.navigateToPage('jobs')
        }
      },
      {
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/home/get-involved-2.svg`,
        imageAlt: 'get involved 2',
        textTitle: this.props.i18n.t('home:getInvolvedTextTitle2'),
        textDescription: this.props.i18n.t('home:getInvolvedTextDescription2'),
        actionBtn: {
          label: this.props.i18n.t('home:getInvolvedActionBtnLabel2'),
          ariaLabel: 'Get Involved Contact Us',
          onClick: () => this.navigateToPage('contactus')
        }
      },
      {
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/home/get-involved-3.svg`,
        imageAlt: 'get involved 3',
        textTitle: this.props.i18n.t('home:getInvolvedTextTitle3'),
        textDescription: this.props.i18n.t('home:getInvolvedTextDescription3'),
        actionBtn: {
          label: this.props.i18n.t('home:getInvolvedActionBtnLabel3'),
          ariaLabel: 'Get Involved Donate Now',
          onClick: () => this.navigateToPage('donate')
        }
      }
    ];
    return (
      <>
        {
          getInvolvedCarouselContent.map((content, index) => {
            return (
              <Carousel.Item key={index}>
                <div className={styles.carouselItemContainer}>
                  <img src={content.imageUrl} alt='get involved 1' className={styles.getInvolvedCarouselImage} />
                  <div className={styles.getInvolvedCarouselTextContainer}>
                    <div className={styles.getInvolvedCarouselTextTitle}>
                      {content.textTitle}
                    </div>
                    <div className={styles.getInvolvedCarouselTextDescription}>
                      {content.textDescription}
                    </div>
                    <div>
                      <CustomButton id='home-get-involved-explore-vacancies' type='button'
                        colorType='secondary'
                        label={content.actionBtn.label} ariaLabel={content.actionBtn?.ariaLabel}
                        onClick={content.actionBtn.onClick} />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })
        }
      </>
    );
  }

  private truncateString(input: string, maxLength = 120): string {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input;
  }
}

export default Home;
