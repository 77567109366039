import React from 'react';
import { Col, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ShowAt } from 'react-with-breakpoints';
import IconButton from '../icon-button/IconButton';
import styles from './NavigationMenu.module.css';

interface NavigationMenuProps {
  i18n: any;
}

enum NavType {
  DROPDOWN,
  LINK
}

interface NavLink {
  linkName: string;
  url: string;
  type: NavType;
  children: NavLink[];
  imageUrl?: string;
}

class NavigationMenu extends React.Component<NavigationMenuProps> {

  render() {
    // Note: change link here when we need to add more links
    const navMenus: NavLink[] = [
      {
        linkName: 'Berbagi Hati',
        url: '/',
        type: NavType.DROPDOWN,
        children: [
          {
            linkName: this.props.i18n.t('navbar.apply'),
            url: '/applyberbagihati',
            type: NavType.LINK,
            children: []
          },
          {
            linkName: this.props.i18n.t('navbar.fundOthers'),
            url: '/berbagihati',
            type: NavType.LINK,
            children: []
          }
        ]
      },
      {
        linkName: '#TidakSendiriLagi',
        url: '/tidaksendirilagi',
        type: NavType.LINK,
        children: []
      },
      {
        linkName: this.props.i18n.t('navbar.services'),
        url: '',
        type: NavType.DROPDOWN,
        children: [
          {
            linkName: this.props.i18n.t('navbar.findAProvider'),
            url: '/findaprovider',
            type: NavType.LINK,
            children: []
          },
          {
            linkName: this.props.i18n.t('navbar.screeningTest'),
            url: '/screeningtest',
            type: NavType.LINK,
            children: []
          }
        ]
      },
      {
        linkName: this.props.i18n.t('navbar.about'),
        url: '',
        type: NavType.DROPDOWN,
        children: [
          {
            linkName: this.props.i18n.t('navbar.aboutUs'),
            url: '/aboutus',
            type: NavType.LINK,
            children: []
          },
          {
            linkName: this.props.i18n.t('navbar.meetTheTeam'),
            url: '/meettheteam',
            type: NavType.LINK,
            children: []
          },
          {
            linkName: this.props.i18n.t('navbar.contactUs'),
            url: '/contactus',
            type: NavType.LINK,
            children: []
          }
        ]
      },
    ];

    return (
      <>
        <ShowAt breakpoint="mediumAndBelow">
          {/* Mobile & Tablet View */}
          <Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img className={styles.mhLogo}
                src={process.env.PUBLIC_URL + '/assets/mh-logo.svg'}
                alt="merajut-hati-logo" />
            </Navbar.Brand>
            <Navbar.Toggle className={styles['navbar-toggler']} />
            <Navbar.Collapse>
              <Nav className="mr-auto justify-content-end">
                {
                  navMenus.map((menu, index) => {
                    switch (menu.type) {
                      case NavType.DROPDOWN:
                        return (
                          <NavDropdown key={index} title={menu.linkName} id="mh-learn-nav-dropdown">
                            {
                              menu.children.map(link => {
                                return (
                                  <NavDropdown.Item key={link.linkName} as={Link} to={link.url}>
                                    {link.linkName}
                                  </NavDropdown.Item>
                                );
                              })
                            }
                          </NavDropdown>
                        );
                      default:
                        return (<Nav.Link key={index} as={Link} to={menu.url}>{menu.linkName}</Nav.Link>);
                    }
                  })
                }
              </Nav>
              {/* Language Selection */}
              <div>
                <Row>
                  <Col xs={1} sm={1}>
                    <IconButton onClick={this.changeLanguage.bind(this, 'en')} src='/assets/flags/united-states.svg' alt='EN' />
                  </Col>
                  <Col>
                    <IconButton onClick={this.changeLanguage.bind(this, 'id')} src='/assets/flags/indonesia.svg' alt='ID' />
                  </Col>
                </Row>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </ShowAt>
        {/* Desktop View */}
        <ShowAt breakpoint="largeAndAbove">
          <div className={styles.container}>
            <div className={styles.logoContainer}>
              <Link to='/'>
                <img className={styles.mhLogo}
                  src={process.env.PUBLIC_URL + '/assets/mh-logo.svg'}
                  alt="merajut-hati-logo" />
              </Link>
            </div>
            <div className={styles.navMenu}>
              {
                navMenus.map((menu, index) => {
                  switch (menu.type) {
                    case NavType.DROPDOWN:
                      return (
                        this.getDropdown(menu, index)
                      );
                    default:
                      return (<Link key={index} className={styles.link} to={menu.url}>{menu.linkName}</Link>);
                  }
                })
              }
            </div>
          </div>
        </ShowAt>
      </>
    );
  }

  private getDropdown(menu: NavLink, index: number) {
    return (
      <div key={index} className={styles.dropdown}>
        <div className={styles.clickableDropdown}>
          <span className={styles.dropdownName}>{menu.linkName}</span>
          <img src={process.env.PUBLIC_URL + '/assets/icons/dropdown-primary.svg'}
            alt="dropdown-icon" />
        </div>
        <div className={styles.dropdownItemContainer}>
          {
            menu.children.map(link => {
              if (!link.imageUrl) {
                return (
                  <Link key={link.linkName} className={styles.linkName} to={link.url}>
                    {link.linkName}
                  </Link>
                );
              } else {
                return (
                  <Link key={link.linkName} className={styles.linkName} to={{ pathname: link.url }} target="_blank">
                    {link.linkName}
                    <img src={link.imageUrl}></img>
                  </Link>
                );
              }
            })
          }
        </div>
      </div>
    );
  }


  private changeLanguage(lang: string) {
    this.props.i18n.changeLanguage(lang);
  }
}

export default NavigationMenu;
