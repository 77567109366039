import React from 'react';
import styles from './LabeledFormField.module.css';

interface LabeledFormFieldProp {
  errorMessage?: string;
  name: string;
  label: string
  value: string;
  onChange(name: string, state: LabeledFormFieldState): void;
  validator?: (input: string) => boolean;
}

export interface LabeledFormFieldState {
  isDirty: boolean;
  isValid: boolean;
  value: string;
}

class LabeledFormField extends React.Component<LabeledFormFieldProp, LabeledFormFieldState> {

  constructor(props: LabeledFormFieldProp) {
    super(props);
    this.state = {
      isDirty: false,
      isValid: true,
      value: this.props.value
    };
  }

  componentDidUpdate() {
    // note: keep component state in sync with what is being passed in
    this.setState({
      value: this.props.value,
      isValid: this.isValid(this.props.value)
    });
  }

  shouldComponentUpdate(nextProps: LabeledFormFieldProp) {
    // note: determine component re-render when data is passed from parent has changed
    return (this.props.value !== nextProps.value);
  }

  render() {
    return (
      <div>
        <label className={styles.labeledInputContainer}>
          <div className={styles.labeledInputLabel}>{this.props.label}</div>
          <input className={this.inputClassNames()}
            onChange={(e) => {
              this.setState({
                value: e.currentTarget.value,
                isValid: this.isValid(e.currentTarget.value)
              }, this.handleOnChange);
            }}
            onBlur={(e) => {
              this.setState({
                isDirty: true,
                isValid: this.isValid(e.currentTarget.value)
              }, this.forceUpdate);
            }}
            value={this.props.value} />
        </label>
        {this.isError() ? this.errorMessage() : undefined}
      </div>
    );
  }

  private inputClassNames() {
    const inputClassNames = [styles.labeledInput];
    if (this.isError()) {
      inputClassNames.push(styles.labeledInputError);
    }
    return inputClassNames.join(' ');
  }

  private errorMessage() {
    return (
      <div className={styles.errorMessage}>{this.props.errorMessage}</div>
    );
  }

  private isValid(value: string) {
    return this.props.validator ? this.props.validator(value) : true;
  }

  private isError() {
    return !this.isValid(this.props.value) && this.state.isDirty;
  }

  private handleOnChange() {
    this.props.onChange(this.props.name, this.state);
  }
}

export default LabeledFormField;
