import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ShowAt } from 'react-with-breakpoints';
import CustomButton from '../../components/custom-button/CustomButton';
import styles from './GetInvolved.module.css';

interface GetInvolvedProps {
  i18n: any;
}

class GetInvolved extends React.Component<GetInvolvedProps> {
  render() {
    return (
      <div className='page-container'>
        <h1>{this.props.i18n.t('get-involved:title')}</h1>
        <p className={styles.getInvolvedDescription}>
          {this.props.i18n.t('get-involved:titleDescription')}
        </p>

        {/* Be a Team Member */}
        <div className={`${styles.getInvolvedItemContainer} ${styles.getInvolvedItemEven}`}>
          <div className={styles.getInvolvedItem}>
            <Row>
              <Col lg={7}>
                <div className={styles.getInvolvedItemHeader}>{this.props.i18n.t('get-involved:beATeamMember')}</div>
                <p className={styles.getInvolvedItemDescription}>
                  {this.props.i18n.t('get-involved:beATeamMemberDescription')}
                </p>
                <Link to='/jobs' className={styles.linkedButton}>
                  <CustomButton
                    id='get-involved-view-job-vacancies' type='button'
                    className={styles.getInvolvedItemButton} label={this.props.i18n.t('get-involved:beATeamMemberLinkedButton')}
                    ariaLabel={this.props.i18n.t('get-involved:beATeamMemberLinkedButton')} colorType='tertiary' />
                </Link>
              </Col>
              <Col lg={5}>
                <ShowAt breakpoint='largeAndAbove'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `linear-gradient(74.76deg, #f9f9f1 22.1%, rgba(255, 255, 255, 0) 89.3%), \
                    url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-0.png'})`
                    }}>
                  </div>
                </ShowAt>
                <ShowAt breakpoint='mediumAndBelow'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-0.png'})`
                    }}>
                  </div>
                </ShowAt>
              </Col>
            </Row>
          </div>
        </div>

        {/* Be a Sponsor */}
        <div className={`${styles.getInvolvedItemContainer} ${styles.getInvolvedItemOdd}`}>
          <div className={styles.getInvolvedItem}>
            <Row>
              <Col lg={7}>
                <div className={styles.getInvolvedItemHeader}>{this.props.i18n.t('get-involved:beASponsor')}</div>
                <p className={styles.getInvolvedItemDescription}>
                  {this.props.i18n.t('get-involved:beASponsorDescription')}
                </p>
                <Link to='/berbagihati' className={styles.linkedButton}>
                  <CustomButton
                    id='get-involved-sponsor-others' type='button'
                    className={styles.getInvolvedItemButton} label={this.props.i18n.t('get-involved:beASponsorLinkedButton')}
                    ariaLabel={this.props.i18n.t('get-involved:beASponsorLinkedButton')} colorType='tertiary' />
                </Link>
              </Col>
              <Col lg={5}>
                <ShowAt breakpoint='largeAndAbove'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `linear-gradient(74.76deg, #ecf3f0 22.1%, rgba(255, 255, 255, 0) 89.3%), \
                    url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-1.png'})`
                    }}>
                  </div>
                </ShowAt>
                <ShowAt breakpoint='mediumAndBelow'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-1.png'})`
                    }}>
                  </div>
                </ShowAt>
              </Col>
            </Row>
          </div>
        </div>

        {/* Be a Donor */}
        <div className={`${styles.getInvolvedItemContainer} ${styles.getInvolvedItemEven}`}>
          <div className={styles.getInvolvedItem}>
            <Row>
              <Col lg={7}>
                <div className={styles.getInvolvedItemHeader}>{this.props.i18n.t('get-involved:beADonor')}</div>
                <p className={styles.getInvolvedItemDescription}>
                  {this.props.i18n.t('get-involved:beADonorDescription')}
                </p>
                <Link to='/donate' className={styles.linkedButton}>
                  <CustomButton
                    id='get-involved-donate-now' type='button'
                    className={styles.getInvolvedItemButton} label={this.props.i18n.t('get-involved:beADonorLinkedButton')}
                    ariaLabel={this.props.i18n.t('get-involved:beADonorLinkedButton')} colorType='tertiary' />
                </Link>
              </Col>
              <Col lg={5}>
                <ShowAt breakpoint='largeAndAbove'>
                  <div className={`${styles.getInvolvedItemImage} ${styles.beADonorImage}`}
                    style={{
                      backgroundImage: `linear-gradient(74.76deg, #f9f9f1 22.1%, rgba(255, 255, 255, 0) 89.3%), \
                    url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-2.png'})`
                    }}>
                  </div>
                </ShowAt>

                <ShowAt breakpoint='mediumAndBelow'>
                  <div className={`${styles.getInvolvedItemImage} ${styles.beADonorImage}`}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-2.png'})`
                    }}>
                  </div>
                </ShowAt>
              </Col>
            </Row>
          </div>
        </div>

        {/* Be a Provider */}
        <div className={`${styles.getInvolvedItemContainer} ${styles.getInvolvedItemOdd}`}>
          <div className={styles.getInvolvedItem}>
            <Row>
              <Col lg={7}>
                <div className={styles.getInvolvedItemHeader}>{this.props.i18n.t('get-involved:beAProvider')}*</div>
                <div className={styles.getInvolvedItemHeaderNote}>
                  {this.props.i18n.t('get-involved:beAProviderEligibility')}
                </div>
                <p className={styles.getInvolvedItemDescription}>
                  {this.props.i18n.t('get-involved:beAProviderDescription')}
                </p>
                <Link to=
                  {{
                    pathname: 'https://docs.google.com/forms/d/e/1FAIpQLSfDmh_2fjxMSFF8E76E4t1KpSGbU_8KDCvc-c9z3sDV_VLquQ/viewform'
                  }}
                  target='_blank'
                  className={styles.linkedButton}>
                  <CustomButton
                    id='get-involved-join-as-provider' type='button'
                    className={styles.getInvolvedItemButton} label={this.props.i18n.t('get-involved:beAProviderLinkedButton')}
                    ariaLabel={this.props.i18n.t('get-involved:beAProviderLinkedButton')} colorType='tertiary' />
                </Link>
              </Col>
              <Col lg={5}>
                <ShowAt breakpoint='largeAndAbove'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `linear-gradient(74.76deg, #ecf3f0 22.1%, rgba(255, 255, 255, 0) 89.3%), \
                    url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-3.png'})`
                    }}>
                  </div>
                </ShowAt>
                <ShowAt breakpoint='mediumAndBelow'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-3.png'})`
                    }}>
                  </div>
                </ShowAt>
              </Col>
            </Row>
          </div>
        </div>

        {/* Be a Partner */}
        <div className={`${styles.getInvolvedItemContainer} ${styles.getInvolvedItemEven}`}>
          <div className={styles.getInvolvedItem}>
            <Row>
              <Col lg={7} md={12}>
                <div className={styles.getInvolvedItemHeader}>{this.props.i18n.t('get-involved:beAPartner')}</div>
                <p className={styles.getInvolvedItemDescription}>
                  {this.props.i18n.t('get-involved:beAPartnerDescriptionPart1')}
                  <Link to={{ pathname: 'mailto: contact@merajuthati.org' }} target='_blank'>
                    {this.props.i18n.t('get-involved:beAPartnerDescriptionPart2')}
                  </Link>
                  {this.props.i18n.t('get-involved:beAPartnerDescriptionPart3')}
                </p>
              </Col>
              <Col lg={5} md={12}>
                <ShowAt breakpoint='largeAndAbove'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `linear-gradient(74.76deg, #f9f9f1 22.1%, rgba(255, 255, 255, 0) 89.3%), \
                    url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-4.png'})`,
                    }}>
                  </div>
                </ShowAt>
                <ShowAt breakpoint='mediumAndBelow'>
                  <div className={styles.getInvolvedItemImage}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/get-involved/item-4.png'})`,
                    }}>
                  </div>
                </ShowAt>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default GetInvolved;
