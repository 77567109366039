import React from 'react';
import styles from './CustomDropdown.module.css';

interface DropdownOptions {
  label: string;
  value: string;
}

interface Option {
  width?: string;
}

interface CustomDropdownProps {
  default?: string;
  dropdownOptions: DropdownOptions[];
  name: string;
  option?: Option;
  onChange: (type: string, input: DropdownOptions) => void;
}

interface CustomDropdownState {
  selected: DropdownOptions;
}

class CustomDropdown extends React.Component<CustomDropdownProps, CustomDropdownState> {
  constructor(props: CustomDropdownProps) {
    super(props);
    this.state = {
      selected: {
        label: '',
        value: ''
      }
    };
  }

  componentDidMount() {
    const { dropdownOptions } = this.props;
    if (dropdownOptions.length > 0) {
      const defaultOption = this.props.default
        ? dropdownOptions[dropdownOptions.findIndex(a => a.value === this.props.default)]
        : dropdownOptions[0];
      this.setSelectedOption(defaultOption);
    }
  }

  componentDidUpdate(prevProps: CustomDropdownProps, prevState: CustomDropdownState) {
    if (prevState.selected !== this.state.selected) {
      this.props.onChange(this.props.name, this.state.selected);
    }
  }

  private inputFieldClassNames = () => {
    const width = this.props.option?.width;
    return `${width} ${styles.inputField}`;
  }

  private optionClassNames = (label: string) => {
    const selected = this.state.selected.label === label ? styles.selected : '';
    return `${styles.option} ${selected}`;
  }

  private setSelectedOption = ({ label, value }: DropdownOptions) => {
    this.setState({
      selected: {
        label,
        value
      }
    });
  }

  render() {
    return (
      <div className={styles.container} tabIndex={-1}>
        <div className={this.inputFieldClassNames()}>
          {this.state.selected.label}
          <img src={process.env.PUBLIC_URL + 'assets/icons/dropdown.svg'} alt="custom-dropdown-arrow" />
        </div>
        <div className={styles.options}>
          {this.props.dropdownOptions.map((option, key) => {
            return (
              <span className={this.optionClassNames(option.label)} key={key}
                onClick={() => this.setSelectedOption(option)}>
                {option.label}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CustomDropdown;
