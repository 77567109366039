import React from 'react';
import styles from './MerajutHati.module.css';

import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Switch, Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ShowAt } from 'react-with-breakpoints';
import i18n from './i18n';
import Cookies from 'universal-cookie';
import { ApolloQueryResult } from '@apollo/client';

import Instagram from './assets/svg/Instagram/Instagram';
import Twitter from './assets/svg/Twitter/Twitter';
import Facebook from './assets/svg/Facebook/Facebook';
import Youtube from './assets/svg/Youtube/Youtube';

import NavigationMenu from './components/navigation-menu/NavigationMenu';
import NewsletterPopup from './components/newsletter/popup/NewsletterPopup';

import AboutUs from './pages/about-us/AboutUs';
import ApplyBerbagiHati from './pages/apply-berbagi-hati/ApplyBerbagiHati';
import BerbagiHati from './pages/berbagi-hati/BerbagiHati';
import BerbagiHatiDetail from './pages/berbagi-hati-detail/BerbagiHatiDetail';
import ContactUs from './pages/contact-us/ContactUs';
import Donate from './pages/donate/Donate';
import Faq from './pages/faq/Faq';
import FindAProvider from './pages/find-a-provider/FindAProvider';
import FindAProviderDetail from './pages/find-a-provider-detail/FindAProviderDetail';
import GetInvolved from './pages/get-involved/GetInvolved';
import Home from './pages/home/Home';
import Maintenance from './pages/maintenance/Maintenance';
import MeetTheTeam from './pages/meet-the-team/MeetTheTeam';
import PaymentGateway from './pages/payment-gateway/PaymentGateway';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import ScreeningTest from './pages/screening-test/ScreeningTest';
import TermsAndAgreement from './pages/terms-and-agreement/TermsAndAgreement';
import ThankYou from './pages/thank-you/ThankYou';
import TidakSendiriLagi from './pages/tidak-sendiri-lagi/TidakSendiriLagi';
import UrgentHelp from './pages/urgent-help/UrgentHelp';
import Jobs from './pages/jobs/Jobs';

import client from './utils/apollo';
import GET_MAINTENANCES from './utils/queries/maintenances';

const DEED_NUMBER = 'AHU-00044.AH.02.02.TH.2014';

enum Language {
  EN = 'en',
  ID = 'id'
}

interface MerajutHatiProp {
  t: (prop: string) => string;
}

interface MerajutHatiState {
  isOnMaintenance: boolean
}

class MerajutHati extends React.Component<MerajutHatiProp, MerajutHatiState> {

  newsletterEmailInput: React.RefObject<any>;

  constructor(props: MerajutHatiProp) {
    super(props);
    this.state = {
      isOnMaintenance: false
    };

    this.newsletterEmailInput = React.createRef();
  }

  componentDidMount() {
    client.query({ query: GET_MAINTENANCES }).then((results: ApolloQueryResult<any>) => {
      const isOnMaintenance = results.data.maintenances[0].isOnMaintenance as boolean;
      this.setState({ isOnMaintenance });
    });
  }

  render() {
    console.log(`Merajut Hati running in ${process.env.NODE_ENV}...`);
    if (this.state.isOnMaintenance) {
      return (<Maintenance />);
    }

    const cookies = new Cookies();
    const isPageVisited = cookies.get('mh_is_page_visited');
    const newsletterPopup = <NewsletterPopup isPopupShown={!isPageVisited} i18n={i18n} />;
    cookies.set('mh_is_page_visited', true);
    return (
      <div>
        {/* Header */}
        <Row className={styles.header}>
          <Col className={styles.headerContainer} md={12} lg={6}>
            <Link to='/urgenthelp'>
              <img src={process.env.PUBLIC_URL + '/assets/icons/urgenthelp.svg'} alt="urgent-help" />
              {this.props.t('header.urgentHelp')}
            </Link>
            <span className={styles.headerSpacer}></span>
            <Link to='/donate'>
              <img src={process.env.PUBLIC_URL + '/assets/icons/donate.svg'} alt="donate" />
              {this.props.t('header.donate')}
            </Link>
          </Col>
          <Col className={styles.headerContainer} md={12} lg={6}>
            <span className={this.getLanguageSelectorClass(Language.EN)}
              onClick={this.changeLanguage.bind(this, Language.EN)}>
              English
            </span>
            <span className={this.getLanguageSelectorClass(Language.ID)}
              onClick={this.changeLanguage.bind(this, Language.ID)}>
              Indonesian
            </span>
          </Col>
        </Row>
        {/* Navigation Bar */}
        <NavigationMenu i18n={i18n} />
        {/* Pages */}
        <div>
          <Switch>
            <Route exact path='/' component={withTranslation('home')(Home)} />
            <Route exact path='/aboutus' component={withTranslation('about-us')(AboutUs)} />
            <Route exact path='/applyberbagihati' component={withTranslation('apply-berbagi-hati')(ApplyBerbagiHati)} />
            <Route exact path='/berbagihati' component={withTranslation('berbagi-hati')(BerbagiHati)} />
            <Route exact path='/berbagihati/:id' component={withTranslation('berbagi-hati-detail')(BerbagiHatiDetail)} />
            <Route exact path='/contactus' component={withTranslation('contact-us')(ContactUs)} />
            <Route exact path='/donate' component={withTranslation('donate')(Donate)} />
            <Route exact path='/faq' component={withTranslation('faq')(Faq)} />
            <Route exact path='/findaprovider' component={withTranslation('find-a-provider')(FindAProvider)} />
            <Route exact path='/findaprovider/:id' component={withTranslation('find-a-provider-detail')(FindAProviderDetail)} />
            <Route exact path='/meettheteam' component={withTranslation('meet-the-team')(MeetTheTeam)} />
            <Route exact path='/getinvolved' component={withTranslation('get-involved')(GetInvolved)} />
            <Route exact path='/jobs' component={withTranslation('jobs')(Jobs)} />
            <Route exact path='/paymentgateway' component={withTranslation('payment-gateway')(PaymentGateway)} />
            <Route exact path='/privacypolicy' component={withTranslation('privacy-policy')(PrivacyPolicy)} />
            <Route exact path='/screeningtest' component={withTranslation('screening-test')(ScreeningTest)} />
            <Route exact path='/termsandagreement' component={withTranslation('terms-and-agreement')(TermsAndAgreement)} />
            <Route exact path='/thankyou' component={withTranslation('thank-you')(ThankYou)} />
            <Route exact path='/tidaksendirilagi' component={withTranslation('tidak-sendiri-lagi')(TidakSendiriLagi)} />
            <Route exact path='/urgenthelp' component={withTranslation('urgent-help')(UrgentHelp)} />
          </Switch>
        </div>
        {/* Footer */}
        <div>
          <Row className={`${styles.footer} align-items-center`}>
            <ShowAt breakpoint="largeAndAbove">
              <Col md={12} lg={3} className={styles.copyright}>
                <div>
                  © 2020 - 2021 Merajut Hati Indonesia.
                </div>
                <div>
                  {i18n.t('footer.copyrightRegistration')}
                  <br />
                  {DEED_NUMBER}
                </div>
              </Col>
            </ShowAt>
            <Col md={12} lg={6}>
              <div className={styles.newsletter}>
                <p className={styles.newsletterHeader}>{i18n.t('newsletterPopup.title')}</p>
                <p className={styles.newsletterDescription}>{i18n.t('newsletterPopup.description')}</p>
                <div>
                  <InputGroup className={styles.newsletterInputGroup}>
                    <FormControl
                      placeholder={i18n.t('newsletterPopup.emailPlaceholder')}
                      aria-label="Your email"
                      aria-describedby="basic-addon2"
                      className={styles.footerNewsLetterTextfield}
                      ref={this.newsletterEmailInput}
                    />
                    <Button className={styles.submitButton} onClick={this.subscribeNewsletter}>
                      {i18n.t('newsletterPopup.join')}
                    </Button>
                  </InputGroup>
                </div>
              </div>
              <div className={styles.socialMedia}>
                <Link
                  to={{ pathname: 'https://www.instagram.com/yayasanmerajuthati/' }}
                  target='_blank'
                >
                  <Instagram />
                </Link>
                <Link
                  to={{ pathname: 'https://twitter.com/Merajuthati_' }}
                  target='_blank'
                >
                  <Twitter />
                </Link>
                <Link
                  to={{ pathname: 'https://www.youtube.com/channel/UChOdh-bBjrAnhvcoNdwAtqw' }}
                  target='_blank'
                >
                  <Youtube />
                </Link>
                <Link
                  to={{ pathname: 'https://www.facebook.com/yayasan.merajut.hati' }}
                  target='_blank'
                >
                  <Facebook />
                </Link>
              </div>
            </Col>
            <ShowAt breakpoint="mediumAndBelow">
              <Col md={12} lg={3} className={styles.copyright}>
                <div>
                  © 2020 - 2021 Merajut Hati Indonesia.
                </div>
                <div>
                  {i18n.t('footer.copyrightRegistration')}
                </div>
                <div>
                  {DEED_NUMBER}
                </div>
              </Col>
            </ShowAt>
            <ShowAt breakpoint="largeAndAbove">
              <Col md={12} lg={3} className={styles.links}>
                <div>
                  <Link to='faq'>FAQ</Link>
                </div>
                <div>
                  <Link to='getinvolved'>{i18n.t('footer.getInvolved')}</Link>
                </div>
                <div>
                  <Link to='privacypolicy'>{i18n.t('footer.privacyPolicy')}</Link>
                </div>
                <div>
                  <Link to='termsandagreement'>{i18n.t('footer.termsAndAgreement')}</Link>
                </div>
              </Col>
            </ShowAt>
          </Row>
        </div>
        <ShowAt breakpoint="mediumAndBelow">
          <div className={styles.footerMobileLinks}>
            <div>
              <Link to='faq'>FAQ</Link>
              <Link to='termsandagreement'>{i18n.t('footer.termsAndAgreement')}</Link>
            </div>
            <div>
              <Link to='getinvolved'>{i18n.t('footer.getInvolved')}</Link>
              <Link to='privacypolicy'>{i18n.t('footer.privacyPolicy')}</Link>
            </div>
          </div>
        </ShowAt>
        {newsletterPopup}
      </div>
    );
  }

  private changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  private getLanguageSelectorClass(lang: Language) {
    return lang === i18n.language ? styles.activeLanguageSelector : styles.languageSelector;
  }

  private subscribeNewsletter = () => {
    const email = this.newsletterEmailInput.current.value as string;
    fetch(`${process.env.REACT_APP_MH_MAILCHIMP_ENDPOINT}/subscribe`, {
      method: 'POST',
      body: JSON.stringify({
        email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status === 400) {
          // TODO: replace with react bootstrap toast
          alert('Email Required');
        } else {
          // TODO: replace with react bootstrap toast
          alert('Newsletter Subscription Successful');
        }
      })
      .catch(err => {
        console.error(err);
        // TODO: replace with react bootstrap toast
        alert('Unknown Error Occurred');
      });
  }
}

export default withTranslation()(MerajutHati);
