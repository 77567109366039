import React from 'react';

interface PhoneProp {
  className?: string;
}

class Phone extends React.Component<PhoneProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label='phone' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4353 11.0825L11.6953 9.82252C11.865 9.65492 12.0797 9.54019 12.3134 9.49227C12.547 9.44434 12.7896 9.46527 13.0116 9.55252L14.5472 10.1656C14.7715 10.2567 14.9639 10.4121 15.1 10.6123C15.2361 10.8125 15.31 11.0486 15.3122 11.2906V14.1031C15.3109 14.2678 15.2763 14.4306 15.2104 14.5815C15.1446 14.7325 15.0488 14.8685 14.929 14.9815C14.8092 15.0945 14.6677 15.1821 14.5132 15.239C14.3586 15.2959 14.1942 15.3209 14.0297 15.3125C3.26905 14.6431 1.0978 5.53065 0.687179 2.04315C0.668117 1.87189 0.685533 1.69853 0.738281 1.53448C0.791028 1.37044 0.877912 1.21942 0.993216 1.09136C1.10852 0.963305 1.24963 0.861113 1.40727 0.791509C1.5649 0.721905 1.73549 0.686465 1.9078 0.687523H4.62468C4.86712 0.688241 5.10381 0.761473 5.30429 0.897802C5.50477 1.03413 5.65989 1.22732 5.74968 1.45252L6.3628 2.98815C6.45295 3.20925 6.47594 3.45201 6.42892 3.6861C6.38191 3.9202 6.26695 4.13525 6.09843 4.3044L4.83843 5.5644C4.83843 5.5644 5.56405 10.475 10.4353 11.0825Z" fill="#38383C" />
      </svg>
    );
  }
}

export default Phone;
