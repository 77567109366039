import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ShowAt } from 'react-with-breakpoints';
import FoundationBackground from '../../assets/svg/AboutUs/FoundationBackground/FoundationBackground';
import CustomButton from '../../components/custom-button/CustomButton';
import styles from './AboutUs.module.css';

interface AboutUsProps {
  i18n: any;
  history: any;
}

class AboutUs extends React.Component<AboutUsProps> {

  render() {
    const { history, i18n } = this.props;

    const educationItem = [
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/education/tidak-sendiri-lagi.svg',
        imgAlt: 'tidak-sendiri-lagi',
        title: i18n.t('about-us:educationItem1Title'),
        description: i18n.t('about-us:educationItem1Description'),
        btnId: 'about-us-tidak-sendiri-lagi',
        btnLabel: i18n.t('about-us:educationItem1Button'),
        btnClickLink: '/tidaksendirilagi',
        btnIsExternalLink: false
      },
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/education/learn-about-mental-health.svg',
        imgAlt: 'learn-about-mental-health',
        title: i18n.t('about-us:educationItem2Title'),
        description: i18n.t('about-us:educationItem2Description'),
        btnId: 'about-us-learn-about-mental-health',
        btnLabel: i18n.t('about-us:educationItem2Button'),
        btnClickLink: 'https://www.instagram.com/yayasanmerajuthati/',
        btnIsExternalLink: true
      },
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/education/mh-talks-and-ruang-curhat.svg',
        imgAlt: 'mh-talks-and-ruang-curhat',
        title: i18n.t('about-us:educationItem3Title'),
        description: i18n.t('about-us:educationItem3Description'),
        btnId: 'about-us-mh-talk-and-ruang-curhat',
        btnLabel: i18n.t('about-us:educationItem3Button'),
        btnClickLink: 'https://www.instagram.com/yayasanmerajuthati/',
        btnIsExternalLink: true
      }
    ];

    const interventionItem = [
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/intervention/screening-questionare.svg',
        imgAlt: 'screening-questionare',
        title: i18n.t('about-us:interventionItem1Title'),
        description: i18n.t('about-us:interventionItem1Description'),
        btnId: 'about-us-screening-questionare',
        btnLabel: i18n.t('about-us:interventionItem1Button'),
        btnClickLink: '/screeningtest',
        btnIsExternalLink: false
      },
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/intervention/find-a-provider.svg',
        imgAlt: 'find-a-provider',
        title: this.props.i18n.t('about-us:interventionItem2Title'),
        description: this.props.i18n.t('about-us:interventionItem2Description'),
        btnId: 'about-us-find-a-provider',
        btnLabel: this.props.i18n.t('about-us:interventionItem2Button'),
        btnClickLink: '/findaprovider',
        btnIsExternalLink: false
      },
      {
        imgSrc: process.env.PUBLIC_URL + 'assets/img/about-us/intervention/berbagi-hati.svg',
        imgAlt: 'berbagi-hati',
        title: i18n.t('about-us:interventionItem3Title'),
        description: i18n.t('about-us:interventionItem3Description'),
        btnId: 'about-us-berbagi-hati',
        btnLabel: i18n.t('about-us:interventionItem3Button'),
        btnClickLink: '/applyberbagihati',
        btnIsExternalLink: false
      },
    ];

    return (
      <>
        {/* Vision & Mission */}
        <div className={styles.visionMissionSection}>
          {/* Vision & Mission Background */}
          <img className={styles.visionMissionBackground}
            alt='indonesia'
            src={process.env.PUBLIC_URL + '/assets/img/about-us/indonesia.png'} />
          {/* Vision & Mission Content */}
          <div className={styles.visionMissionContentContainer}>
            <h1 className={styles.header}>{this.props.i18n.t('about-us:title')}</h1>
            <div className={styles.visionMissionHeader}>
              {this.props.i18n.t('about-us:vision')}
            </div>
            <div className={styles.visionMissionDescriptionContainer}>
              <div className={styles.visionMissionDescription}>
                {this.props.i18n.t('about-us:visionDescription')}
              </div>
            </div>
            <div className={styles.visionMissionHeader}>
              {this.props.i18n.t('about-us:mission')}
            </div>
            <div className={styles.visionMissionDescriptionContainer}>
              <div className={styles.visionMissionDescription}>
                {this.props.i18n.t('about-us:missionDescription')}
              </div>
            </div>
          </div>
        </div>

        {/* Foundation */}
        <div className={styles.foundationSection}>
          {/* Background */}
          <ShowAt breakpoint='mediumAndAbove'>
            <FoundationBackground className={styles.foundationBackground} />
          </ShowAt>
          {/* Content */}
          <div className={styles.foundationContent}>
            <h1 className={styles.foundationHeader}>{this.props.i18n.t('about-us:foundation')}</h1>
            <div className={styles.foundationDescription}>
              {this.props.i18n.t('about-us:foundationDescPart1')}&nbsp;
              <strong>{this.props.i18n.t('about-us:foundationDescPart2')}</strong>&nbsp;
              {this.props.i18n.t('about-us:foundationDescPart3')}&nbsp;
              <strong>{this.props.i18n.t('about-us:foundationDescPart4')}</strong>&nbsp;
              {this.props.i18n.t('about-us:foundationDescPart5')}
            </div>
            <div className={styles.foundationBeliefsContainer}>
              <div className={styles.ellipseSmallContainer}>
                {this.props.i18n.t('about-us:foundationPillar1')}
              </div>
              <div className={styles.ellipseLargeContainer}>
                {this.props.i18n.t('about-us:foundationPillar2')}
              </div>
              <div className={styles.ellipseSmallContainer}>
                {this.props.i18n.t('about-us:foundationPillar3')}
              </div>
            </div>
          </div>
        </div>

        {/* TSL Campaign */}
        <div className={styles.campaignSection}>
          <div className={styles.campaignDescription}>
            {this.props.i18n.t('about-us:campaignDescription')}
          </div>
          <div className={styles.campaignHashtag}>
            #TIDAK<br />
            SENDIRILAGI
          </div>
        </div>

        {/* Our Work */}
        <div className={styles.ourWorkContainer}>
          <div className={styles.ourWorkTitle}>{this.props.i18n.t('about-us:ourWork')}</div>
          {/* Education */}
          <div className={styles.ourWorkSubtitle}>{this.props.i18n.t('about-us:education')}</div>
          <div className={styles.ourWorkSubtitleDesc}>
            {this.props.i18n.t('about-us:educationDescription')}
          </div>
          <Row>
            {educationItem.map((item, index) => (
              <Col lg={4} md={6} xs={12} key={index}>
                <div className={styles.posterContainer}>
                  <div className={styles.posterImgContainer}>
                    <img className={styles.posterImg}
                      src={item.imgSrc}
                      alt={item.imgAlt} />
                  </div>
                  <div className={styles.posterContentContainer}>
                    <div>
                      <div className={styles.posterTitle}>{item.title}</div>
                      <div className={styles.posterDescription}>
                        {item.description}
                      </div>
                    </div>
                    <div>
                      {
                        item.btnIsExternalLink ?
                          (
                            <Link className={styles.linkedButton}
                              to={{ pathname: item.btnClickLink }}
                              target='_blank'>
                              <CustomButton id={item.btnId} type='button'
                                colorType='tertiary' label={item.btnLabel}
                                ariaLabel={item.btnLabel} />
                            </Link>
                          ) :
                          (
                            <CustomButton id={item.btnId} type='button'
                              colorType='tertiary' label={item.btnLabel}
                              ariaLabel={item.btnLabel}
                              onClick={() => history.push(item.btnClickLink)} />
                          )
                      }
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          {/* Intervention */}
          <div className={`${styles.ourWorkSubtitle} ${styles.intervention}`}>{this.props.i18n.t('about-us:intervention')}</div>
          <div className={styles.ourWorkSubtitleDesc}>
            {this.props.i18n.t('about-us:interventionDescription')}
          </div>
          <Row>
            {interventionItem.map((item, index) => (
              <Col lg={4} md={6} xs={12} key={index}>
                <div className={styles.posterContainer}>
                  <div className={styles.posterImgContainer}>
                    <img className={styles.posterImg}
                      src={item.imgSrc}
                      alt={item.imgAlt} />
                  </div>
                  <div className={styles.posterContentContainer}>
                    <div>
                      <div className={styles.posterTitle}>{item.title}</div>
                      <div className={styles.posterDescription}>
                        {item.description}
                      </div>
                    </div>
                    <div>
                      {
                        item.btnIsExternalLink ?
                          (
                            <Link className={styles.linkedButton}
                              to={{ pathname: item.btnClickLink }}
                              target='_blank'>
                              <CustomButton id={item.btnId} type='button'
                                colorType='tertiary' label={item.btnLabel}
                                ariaLabel={item.btnLabel} />
                            </Link>
                          ) :
                          (
                            <CustomButton id={item.btnId} type='button'
                              colorType='tertiary' label={item.btnLabel}
                              ariaLabel={item.btnLabel}
                              onClick={() => history.push(item.btnClickLink)} />
                          )
                      }
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/* Branding */}
        <div className={styles.brandingContainer}>
          {/* Background */}
          <img
            className={styles.brandingBackground}
            alt='branding-background-image'
            src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/branding-background.svg'} />
          {/* Content */}
          <div className={styles.brandingContent}>
            <div className={styles.brandingSmallTitle}>{this.props.i18n.t('about-us:behind')}</div>
            <div className={styles.brandingTitle}>{this.props.i18n.t('about-us:nameAndLogo')}</div>
            <div className={styles.brandingDescription}>
              {this.props.i18n.t('about-us:brandingDescription')}
            </div>
            <div className={styles.brandingLogoContainer}>
              <div className={styles.brandingLogoItem}>
                <div>
                  <img
                    className={styles.brandingImage1}
                    alt='helping-hand-image-1'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/helping-hand-1.svg'} />
                </div>
                <div>
                  <img
                    className={styles.brandingImage2}
                    alt='helping-hand-image-2'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/helping-hand-2.svg'} />
                </div>
                <div className={styles.brandingName}>
                  {this.props.i18n.t('about-us:helpingHand')}
                </div>
              </div>
              <div className={styles.brandingLogoItem}>
                <div>
                  <img
                    className={styles.brandingImage1}
                    alt='embracing-image-1'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/embracing-1.svg'} />
                </div>
                <div>
                  <img
                    className={styles.brandingImage2}
                    alt='embracing-image-2'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/embracing-2.svg'} />
                </div>
                <div className={styles.brandingName}>
                  {this.props.i18n.t('about-us:embracing')}
                </div>
              </div>
              <div className={styles.brandingLogoItem}>
                <div>
                  <img
                    className={styles.brandingImage1}
                    alt='heart-1'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/heart-1.svg'} />
                </div>
                <div>
                  <img
                    className={styles.brandingImage2}
                    alt='heart-2'
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/branding/heart-2.svg'} />
                </div>
                <div className={styles.brandingName}>
                  {this.props.i18n.t('about-us:heart')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Questions */}
        <div className={styles.questionsContainer}>
          {/* Background */}
          <ShowAt breakpoint='small'>
            <img
              className={styles.questionsBackground}
              alt='questions-background-image'
              src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/questions-background-mobile.svg'} />
          </ShowAt>
          <ShowAt breakpoint='mediumAndAbove'>
            <img
              className={styles.questionsBackground}
              alt='questions-background-image'
              src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/questions-background.svg'} />
          </ShowAt>
          {/* Content */}
          <div className={styles.questionsContentContainer}>
            <div className={styles.windowWrapper}>
              <div className={styles.windowContainer}>
                <div className={styles.windowTabContainer}>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/red-ellipse.svg'}
                    alt='red-ellipse' />
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/yellow-ellipse.svg'}
                    alt='yellow-ellipse' />
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/green-ellipse.svg'}
                    alt='green-ellipse' />
                </div>
                <div className={styles.windowContentContainer}>
                  <div className={styles.windowTitle}>{this.props.i18n.t('about-us:getInvolved')}</div>
                  <div className={styles.windowDescription}>
                    {this.props.i18n.t('about-us:getInvolvedDescription1')}
                    <Link to='/getinvolved'>{this.props.i18n.t('about-us:getInvolvedDescription2')}</Link>
                    {this.props.i18n.t('about-us:getInvolvedDescription3')}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.windowWrapper}>
              <div className={styles.windowContainer}>
                <div className={styles.windowTabContainer}>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/red-ellipse.svg'}
                    alt='red-ellipse' />
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/yellow-ellipse.svg'}
                    alt='yellow-ellipse' />
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/about-us/questions/green-ellipse.svg'}
                    alt='green-ellipse' />
                </div>
                <div className={styles.windowContentContainer}>
                  <div className={styles.windowTitle}>{this.props.i18n.t('about-us:haveAnyQuestions')}</div>
                  <div className={styles.windowDescription}>
                    {this.props.i18n.t('about-us:haveAnyQuestionsDescription1')}
                    <Link to={{ pathname: 'mailto: contact@merajuthati.org' }} target='_blank'>
                      contact@merajuthati.org
                    </Link>
                    {this.props.i18n.t('about-us:haveAnyQuestionsDescription2')}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
