import React from 'react';

interface GeneralDonationProp {
  className?: string;
}

class GeneralDonation extends React.Component<GeneralDonationProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label="general-donation" width="112" height="186" viewBox="0 0 112 186" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3149:3981)">
          <rect width="112" height="186" rx="12" fill="#4A8870" />
          <circle cx="56" cy="93" r="75" fill="#B9CEC4" />
          <path d="M40.7033 162.463C40.7033 170.993 39.2063 181.705 38.4578 185.995H73.6376V175.701V156.213C73.6376 149.962 73.6814 146.443 75.1347 142.976C77.7545 136.725 80 131.588 80 125.326C80 119.076 78.8772 112.089 78.503 109.516C78.1287 106.942 75.5089 96.6461 72.1406 96.6456C68.4546 96.6449 35.4637 96.2779 33.9667 98.1163C32.4696 99.9548 31.7211 105.838 32.0954 115.03C32.4696 124.223 33.9667 130.106 35.4637 137.092C36.9607 144.078 40.7033 151.8 40.7033 162.463Z" fill="#F9C9B4" stroke="#F9C9B4" />
          <path d="M38.4578 185.996C39.2063 181.706 40.7033 170.994 40.7033 162.464C40.7033 151.801 36.9607 144.079 35.4637 137.093C33.9667 130.107 32.4696 124.223 32.0954 115.031C31.7211 105.839 32.4696 99.9557 33.9667 98.1172" stroke="#E8A78B" />
          <path d="M32.0957 99.5241C32.0957 97.541 33.9176 95.7532 34.9714 95.1849C36.6968 94.2542 38.7097 93.9454 41.0103 95.8049C43.1085 97.5009 44.461 100.142 46.1864 104.79C47.5668 108.508 47.0491 112.433 46.474 114.086C46.0433 115.323 45.4388 117.31 43.5983 117.806C41.8729 118.271 41.0103 117.806 39.5724 116.257C38.1061 114.677 36.701 110.781 35.8383 107.682C35.0332 104.79 32.0957 102.003 32.0957 99.5241Z" fill="#F9C9B4" stroke="#E8A78B" />
          <rect x="0.617803" y="0.350509" width="11.168" height="27.7163" rx="5.58401" transform="matrix(0.965146 -0.261712 0.270461 0.962731 38.7588 90.4462)" fill="#F9C9B4" stroke="#E8A78B" />
          <rect x="0.575129" y="0.414753" width="12.1093" height="33.0869" rx="6.05467" transform="matrix(0.987571 -0.157172 0.162686 0.986678 48.9455 85.9104)" fill="#F9C9B4" stroke="#E8A78B" />
          <rect x="0.713291" y="-0.00770465" width="9.56227" height="20.0832" rx="4.78114" transform="matrix(0.705721 -0.708489 0.720861 0.69308 45.9967 73.8233)" fill="#F9C9B4" stroke="#E8A78B" />
          <path d="M51.9315 125.698C51.6321 129.228 52.5553 133.787 53.0543 135.626L79.2523 131.949C80.7494 123.86 79.2523 114.3 78.5038 108.416C77.7553 102.533 73.2642 94.8117 72.1414 92.6054C71.0187 90.3991 68.7731 83.7809 69.1474 80.1039C69.5216 76.427 70.2701 73.4853 70.6444 70.5437C71.0187 67.6022 67.6503 68.3376 66.9018 68.3376C66.1533 68.3376 59.7909 72.7499 57.9196 78.2653C56.0484 83.7807 56.7969 90.0315 57.9196 92.6054C59.0424 95.1792 60.9137 102.533 60.9137 105.475C60.9137 108.416 59.0424 111.358 57.9196 113.196C56.7969 115.035 52.3058 121.286 51.9315 125.698Z" fill="#F9C9B4" />
          <path d="M53.0543 135.628C52.5553 133.789 51.6321 129.23 51.9315 125.7C52.3058 121.287 56.7969 115.037 57.9196 113.198C59.0424 111.36 60.9137 108.418 60.9137 105.477C60.9137 102.535 59.0424 95.1812 57.9196 92.6073C56.7969 90.0335 56.0484 83.7827 57.9196 78.2672C59.7909 72.7518 64.6582 69.8122 66.5276 68.7057C67.6503 68.041 71.0187 67.6041 70.6444 70.5457C70.2701 73.4872 69.5216 76.4289 69.1474 80.1059C68.7731 83.7828 71.0187 90.401 72.1414 92.6073C73.2642 94.8137 77.7553 102.535 78.5038 108.418C79.2523 114.301 80.7494 123.861 79.2523 131.951C77.7553 140.04 74.0127 141.878 73.6385 149.6C73.3391 155.777 73.5137 176.442 73.6385 186.002" stroke="#E8A78B" />
          <path d="M62.2102 50.5037C55.8491 38.0829 41.0864 46.692 61.2737 62.8989C81.4985 50.6191 70.8952 37.0502 62.2102 50.5037Z" fill="#F47A7A" />
        </g>
        <defs>
          <clipPath id="clip0_3149:3981">
            <rect width="112" height="186" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>

    );
  }
}

export default GeneralDonation;
