import React from 'react';
import { Row } from 'react-bootstrap';

import styles from './CustomCarousel.module.css';
import Carousel from 'react-bootstrap/Carousel';
import CustomButton from '../custom-button/CustomButton';

interface CustomCarouselProp {
  content: JSX.Element;
  actionButtonText?: string;
  actionButtonOnClick?(): any;
  actionButtonIconURL?: string;
  isDisplayButton: boolean;
  showIndicator?: boolean;
}

interface CustomCarouselState {
  index: number;
}

class CustomCarousel extends React.Component<CustomCarouselProp, CustomCarouselState> {
  constructor(props: CustomCarouselProp) {
    super(props);
    this.state = {
      index: 0
    };
    this.prevCarousel = this.prevCarousel.bind(this);
    this.nextCarousel = this.nextCarousel.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  prevCarousel() {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
      return;
    }
    this.setState({ index: this.props.content.props.children.length - 1 });
  }

  nextCarousel() {
    if (this.state.index < this.props.content.props.children.length - 1) {
      this.setState({ index: this.state.index + 1 });
      return;
    }
    this.setState({ index: 0 });
  }

  renderButton() {
    if (this.props.isDisplayButton) {
      return (
        <CustomButton id='carousel-action-button' type='button' label={this.props.actionButtonText || ''}
          onClick={this.props.actionButtonOnClick} ariaLabel={this.props.actionButtonText || ''}
          imgSrc={`${process.env.PUBLIC_URL}${this.props.actionButtonIconURL}`} colorType='secondary'>
        </CustomButton>
      );
    }
  }

  render() {
    return (
      <div>
        <div className={this.shouldShowIndicator() ? styles.carouselWrapper : ''}>
          <Carousel activeIndex={this.state.index} controls={false}>
            {this.props.content.props.children}
          </Carousel>
        </div>
        <div className={styles.carouselFooterContainer}>
          {this.renderButton()}
          <Row>
            <img
              className={styles.navigationButton}
              src={`${process.env.PUBLIC_URL}/assets/icons/left-button.svg`}
              alt="prev"
              onClick={this.prevCarousel}
            />
            <img
              className={styles.navigationButton}
              src={`${process.env.PUBLIC_URL}/assets/icons/right-button.svg`}
              alt="next"
              onClick={this.nextCarousel}
            />
          </Row>
        </div>
      </div>
    );
  }

  private shouldShowIndicator() {
    return !this.props.isDisplayButton && this.props.showIndicator;
  }
}

export default CustomCarousel;
