import React from 'react';
import styles from './CustomFormField.module.css';

interface Option {
  height: string,
  width: string
}

interface CustomFormFieldProps {
  errorMessage?: string;
  isFocus?: boolean;
  isOptional?: boolean;
  name: string;
  placeholder: string;
  value: string;
  option?: Option;
  type?: string;
  pattern?: string;
  maxLength?: number;
  autoComplete?: string;
  onChange: (type: string, input: CustomFormFieldState) => void;
  validator?: (input: string) => boolean;
}

export interface CustomFormFieldState {
  isDirty: boolean;
  isValid: boolean;
  value: string;
}

class CustomFormField extends React.Component<CustomFormFieldProps, CustomFormFieldState> {
  constructor(props: CustomFormFieldProps) {
    super(props);
    this.state = {
      isDirty: false,
      isValid: true,
      value: ''
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  private containerClassNames() {
    const container = this.props.option ? '' : styles.container;
    const height = this.props.option ? this.props.option.height : '';
    const width = this.props.option ? this.props.option.width : '';
    return `${container} ${height} ${width}`;
  }

  private customFormFieldClassNames() {
    const error = !this.state.isValid ? styles.customFormFieldError : '';
    return `${styles.customFormField} ${error}`;
  }

  private errorMessage() {
    return (
      <div className={styles.errorMessage}>{this.props.errorMessage}</div>
    );
  }

  private handleOnChange() {
    this.props.onChange(this.props.name, this.state);
  }

  render() {
    return (
      <div className={this.containerClassNames()}>
        <label
          className={styles.inputWrapper}
        >
          <input
            className={this.customFormFieldClassNames()}
            placeholder={this.props.placeholder}
            type={this.props.type}
            pattern={this.props.pattern}
            maxLength={this.props.maxLength}
            autoComplete={this.props.autoComplete}
            value={this.state.value}
            onChange={(e) => {
              this.setState({ value: e.currentTarget.value }, this.handleOnChange);
            }}
            onBlur={(e) => {
              this.setState({
                isDirty: true,
                isValid: this.props.validator ? this.props.validator(e.currentTarget.value) : true
              }, this.handleOnChange);
            }}
          />
          {this.props.isOptional && !this.state.value ? <div className={styles.optionalText}>Optional</div> : null}
        </label>
        {!this.state.isValid ? this.errorMessage() : undefined}
      </div>
    );
  }
}

export default CustomFormField;
