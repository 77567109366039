import React from 'react';

interface CheckProp {
  className?: string;
}

class Check extends React.Component<CheckProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label="check" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.456 0.454736C10.6673 0.253569 10.9485 0.142356 11.2402 0.144563C11.5319 0.146771 11.8114 0.262226 12.0196 0.466567C12.2279 0.670908 12.3486 0.948152 12.3563 1.2398C12.364 1.53144 12.2581 1.81468 12.061 2.02974L6.07597 9.51474C5.97306 9.62558 5.84885 9.71454 5.71077 9.77629C5.57269 9.83803 5.42358 9.8713 5.27235 9.8741C5.12112 9.8769 4.97087 9.84918 4.8306 9.79259C4.69033 9.73599 4.56292 9.6517 4.45597 9.54474L0.486969 5.57574C0.376439 5.47274 0.287785 5.34854 0.226297 5.21054C0.16481 5.07254 0.131747 4.92357 0.129081 4.77252C0.126416 4.62147 0.154203 4.47142 0.210785 4.33134C0.267367 4.19126 0.351583 4.06401 0.458412 3.95718C0.56524 3.85035 0.69249 3.76613 0.832573 3.70955C0.972655 3.65297 1.1227 3.62518 1.27375 3.62785C1.42481 3.63051 1.57378 3.66358 1.71178 3.72506C1.84978 3.78655 1.97398 3.87521 2.07697 3.98574L5.21797 7.12524L10.4275 0.487736C10.4368 0.476187 10.4469 0.46517 10.4575 0.454736H10.456Z" fill="#38383C" />
      </svg>

    );
  }
}

export default Check;
