import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './UrgentHelp.module.css';

interface UrgentHelpProp {
  i18n: any;
}

class UrgentHelp extends React.Component<UrgentHelpProp> {
  render() {
    return (
      <div className='page-container'>
        <h1 className={styles.urgent}>{this.props.i18n.t('urgent-help:title')}</h1>
        <Row className={styles.reverseWrap}>
          <Col xs={12} lg={6}>
            <Row>
              <a href='tel:112' className={styles.urgentNumber}>
                112
              </a>
              <Col>
                <div className={styles.insideUse}>
                  {this.props.i18n.t('urgent-help:lifeThreatening.usage')}
                </div>
                <div className={styles.insideTitle}>
                  {this.props.i18n.t('urgent-help:lifeThreatening.title')}
                </div>
              </Col>
            </Row>
            <Row>
              <a href='tel:119' className={styles.urgentNumber}>
                119
              </a>
              <Col>
                <div className={styles.insideUse}>
                  {this.props.i18n.t('urgent-help:mentalHealth.usage')}
                </div>
                <div className={styles.insideTitle}>
                  {this.props.i18n.t('urgent-help:mentalHealth.title')}
                </div>
                <div className={styles.inside}>
                  {this.props.i18n.t('urgent-help:mentalHealth.description1')}
                  <br />
                  <br />
                  {this.props.i18n.t('urgent-help:mentalHealth.description2')}
                  <br />
                  {this.props.i18n.t('urgent-help:mentalHealth.description3')}
                  <br />
                  <br />
                  {this.props.i18n.t('urgent-help:mentalHealth.description4')}
                  <br />
                  {this.props.i18n.t('urgent-help:mentalHealth.description5')}
                  <br />
                  {this.props.i18n.t('urgent-help:mentalHealth.description6')}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <img className={styles.urgentHelpImage} src={process.env.PUBLIC_URL + '/assets/img/urgent-help/urgent-help-illust.svg'}
              alt="Urgent Help image" />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UrgentHelp;
