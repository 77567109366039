import { ApolloQueryResult } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BreakpointContext, ShowAt } from 'react-with-breakpoints';
import CustomButton from '../../components/custom-button/CustomButton';
import CustomCarousel from '../../components/custom-carousel/CustomCarousel';
import styles from './ApplyBerbagiHati.module.css';

import client from '../../utils/apollo';
import { GET_CLIENTS_BY_ISCOMPLETE } from '../../utils/queries/clients';
import { Breakpoint } from '../../utils/breakpoint';

const START_DATE_FORMAT = 'DD MMMM';
const END_DATE_FORMAT = 'DD MMMM yyyy';

interface BerbagiHatiApplicationCycle {
  startDate: string;
  endDate: string;
  isActive: boolean;
}

interface ApplyBerbagiHatiProps {
  i18n: any;
  history: any;
}

interface ApplyBerbagiHatiState {
  isLoading: boolean;
  applicationCycle: BerbagiHatiApplicationCycle;
  successClients: Client[];
}

interface Client {
  age: number;
  description: string;
  isComplete: boolean;
  name: string;
  numberOfSponsors: number;
  profilePicture: Cover;
  raised: number;
}

interface Cover {
  name: string;
  url: string;
}

class ApplyBerbagiHati extends React.Component<ApplyBerbagiHatiProps, ApplyBerbagiHatiState> {

  private currentBreakpoint = '';

  constructor(props: ApplyBerbagiHatiProps) {
    super(props);
    this.state = {
      isLoading: true,
      applicationCycle: {
        isActive: false
      } as BerbagiHatiApplicationCycle,
      successClients: []
    };
  }

  componentDidMount = () => {
    const applicationCycleEndpoint = `${process.env.REACT_APP_MH_STRAPI_ENDPOINT}/berbagi-hati-application-cycles`;
    let applicationCycle: any;
    fetch(applicationCycleEndpoint)
      .then(res => res.json())
      .then((berbagiHatiApplicationCycles: BerbagiHatiApplicationCycle[]) => {
        // Note: assuming that there is only 1 berbagi hati application cycle
        applicationCycle = berbagiHatiApplicationCycles[0];
        return client.query({
          query: GET_CLIENTS_BY_ISCOMPLETE,
          variables: {
            limit: 6,
            isComplete: true
          }
        });
      })
      .then((results: ApolloQueryResult<any>) => {
        const successClients = results.data.clients as Client[];
        this.setState({
          isLoading: false,
          applicationCycle,
          successClients
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    return (
      <BreakpointContext.Consumer>
        {value => {
          this.currentBreakpoint = value.currentBreakpoint;
          return this.getApplyBerbagiHati();
        }}
      </BreakpointContext.Consumer>
    );
  }

  private getApplyBerbagiHati() {
    return (
      <>
        <div className='page-container'>
          {/* Header */}
          <div className={styles.headerContainer}>
            <h2 className={styles.title}>Berbagi Hati</h2>
            <h1 className={styles.title}>{this.props.i18n.t('apply-berbagi-hati:programApplication')}</h1>
            <Row>
              <Col xs={12} lg={6} className={styles.titleDescriptionContainer}>
                <p className={styles.titleDescription}>
                  {this.props.i18n.t('apply-berbagi-hati:titleDescription')}
                </p>
              </Col>
              <Col xs={12} lg={6}>
                <div className={styles.headerImageContainer}>
                  <ShowAt breakpoint='largeAndAbove'>
                    <img
                      src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/apply-berbagi-hati-background2.svg'}
                      alt='apply-berbagi-hati-background-2'
                      className={styles.applyBHBackgroundImage2}
                    />
                    <img
                      src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/apply-berbagi-hati-background1.svg'}
                      alt='apply-berbagi-hati-background-1'
                      className={styles.applyBHBackgroundImage1}
                    />
                  </ShowAt>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/apply-berbagi-hati.svg'}
                    alt='apply-berbagi-hati-image'
                    className={styles.applyBHImage} />
                </div>
              </Col>
            </Row>
          </div>

          {/* Why Berbagi Hati */}
          <div className={styles.descriptionTitle}>
            {this.props.i18n.t('apply-berbagi-hati:whyBerbagiHati')}
          </div>
          <div className={styles.berbagiHatiReasonContainer}>
            <Row>
              <Col lg={4} xs={12} className={styles.berbagiHatiReasonTitle}>
                <div>
                  <b>01</b>
                </div>
                <div>
                  <span className={styles.berbagiHatiReason}>{this.props.i18n.t('apply-berbagi-hati:reason1Part1')}</span>
                  <b>{this.props.i18n.t('apply-berbagi-hati:reason1Part2')}</b>
                  <span className={styles.berbagiHatiReason}>{this.props.i18n.t('apply-berbagi-hati:reason1Part3')}</span>
                </div>
              </Col>
              <Col lg={8} xs={12} className={styles.berbagiHatiReasonDescription}>
                {this.props.i18n.t('apply-berbagi-hati:reason1Description')}
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={12} className={styles.berbagiHatiReasonTitle}>
                <div>
                  <b>02</b>
                </div>
                <div>
                  <span className={styles.berbagiHatiReason}>{this.props.i18n.t('apply-berbagi-hati:reason2Part1')}</span>
                  <b>{this.props.i18n.t('apply-berbagi-hati:reason2Part2')}</b>
                  <span className={styles.berbagiHatiReason}>{this.props.i18n.t('apply-berbagi-hati:reason2Part3')}</span>
                </div>
              </Col>
              <Col lg={8} xs={12} className={styles.berbagiHatiReasonDescription}>
                {this.props.i18n.t('apply-berbagi-hati:reason2Description')}
              </Col>
            </Row>

            <Row>
              <Col lg={4} xs={12} className={styles.berbagiHatiReasonTitle}>
                <div>
                  <b>03</b>
                </div>
                <div>
                  <span className={styles.berbagiHatiReason}>{this.props.i18n.t('apply-berbagi-hati:reason3Part1')}</span>
                  <b>{this.props.i18n.t('apply-berbagi-hati:reason3Part2')}</b>.
                </div>
              </Col>
              <Col lg={8} xs={12} className={styles.berbagiHatiReasonDescription}>
                {this.props.i18n.t('apply-berbagi-hati:reason3DescriptionPart1')}
                <Link
                  to={{ pathname: 'https://www.instagram.com/yayasanmerajuthati/' }}
                  target='_blank'>Instagram
                </Link>
                {this.props.i18n.t('apply-berbagi-hati:reason3DescriptionPart2')}
                <Link to='/privacypolicy'>
                  {this.props.i18n.t('apply-berbagi-hati:reason3DescriptionPart3')}
                </Link>.
              </Col>
            </Row>
          </div>

          {/* Application Cycle */}
          {
            this.state.applicationCycle.isActive ? (
              <div className={styles.applicationCycleContainer}>
                {/* Application Cycle Background */}
                <ShowAt breakpoint='largeAndAbove'>
                  <img
                    className={styles.applicationCycleBackground}
                    alt='application-cycle-background-image'
                    src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/application-cycle-background.svg'} />
                </ShowAt>
                {/* Application Cycle Content */}
                <div className={styles.applicationCycleContentContainer}>
                  <div className={styles.applicationTitle}>
                    {this.props.i18n.t('apply-berbagi-hati:applyToOurProgram')}
                  </div>
                  <div className={styles.applicationCycleDescription}>
                    <span>{this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart1')}</span>
                    <strong>{this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart2')}</strong>
                    <span>{this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart3')}</span>
                    <Link to='/screeningtest'>{this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart4')}</Link>
                    <span> {this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart5')}</span>
                    <strong>{this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart6')}</strong>
                    <span> {this.props.i18n.t('apply-berbagi-hati:applicationCycleDescriptionPart7')}</span>
                  </div>
                  {/* TODO: replace with linked button component after refactor */}
                  <Link
                    to={{ pathname: 'https://forms.gle/pTPz6TPec3P4KVBh8' }}
                    target='__blank'
                    className={styles.linkedButton}>
                    <CustomButton
                      type='button'
                      id='berbagi-hati-apply-sponsorship'
                      ariaLabel='Apply Sponsorship'
                      label={this.props.i18n.t('apply-berbagi-hati:applyForSponsorship')} />
                  </Link>
                </div>
              </div>
            ) : null}

          {/* Success Stories */}
          {
            this.state.successClients.length ?
              (
                <div className={styles.successStoriesContainer}>
                  <div className={styles.successStoriesTitle}>{this.props.i18n.t('apply-berbagi-hati:successStories')}</div>
                  {this.getSuccessStories()}
                </div>
              ) : null
          }
        </div>
        {/* Fund Others */}
        <div className={styles.fundOthersContainer}>
          {/* PC View */}
          <ShowAt breakpoint='mediumAndAbove'>
            <Row>
              <Col md={6} xs={12}>
                <img className={styles.fundOthersImage}
                  src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/fund-others.svg'}
                  alt='fund-others' />
              </Col>
              <Col md={6} xs={12}>
                <div className={styles.fundOthersTitle}>
                  {this.props.i18n.t('apply-berbagi-hati:fundOthersTitle')}
                </div>
                <div className={styles.fundOthersDesc}>
                  {this.props.i18n.t('apply-berbagi-hati:fundOthersDescription')}
                </div>
                <CustomButton
                  type='button'
                  id='apply-berbagi-hati-fund-others'
                  ariaLabel={this.props.i18n.t('apply-berbagi-hati:fundOthersBtn')}
                  label={this.props.i18n.t('apply-berbagi-hati:fundOthersBtn')}
                  colorTheme='light' colorType='primary'
                  onClick={this.navigateToPaymentGateway} />
              </Col>
            </Row>
          </ShowAt>
          {/* Mobile & Tablet View */}
          <ShowAt breakpoint='small'>
            <Row>
              <Col md={6} xs={12}>
                <div className={styles.fundOthersTitle}>
                  {this.props.i18n.t('apply-berbagi-hati:fundOthersTitle')}
                </div>
                <div className={styles.fundOthersDesc}>
                  {this.props.i18n.t('apply-berbagi-hati:fundOthersDescription')}
                </div>
              </Col>
              <Col md={6} xs={12}>
                <img className={styles.fundOthersImage}
                  src={process.env.PUBLIC_URL + '/assets/img/apply-berbagi-hati/fund-others.svg'}
                  alt='fund-others' />
              </Col>
            </Row>
            <div>
              <CustomButton
                type='button'
                id='apply-berbagi-hati-fund-others'
                ariaLabel={this.props.i18n.t('apply-berbagi-hati:fundOthersBtn')}
                label={this.props.i18n.t('apply-berbagi-hati:fundOthersBtn')}
                colorTheme='light' colorType='primary'
                onClick={this.navigateToPaymentGateway} />
            </div>
          </ShowAt>
        </div>
      </>
    );
  }

  private getSuccessStories = () => {
    if (this.currentBreakpoint === Breakpoint.XLARGE
      || this.currentBreakpoint === Breakpoint.LARGE) {
      return <CustomCarousel key='pc-success-stories' content={this.getPCSuccessStoriesContent()} isDisplayButton={false} />;
    } else {
      return <CustomCarousel key='mobile-success-stories' content={this.getMobileSuccessStoriesContent()} isDisplayButton={false} />;
    }
  }

  private getMobileSuccessStoriesContent() {
    return (
      <>
        {this.state.successClients.map((client: Client, index: number) => {
          return (
            <Carousel.Item key={index}>
              <div className={styles.carouselItemContainer}>
                {this.getClient(client)}
              </div>
            </Carousel.Item>
          );
        })}
      </>
    );
  }
  private getPCSuccessStoriesContent() {
    const successClientsCarouselItems = [];
    const numItemsPerRow = 4;
    for (let i = 0; i < this.state.successClients.length; i += numItemsPerRow) {
      const clients = [];
      for (let j = 0; j < numItemsPerRow; j++) {
        if (j === 0) {
          clients.push(this.state.successClients[j]);
        } else if (this.state.successClients[i + j]) {
          clients.push(this.state.successClients[i + j]);
        }
      }
      successClientsCarouselItems.push(clients);
    }

    return (
      <>
        {
          successClientsCarouselItems.map((clients: Client[], index: number) => {
            return (
              <Carousel.Item key={index}>
                <div className={styles.carouselItemContainer}>
                  {clients.map((client: Client) => this.getClient(client))}
                </div>
              </Carousel.Item>
            );
          })
        }
      </>
    );

  }

  private getClient(client: Client) {
    return (
      <div className={styles.clientContainer} key={client.name}>
        <div className={styles.clientProfilePictureContainer}>
          <img className={styles.clientProfilePicture}
            src={client.profilePicture.url}
            alt={client.profilePicture.name} />
        </div>
        <div className={styles.clientContentContainer}>
          <div>
            <div className={styles.clientTitle}>{client.name}, {client.age}</div>
            <div className={styles.clientDescription}>
              {this.truncateString(client.description)}
            </div>
          </div>
          <div className={styles.clientReport}>
            IDR{this.formatNumber(client.raised)}&ensp;
            <span className={styles.clientReportLight}>{this.props.i18n.t('apply-berbagi-hati:clientRaisedFrom')}</span>&ensp;
            {this.formatNumber(client.numberOfSponsors)}&ensp;
            <span className={styles.clientReportLight}>{this.props.i18n.t('apply-berbagi-hati:clientDonors')}</span>
          </div>
        </div>
      </div>
    );
  }

  private formatNumber(amount: number) {
    return amount.toLocaleString();
  }

  private navigateToPaymentGateway = () => {
    this.props.history.push({
      pathname: '/paymentgateway',
      search: '?type=berbagihati'
    });
  }

  // TODO: refactor into utilities for showing description
  private truncateString(input: string, maxLength = 120): string {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input;
  }
}

export default ApplyBerbagiHati;
