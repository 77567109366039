import React from 'react';
import styles from './TidakSendiriLagi.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Tooltip from '../../assets/svg/Tooltip/Tooltip';
import CustomButton from '../../components/custom-button/CustomButton';

import client from '../../utils/apollo';
import GET_TIDAK_SENDIRI_LAGIS from '../../utils/queries/tidakSendiriLagis';
import Spinner from '../../components/spinner/Spinner';

interface TidakSendiriLagiProps {
  i18n: any
}

interface TidakSendiriLagiState {
  isLoading: boolean;
  isHideButton: boolean;
  media: Media[];
  toolTipOpened: boolean;
}

interface Media {
  cover: Cover;
  id: number;
  instagramURL: string;
  name: string;
}

interface Cover {
  name: string;
  url: string;
}

class TidakSendiriLagi extends React.Component<TidakSendiriLagiProps, TidakSendiriLagiState> {
  constructor(props: TidakSendiriLagiProps) {
    super(props);
    this.state = {
      isLoading: false,
      isHideButton: false,
      media: [],
      toolTipOpened: false
    };
  }

  componentDidMount = () => {
    this.exploreMore();
  }

  exploreMore = () => {
    this.toggleLoading();
    client.query({
      query: GET_TIDAK_SENDIRI_LAGIS,
      variables: {
        start: this.state.media.length,
        limit: 6
      }
    })
      .then((media) => {
        const data = [...media.data.tidakSendiriLagis];
        if (data.length < 6) {
          this.toggleMediaButton();
        }
        this.setState({
          media: [...this.state.media, ...data]
        });
        this.toggleLoading();
      })
      .catch(error => {
        console.error(error);
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading
    });
  }

  toggleMediaButton = () => {
    this.setState({
      isHideButton: !this.state.isHideButton
    });
  }

  toggletoolTip = () => {
    this.setState({
      toolTipOpened: !this.state.toolTipOpened
    });
  }

  private showButton = () => {
    if (this.state.isLoading) {
      return (
        <div className={styles.spinnerWrapper}>
          <Spinner option={{ height: styles.spinnerHeight, width: styles.spinnerWidth, border: styles.spinnerBorder }} />
        </div>
      );
    } else if (!this.state.isHideButton) {
      return (
        <CustomButton ariaLabel='tsl explore more' id='tsl-explore-more' type='button'
          label={this.props.i18n.t('tidak-sendiri-lagi:exploreMoreButton')}
          onClick={this.exploreMore} />
      );
    }
  }

  private toolTip = () => {
    return (
      <Container className={styles.toolTipContainer}>
        <Row className={styles.toolTipHeader}>
          <Col>
            {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tip')}
          </Col>
          <Col>
            <img src={process.env.PUBLIC_URL + '/assets/icons/chat-right-quote.svg'} alt="chat-right-quote" />
          </Col>
        </Row>
        <Row className={styles.toolTipContent}>
          <Col>
            <div>
              {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tipDescription')}
            </div>
            <ol className={styles.toolTipQuestions}>
              <li>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tipQuestion1')}</li>
              <li>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tipQuestion2')}</li>
              <li>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tipQuestion3')}</li>
              <li>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tipQuestion4')}</li>
            </ol>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    return (
      <>
        <div className='page-container'>
          <h1>
            {this.props.i18n.t('navbar.tidakSendiriLagi')}
          </h1>
          <div className={styles.descriptionContainer}>
            <div className={styles.descriptionText}>
              <div className={styles.descriptionImage}>
                <img src={process.env.PUBLIC_URL + '/assets/img/tidak-sendiri-lagi/tidak-sendiri-lagi-description.svg'}
                  alt="tidak-sendiri-lagi-description-image" />
              </div>
              <div className={styles.textHeader}>
                {this.props.i18n.t('tidak-sendiri-lagi:descriptionSection.title')}
              </div>
              <div className={styles.textContent}>
                {this.props.i18n.t('tidak-sendiri-lagi:descriptionSection.description')}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.instructionContainer} page-container`}>
          <div className={styles.textHeader}>
            {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.title')}
          </div>
          <div className={styles.textContent}>
            {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.description')}
          </div>
          <Row className={styles.submission}>
            <Col md='12' lg='2' className={styles.instruction}>
              <div className={styles.textSubHeader}>
                {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.submission')}
              </div>
              <div className={styles.toolTipIcon}>
                <Tooltip className={styles.toolTipSvg} onMouseEnter={this.toggletoolTip} onMouseLeave={this.toggletoolTip} />
                {this.state.toolTipOpened ? this.toolTip() : ''}
              </div>
            </Col>
            <Col sm='12' md='6' lg='5' className={styles.instruction}>
              <div>
                <img src={process.env.PUBLIC_URL + '/assets/img/tidak-sendiri-lagi/tidak-sendiri-lagi-public.svg'}
                  alt="tidak-sendiri-lagi-public-image" />
              </div>
              <div>
                <div className={styles.textSubHeader}>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.public')}</div>
                <div className={styles.textContent}>
                  {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.publicDescription1')}
                  <span className={styles.highlight}>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.tidakSendiriLagi')}</span>
                  {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.publicDescription2')}
                  <Link to={{
                    pathname: 'https://www.instagram.com/yayasanmerajuthati/'
                  }} target='_blank'>
                    {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.merajutHati')}
                  </Link>
                  {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.publicDescription3')}
                </div>
              </div>
            </Col>
            <Col sm='12' md='6' lg='5' className={styles.instruction}>
              <div>
                <img src={process.env.PUBLIC_URL + '/assets/img/tidak-sendiri-lagi/tidak-sendiri-lagi-anonymous.svg'}
                  alt="tidak-sendiri-lagi-anonymous-image" />
              </div>
              <div>
                <div className={styles.textSubHeader}>{this.props.i18n.t('tidak-sendiri-lagi:instructionSection.anonymous')}</div>
                <div className={styles.textContent}>
                  {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.anonymousDescription1')}&nbsp;
                  <Link to={{
                    pathname: 'https://docs.google.com/forms/d/e/1FAIpQLSeXzJO8IL43H21MFaGZgJ0bskpmU7YBWzhUEVBWzw4dQBPf7A/' +
                      'viewform?vc=0&c=0&w=1&flr=0&gxids=7628'
                  }} target='_blank'>
                    {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.form')}
                  </Link>&nbsp;
                  {this.props.i18n.t('tidak-sendiri-lagi:instructionSection.anonymousDescription2')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='page-container'>
          <div className={styles.textHeader}>
            {this.props.i18n.t('tidak-sendiri-lagi:storiesSection.title')}
          </div>
          <Row className={styles.grid}>
            {this.state.media.map((thumbnail, key) => {
              return (
                <Col sm='12' md='6' lg='4' className={styles.gridImageContainer} key={key}
                  onClick={() => window.open(thumbnail.instagramURL, '_blank')}>
                  <img src={thumbnail.cover.url}
                    alt={`${process.env.REACT_APP_MH_STRAPI_ENDPOINT}${thumbnail.cover.name}`} />
                </Col>
              );
            })}
          </Row>
          {this.showButton()}
        </div>
      </>
    );
  }
}

export default TidakSendiriLagi;
