import React from 'react';
import styles from './Donate.module.css';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import CustomButton from '../../components/custom-button/CustomButton';
import LabeledFormField, { LabeledFormFieldState } from '../../components/labeled-form-field/LabeledFormField';
import CustomDropdown from '../../components/custom-dropdown/CustomDropdown';
import TextButton from '../../components/text-button/TextButton';

import client from '../../utils/apollo';
import GET_COST_DISBURSEMENT_BY_DATERANGE from '../../utils/queries/costDisbursements';
import DonutChart from '../../components/donut-chart/DonutChart';

interface DonateState {
  donationForm: FormInput;
  isDisabled: boolean;
  dropdown: Dropdown;
  costDisbursement: CostDisbursement[];
}

interface DonateProps {
  i18n: any;
  history: any;
}

interface FormInput {
  donationAmount: string;
}

interface Dropdown {
  category: string;
  month: string;
  year: string;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface CostDisbursement {
  amount: number;
  category: string;
  currency: string;
  date: string;
  description: string;
}

interface colorCategory {
  [key: string]: string;
}

const colorCategory: colorCategory = {
  administrative_fees: '#ED9C76',
  berbagi_hati_program: '#4A8870',
  event_and_campaigns: '#A7D59B',
  marketing: '#FACE33',
  operational_costs: '#D35F5F',
  research: '#2D5C4A'
};

class Donate extends React.Component<DonateProps, DonateState> {
  constructor(props: DonateProps) {
    super(props);
    this.state = {
      donationForm: {
        donationAmount: ''
      },
      isDisabled: true,
      dropdown: {
        category: 'all_expenses',
        month: moment().format('M'),
        year: moment().format('YYYY')
      },
      costDisbursement: []
    };
  }

  componentDidMount() {
    const start = moment().startOf('month').toISOString();
    const end = moment().endOf('month').toISOString();
    this.getCostDisbursementData(start, end);
  }

  componentDidUpdate(prevProps: DonateProps, prevState: DonateState) {
    if (prevState.dropdown !== this.state.dropdown) {
      const { month, year } = this.state.dropdown;
      const start = moment().year(parseInt(year)).month(parseInt(month) - 1).startOf('month').toISOString();
      const end = moment().year(parseInt(year)).month(parseInt(month) - 1).endOf('month').toISOString();
      this.getCostDisbursementData(start, end);
    }
  }

  render() {
    const {
      costDisbursement,
      donationForm: { donationAmount },
      dropdown: { category, month, year },
      isDisabled
    } = this.state;
    const { history, i18n } = this.props;
    return (
      <>
        <div className={`page-container ${styles.donatePageContainer}`}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/donate/donate-background.svg'})` }} >
          <div className={styles.donatePage}>
            <Row>
              <Col sm='12' md='7' lg='7' xl='8'>
                <h1>{i18n.t('donate:title')}</h1>
                <p className={styles.donateDescription}>
                  {i18n.t('donate:titleDescription')}
                </p>
                <Row className={styles.donationFormContainer}>
                  <TextButton ariaLabel='Donate 100,000' id='donate-100,000' label='IDR100,000' type='button'
                    onClick={() => this.setDonationAmount('100000')} />

                  <TextButton ariaLabel='Donate 200,000' id='donate-200,000' label='IDR200,000' type='button'
                    onClick={() => this.setDonationAmount('200000')} />

                  <TextButton ariaLabel='Donate 400,000' id='donate-400,000' label='IDR400,000' type='button'
                    onClick={() => this.setDonationAmount('400000')} />

                  <TextButton ariaLabel='Donate 1,000,000' id='donate-1,000,000' label='IDR1,000,000' type='button'
                    onClick={() => this.setDonationAmount('1000000')} />

                  <span className={styles.textOption}>{i18n.t('donate:or')}</span>

                  <LabeledFormField
                    errorMessage='Invalid Amount'
                    onChange={this.setField}
                    name='donationAmount'
                    label='IDR'
                    value={donationAmount}
                    validator={this.donationValidator}
                  />
                </Row>
                <CustomButton
                  id='donate-continue' type='button' label='Continue'
                  isDisabled={isDisabled}
                  onClick={this.navigateToPaymentGateway} ariaLabel='Continue' />
              </Col>
              <Col sm='12' md='5' lg='5' xl='4' className={styles.donatePageImageContainer}>
                <img className={styles.donatePageImage} src={process.env.PUBLIC_URL + '/assets/img/donate/donate-page.svg'}
                  alt='donate-page-image' />
              </Col>
            </Row>
          </div>
        </div >
        <div className={`page-container ${styles.otherDonation}`}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/donate/donate-other-donation-background.svg'})` }}>
          <Row>
            <Col sm='12' lg='4' className={styles.otherDonationDescription}>
              <p className={styles.textHeader}>{i18n.t('donate:otherDonation.title')}</p>
              <p>{i18n.t('donate:otherDonation.description')}</p>
            </Col>
            {/* individual workshop section */}
            {/* <Col xs='12' sm='6' lg='4'>
              <div className={styles.otherDonationCard}>
                <div>
                  <h2 className={styles.cardText}>{i18n.t('donate:otherDonation.individualWorkshop')}</h2>
                  <p className={styles.cardText}>{i18n.t('donate:otherDonation.individualWorkshopDescription')}</p>
                </div>
                <div>
                  <CustomButton ariaLabel='OtherDonationIndividualWorkshop' colorType='tertiary'
                    id='other-donation-individual-workshop' label={i18n.t('donate:learnMore')} type='button' />
                </div>
              </div>
            </Col> */}
            <Col xs='12' sm='6' lg='4'>
              <div className={styles.otherDonationCard}>
                <div>
                  <h2 className={styles.cardText}>{i18n.t('donate:otherDonation.groupWorkshop')}</h2>
                  <p className={styles.cardText}>{i18n.t('donate:otherDonation.groupWorkshopDescription')}</p>
                </div>
                <div>
                  <CustomButton ariaLabel='Contact Us' colorType='tertiary' id='other-donation-contactus'
                    label={i18n.t('donate:contactUs')} type='button' onClick={() => history.push('/contactus')} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`page-container ${styles.costDisbursement}`}>
          <div>
            <p className={styles.textHeader}>{i18n.t('donate:costDisbursement.title')}</p>
            <p>{i18n.t('donate:costDisbursement.description')}</p>
          </div>
          <div className={styles.dropdown}>
            <CustomDropdown default={year} dropdownOptions={this.generateYears()}
              name='year' option={{ width: styles.monthDropdownWidth }} onChange={this.setDropdown} />
            <CustomDropdown default={month} dropdownOptions={this.generateMonths(year)}
              name='month' option={{ width: styles.yearDropdownWidth }} onChange={this.setDropdown} />
            <CustomDropdown default={category} dropdownOptions={this.generateCategories()}
              name='category' option={{ width: styles.categoryDropdownWidth }} onChange={this.setDropdown} />
          </div>
          <DonutChart colorCategory={colorCategory} donutChartData={costDisbursement}
            selectedCategory={category} />
          <table className={styles.costDisbursemetTable}>
            <tr className={styles.tableRowHeader}>
              <th className={`${styles.tableCell} ${styles.tableHeaderCell}`}>
                {i18n.t('donate:table.date')}
              </th>
              <th className={`${styles.tableCell} ${styles.tableHeaderCell}`}>
                {i18n.t('donate:table.description')}
              </th>
              <th className={`${styles.tableCell} ${styles.tableHeaderCell}`}>
                {i18n.t('donate:table.category')}
              </th>
              <th className={`${styles.tableCell} ${styles.tableHeaderCell}`}>
                {i18n.t('donate:table.amount')}
              </th>
            </tr>
            {costDisbursement.filter((data) => {
              return data.category === category || category === 'all_expenses';
            })
              .map((data, index) => {
                const { date, description, category, amount } = data;
                const tableRowClassName = index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;
                const color = colorCategory[category];
                return (
                  <tr className={tableRowClassName} key={index}>
                    <td className={styles.tableDate}>{moment(date).format('DD MMM YYYY')}</td>
                    <td className={styles.tableCell}>{description}</td>
                    <td className={styles.tableCell} style={{ color }}>
                      {this.convertToTitleCase(category)}
                    </td>
                    <td className={styles.tableCell}>{`IDR${this.formatNumber(amount)}`}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </>
    );
  }

  private convertToTitleCase = (category: string) => {
    return category
      .split('_')
      .map(a => a[0].toUpperCase() + a.substring(1))
      .join(' ');
  }

  private checkFormValid = () => {
    if (this.donationValidator(this.state.donationForm.donationAmount)) {
      this.setState({
        isDisabled: false
      });
    } else {
      this.setState({
        isDisabled: true
      });
    }
  }

  private donationValidator = (input: string) => {
    const positiveNumberRegex = new RegExp(/^[1-9][0-9]*$/);
    // TODO: minimum donation amount?
    if (positiveNumberRegex.test(input)) {
      return true;
    }
    return false;
  }

  private getCostDisbursementData = (start: string, end: string) => {
    client.query({
      query: GET_COST_DISBURSEMENT_BY_DATERANGE,
      variables: {
        start: start,
        end: end
      }
    })
      .then((data) => {
        const costDisbursement = data.data.costDisbursements;
        this.setState({
          costDisbursement
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          costDisbursement: []
        });
      });
  }

  private setDonationAmount = (amount: string) => {
    this.setState({
      donationForm: {
        ...this.state.donationForm,
        donationAmount: amount
      },
      isDisabled: !this.donationValidator(amount)
    });
  }

  private setDropdown = (type: string, input: DropdownOption) => {
    this.setState({
      dropdown: {
        ...this.state.dropdown,
        [type]: input.value
      }
    });
  }

  private setField = (name: string, state: LabeledFormFieldState) => {
    this.setState({
      donationForm: {
        ...this.state.donationForm,
        [name]: state.value
      }
    }, this.checkFormValid);
  }

  private navigateToPaymentGateway = () => {
    this.props.history.push({
      pathname: '/paymentgateway',
      search: `?type=general&amount=${this.state.donationForm.donationAmount}`
    });
  }

  private generateMonths = (year: string) => {
    const currentYear = moment().format('YYYY');
    const currentMonth = year === currentYear ? parseInt(moment().format('M')) - 1 : 11;
    const monthList = [];
    for (let i = 0; i <= currentMonth; i++) {
      monthList[i] = {
        label: moment().month(i).format('MMMM'),
        value: (i + 1).toString()
      };
    }
    return monthList;
  };

  private generateYears = () => {
    const startYear = 2020;
    const currentYear = parseInt(moment().format('YYYY'));
    const yearList = [];
    for (let i = 0; i <= currentYear - startYear; i++) {
      yearList[i] = {
        label: (i + startYear).toString(),
        value: (i + startYear).toString()
      };
    }
    return yearList;
  };

  private generateCategories = () => {
    const costDisbursement = this.state.costDisbursement;
    const category = costDisbursement.map(a => a.category);
    const filtered = category.filter((a, index, self) => index === self.indexOf(a));
    const dropdownOption = filtered
      .map(a => ({ label: a.split('_').map(a => a[0].toUpperCase() + a.substring(1)).join(' '), value: a }));
    return [{ label: this.props.i18n.t('donate:allExpenses'), value: 'all_expenses' }, ...dropdownOption];
  };

  private formatNumber(amount: number) {
    return amount.toLocaleString();
  }
}

export default Donate;
