import React from 'react';

class LinkedIn extends React.Component {
  render() {
    return (
      <svg aria-label="linkedin" width="24" height="24" viewBox="0 0 18 18" fill="#808284" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3337 15.3399H12.6714V11.1622C12.6714 10.1661 12.6506 8.88581 11.2793 8.88581C9.891 8.88581 9.6795 9.96637 9.6795 11.0886V15.3394H7.01381V6.74944H9.57431V7.92112H9.60863C9.96638 7.24444 10.8366 6.53288 12.1348 6.53288C14.8354 6.53288 15.3366 8.31094 15.3366 10.6228V15.3394L15.3337 15.3399ZM4.00219 5.5755C3.79872 5.57557 3.59723 5.53551 3.40927 5.45759C3.22131 5.37968 3.05057 5.26544 2.90682 5.12144C2.76308 4.97743 2.64916 4.80648 2.57158 4.61838C2.49401 4.43028 2.45431 4.22872 2.45475 4.02525C2.45508 3.719 2.54622 3.41972 2.71663 3.16526C2.88704 2.9108 3.12907 2.71258 3.41213 2.59566C3.69519 2.47875 4.00656 2.44839 4.30687 2.50842C4.60718 2.56845 4.88295 2.71618 5.0993 2.93293C5.31566 3.14968 5.46289 3.42572 5.52238 3.72614C5.58187 4.02656 5.55094 4.33787 5.43351 4.62072C5.31608 4.90356 5.11742 5.14524 4.86265 5.31518C4.60788 5.48513 4.30844 5.57572 4.00219 5.5755ZM5.33756 15.3399H2.66625V6.75H5.33756V15.3399ZM16.6702 0H1.3275C0.594562 0 0 0.579937 0 1.29769V16.7029C0 17.4206 0.594562 18.0006 1.3275 18.0006H16.6674C17.3993 18.0006 18 17.4206 18 16.7029V1.29769C18 0.579937 17.3993 0 16.6674 0H16.6702Z" />
      </svg>
    );
  }
}

export default LinkedIn;
