import React from 'react';

class Twitter extends React.Component {
  render() {
    return (
      <svg aria-label="twitter" width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg" fill="#4a8870">
        <path d="M7.539 19.5003C16.596 19.5003 21.5505 11.9958 21.5505 5.49931C21.5505 5.28931 21.5505 5.07631 21.5415 4.86631C22.5061 4.16804 23.3386 3.30353 24 2.31331C23.099 2.71058 22.1441 2.97242 21.1665 3.09031C22.1963 2.47476 22.9676 1.50626 23.337 0.364807C22.3695 0.937993 21.3105 1.34037 20.2065 1.55431C19.4643 0.763843 18.4821 0.240184 17.4121 0.0644435C16.3421 -0.111297 15.2441 0.0706895 14.288 0.582213C13.3319 1.09374 12.5712 1.90625 12.1237 2.8939C11.6761 3.88154 11.5668 4.98921 11.8125 6.04531C9.85461 5.94713 7.93922 5.43851 6.19056 4.55242C4.4419 3.66633 2.89903 2.42257 1.662 0.901807C1.03401 1.98643 0.842361 3.26941 1.12597 4.49021C1.40958 5.711 2.14718 6.7781 3.189 7.47481C2.40831 7.44828 1.64478 7.23865 0.96 6.86281V6.93031C0.961346 8.06653 1.35496 9.16744 2.07431 10.0469C2.79366 10.9265 3.79462 11.5306 4.908 11.7573C4.48539 11.8737 4.04884 11.9318 3.6105 11.9298C3.30148 11.9307 2.99307 11.9021 2.6895 11.8443C3.00418 12.8224 3.61691 13.6776 4.44187 14.29C5.26683 14.9025 6.2627 15.2416 7.29 15.2598C5.54483 16.6305 3.3891 17.3739 1.17 17.3703C0.778981 17.372 0.388235 17.3494 0 17.3028C2.25227 18.7387 4.86795 19.5011 7.539 19.5003Z" fill="#4A8870" />
      </svg>
    );
  }
}

export default Twitter;
