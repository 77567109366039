import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './BerbagiHati.module.css';
import moment from 'moment';
import CheckboxPanel from '../../components/checkbox/CheckboxPanel';
import CustomButton from '../../components/custom-button/CustomButton';
import CustomCard from '../../components/custom-card/CustomCard';
import { Link } from 'react-router-dom';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import CustomDropdown from '../../components/custom-dropdown/CustomDropdown';

interface BerbagiHatiProp {
  i18n: any;
  history: any;
}

interface Client {
  [index: string]: any;
  id: string,
  name: string,
  profilePicture: Media,
  description: string,
  age: number,
  location: string,
  gender: string,
  numberOfSponsors: number,
  goal: number,
  raised: number,
  published_at: string,
  status: string,
  story: string,
  costBreakdown: string,
  updates: string
}

interface Media {
  url: string;
}

class BerbagiHati extends React.Component<BerbagiHatiProp> {
  allClient: Client[];
  arrayRef: any;
  customCards: any;
  customCardRef: any;
  filterPanel: any = {
    'gender': {
      category: this.props.i18n.t('berbagi-hati:filter.gender.title'),
      type: 'general',
      options: this.props.i18n.t('berbagi-hati:filter.gender.filters', { returnObjects: true }),
      optionsCode: ['male', 'female'],
      optionsActive: [false, false]
    },
    'age': {
      category: this.props.i18n.t('berbagi-hati:filter.age.title'),
      type: 'range',
      options: this.props.i18n.t('berbagi-hati:filter.age.filters', { returnObjects: true }),
      optionsCode: [[0, 10], [11, 20], [21, 30], [31, 40], [41, 50], [51, 60], [61, 70], [71, 80], [81, 90], [91, 100], [101, Infinity]],
      optionsActive: [false, false, false, false]
    },
    'numberOfSponsors': {
      category: this.props.i18n.t('berbagi-hati:filter.numberOfSponsors.title'),
      type: 'range',
      options: this.props.i18n.t('berbagi-hati:filter.numberOfSponsors.filters', { returnObjects: true }),
      optionsCode: [[0, 100], [101, 1000], [1001, 10000], [10001, 100000]],
      optionsActive: [false, false, false, false]
    }
  }

  private initialAllClient: Client[] = [];

  constructor(props: BerbagiHatiProp) {
    super(props);
    this.allClient = [];
    this.initialAllClient = [];
    this.arrayRef = [];
    this.customCardRef = React.createRef();
  }

  componentDidMount() {
    const clientsUrl = `${process.env.REACT_APP_MH_STRAPI_ENDPOINT}/clients`;
    fetch(clientsUrl)
      .then(response => response.json())
      .then(clients => {
        this.allClient = clients;
        this.initialAllClient = clients;
        this.customCards = this.createClients(this.allClient);
        this.forceUpdate();
      })
      .catch(err => {
        console.error(err);
        this.allClient = [];
        this.initialAllClient = [];
        this.customCards = this.createClients(this.allClient);
      });
  }

  addToRef = (el: any) => {
    if (!this.arrayRef.includes(el)) {
      this.arrayRef.push(el);
    }
  }

  allFilterOff = () => {
    let filterOff = true;
    Object.keys(this.filterPanel).forEach(key => {
      this.filterPanel[key].optionsActive.forEach((filter: any) => {
        if (filter) {
          filterOff = false;
        }
      });
    });
    return filterOff;
  }

  bigNumberConvert = (num: number) => {
    const digits = String(num).length;
    let strNum = '';
    for (let digit = digits; digit > 0; digit--) {
      if (digit % 3 == 0 && digit != digits) {
        strNum += ',';
      }
      strNum += String(num)[digits - digit];
    }
    return strNum;
  }

  clearFilters = () => {
    Object.keys(this.filterPanel).forEach((element, index) => {
      const tempActive = [] as any[];
      this.filterPanel[element].options.forEach((el: any) => {
        tempActive.push(false);
      });
      this.filterPanel[element].optionsActive = tempActive;
      this.arrayRef[index].setState({
        filters: this.filterPanel[element].optionsActive
      });
    });
  }

  createCheckboxPanel = () => {
    const component = [] as any[];
    Object.keys(this.filterPanel).forEach((element) => {
      component.push(
        <CheckboxPanel key={this.filterPanel[element].category}
          header={this.filterPanel[element].category}
          filters={this.filterPanel[element].options}
          onChange={this.onChangeFilter} ref={this.addToRef}></CheckboxPanel>);
    });
    return component;
  }

  createClients = (clients: Client[]) => {
    const clientCustomCards = [] as any[];

    clients.sort((a, b) => a.raised / a.goal - b.raised / b.goal).forEach((client: Client) => {
      if (this.allFilterOff() || this.filterClient(client)) {
        clientCustomCards.push(
          <CustomCard className={styles.client}
            key={client.id} imgSrc={client.profilePicture.url} title={client.name}
            desc={client.description}
            onClick={() => this.customCardOnClick(client.id)}>
            <div className={styles.cardChildren}>
              <ProgressBar progress={Math.floor(client.raised / client.goal * 100)} />
              <div className={styles.progress}>
                <span className={styles.progressBold}>IDR {this.bigNumberConvert(client.raised)}</span>
                <span> {this.props.i18n.t('berbagi-hati:raisedOutOf')} </span>
                <span className={styles.progressBold}>IDR {this.bigNumberConvert(client.goal)}</span>
                <span> {this.props.i18n.t('berbagi-hati:goal')}</span>
              </div>
              <div className={styles.numDonation}>
                {this.bigNumberConvert(client.numberOfSponsors)} {this.props.i18n.t('berbagi-hati:peopleDonated')}
              </div>
              <Row className={styles.donateRow}>
                <div className={styles.fundOthersDonateBtn}>
                  <CustomButton id={'fund-others-donate-btn'} type='button' label={this.props.i18n.t('berbagi-hati:donate')}
                    onClick={() => this.donate(client.id)} ariaLabel='Donate'
                    imgSrc={process.env.PUBLIC_URL + '/assets/icons/arrow-right.svg'} />
                </div>
                {/* TODO: Ability to share client to people */}
                {/* <img src={process.env.PUBLIC_URL + '/assets/icons/share-icon.svg'}
                  className={styles.share} onClick={this.shareClient} /> */}
              </Row>
            </div>
          </CustomCard>
        );
      }
    });
    if (clientCustomCards.length == 0) {
      clientCustomCards.push(
        <div key='no-result' className={styles.noResult}>
          <img src={process.env.PUBLIC_URL + '/assets/img/find-a-provider/no_result_illust.svg'}
            className={styles.noResultImg} alt='no result illustration' />
          <div className={styles.noResultTitle}>
            {this.props.i18n.t('berbagi-hati:searchNotFoundTitle')}
          </div>
          <div className={styles.noResultDesc}>
            {this.props.i18n.t('berbagi-hati:searchNotFoundDescription1')}
            <span> <Link to='/contactus'>{this.props.i18n.t('berbagi-hati:contactUs')}</Link> </span>
            {this.props.i18n.t('berbagi-hati:searchNotFoundDescription2')}
          </div>
        </div>
      );
    }
    return clientCustomCards;
  }

  customCardOnClick = (id: string) => {
    this.props.history.push(`/berbagihati/${id}`);
  }

  donate = (clientId: string) => {
    // navigate to the client's donate page
    this.props.history.push(`/paymentgateway?type=berbagihati&clientId=${clientId}`);
  }

  filterClient = (client: Client) => {
    let providerGoesThrough = false;
    Object.keys(this.filterPanel).forEach((element) => {
      if (this.filterPanel[element].type == 'general') {
        if (this.filterPanel[element].optionsActive[this.filterPanel[element]
          .optionsCode.indexOf(client[element])]) {
          providerGoesThrough = true;
        }
      } else if (this.filterPanel[element].type == 'range') {
        for (let count = 0; count < this.filterPanel[element].options.length; count++) {
          if (this.filterPanel[element].optionsActive[count]) {
            if (client[element] >= this.filterPanel[element].optionsCode[count][0] &&
              client[element] <= this.filterPanel[element].optionsCode[count][1]) {
              providerGoesThrough = true;
            }
          }
        }

      }

    });
    return providerGoesThrough;
  }

  onChangeFilter = () => {
    Object.keys(this.filterPanel).forEach((element, index) => {
      this.filterPanel[element].optionsActive = this.arrayRef[index].state.filters;
    });
    this.customCards = this.createClients(this.allClient);
    this.forceUpdate();
  }

  shareClient = () => {
    // TODO: share client?
  }

  sortBy = (name: string, selected: any) => {
    if (selected.value == '1') {
      this.allClient = [...this.initialAllClient].sort(
        (first, second) => moment(first.published_at).diff(moment(second.published_at))
      );
    } else if (selected.value == '2') {
      this.allClient = [...this.initialAllClient].sort(
        (first, second) => moment(second.published_at).diff(moment(first.published_at))
      );
    } else {
      this.allClient = [...this.initialAllClient];
    }

    this.customCards = this.createClients(this.allClient);
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <Row className={styles.titleRow}>
          <Col className={styles.titleCol1}>
            <h1>{this.props.i18n.t('berbagi-hati:title')}</h1>
            <div className={styles.description}>
              <div>
                {this.props.i18n.t('berbagi-hati:description')}&ensp;
                <Link to={'/applyberbagihati'}>{this.props.i18n.t('berbagi-hati:titleLinkName')}</Link>
                .
              </div>
              <div className={styles.disclaimerTitle}>
                Disclaimer:
              </div>
              <div>
                {this.props.i18n.t('berbagi-hati:disclaimer')}
              </div>
            </div>
          </Col>
          <Col className={styles.titleCol2}>
            <img className={styles.berbagiHatiIllust}
              src={process.env.PUBLIC_URL + '/assets/img/berbagi-hati/berbagi-hati-illust.svg'} />
          </Col>
        </Row>
        <div className='page-container'>
          <Row className={styles.row}>
            <Col xs={12} lg={3}>
              <div className={styles.filterTitle}>Filter</div>
              <div className={styles.filters}>
                {this.createCheckboxPanel()}
              </div>
              <CustomButton id='berbagi-hati-clear-filter' type='button' label={this.props.i18n.t('berbagi-hati:clearFilters')}
                onClick={this.clearFilters} ariaLabel="Clear Filters"
                imgSrc={process.env.PUBLIC_URL + '/assets/icons/undo-icon.svg'}>
              </CustomButton>
            </Col>
            <Col xs={12} lg={9} className={styles.clientsCol}>
              <div className={styles.sortByPanel}>
                <div className={styles.sortByName}>
                  {this.props.i18n.t('berbagi-hati:sortBy.title')}
                </div>
                <CustomDropdown dropdownOptions={[
                  { label: this.props.i18n.t('berbagi-hati:sortBy.sortOptions', { returnObjects: true })[0], value: '0' },
                  { label: this.props.i18n.t('berbagi-hati:sortBy.sortOptions', { returnObjects: true })[1], value: '1' },
                  { label: this.props.i18n.t('berbagi-hati:sortBy.sortOptions', { returnObjects: true })[2], value: '2' }]}
                  onChange={this.sortBy} name={'sortBy'} option={{ width: '200px' }} />
              </div>
              <div className={styles.clients}>
                {this.customCards}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default BerbagiHati;
