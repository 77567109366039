import React from 'react';

interface MeetTheTeamBackgroundProp {
  className?: string;
}

class MeetTheTeamBackground extends React.Component<MeetTheTeamBackgroundProp> {
  render() {
    return (
      <svg className={this.props.className} viewBox="0 0 1280 1152" height="1152" fill="#F9F9F1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1280 0L1280 1039.05C1190.5 1108.57 1110.5 1152 901.5 1152C578 1152 363.5 929.5 0 1095.5L0 0L1280 0Z" fill="#F9F9F1" />
      </svg>

    );
  }
}

export default MeetTheTeamBackground;
