import React from 'react';
import styles from './CustomButton.module.css';

interface CustomButtonProp {
  colorTheme?: 'light' | 'dark';
  colorType?: 'primary' | 'secondary' | 'tertiary';
  id: string;
  isDisabled?: boolean;
  imgAlt?: string;
  imgSrc?: string;
  type: 'button' | 'submit';
  label: string;
  onClick?(): void;
  ariaLabel: string;
  className?: string;
}

class CustomButton extends React.Component<CustomButtonProp> {
  private customButtonClassNames = () => {
    let colorType;
    if (!this.props.colorType || this.props.colorType === 'primary') {
      colorType = styles.primary;
    } else if (this.props.colorType === 'secondary') {
      colorType = styles.secondary;
    } else {
      colorType = styles.tertiary;
    }
    const colorTheme = !this.props.colorTheme || this.props.colorTheme === 'light' ? '' : styles.dark;
    const disabled = this.props.isDisabled ? styles.disabledButton : '';

    return `${this.props.className} ${styles.customButton} ${disabled} ${colorType} ${colorTheme}`;
  }
  render() {
    return (
      <button
        disabled={this.props.isDisabled}
        id={this.props.id}
        type={this.props.type}
        onClick={() => this.props.onClick ? this.props.onClick.apply(undefined) : null}
        aria-label={this.props.ariaLabel}
        className={this.customButtonClassNames()}
      >
        <p className={styles.label}>{this.props.label}</p>
        <img src={this.props.imgSrc || '/assets/icons/arrow-right.svg'} alt={this.props.imgAlt || 'arrow'} className={styles.arrow} />
      </button>
    );
  }
}

export default CustomButton;
