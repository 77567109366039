import React from 'react';

interface TooltipProp {
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

class Tooltip extends React.Component<TooltipProp> {
  render() {
    return (
      <svg className={this.props.className} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} aria-label='tooltip' width="24" height="24" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="28" cy="28" r="27.5" stroke="#38383C" />
        <path d="M23 36H27C27 37.1 26.1 38 25 38C23.9 38 23 37.1 23 36ZM21 35H29V33H21V35ZM32.5 25.5C32.5 29.32 29.84 31.36 28.73 32H21.27C20.16 31.36 17.5 29.32 17.5 25.5C17.5 21.36 20.86 18 25 18C29.14 18 32.5 21.36 32.5 25.5ZM37.37 23.37L36 24L37.37 24.63L38 26L38.63 24.63L40 24L38.63 23.37L38 22L37.37 23.37ZM35 22L35.94 19.94L38 19L35.94 18.06L35 16L34.06 18.06L32 19L34.06 19.94L35 22Z" fill="#000000" />
      </svg>
    );
  }
}

export default Tooltip;
