import React from 'react';
import styles from './PrivacyPolicy.module.css';
import { Link } from 'react-router-dom';

interface PrivacyPolicyProps {
  i18n: any
}

class PrivacyPolicy extends React.Component<PrivacyPolicyProps> {
  render() {
    return (
      <div className='page-container'>
        <h1>{this.props.i18n.t('privacy-policy:header')}</h1>
        <p className={styles.content}>
          {this.props.i18n.t('privacy-policy:description1')}
          <Link to='/contactus'>{this.props.i18n.t('privacy-policy:contactUs')}</Link>
          .
        </p>

        <p className={styles.lastUpdate}>{this.props.i18n.t('privacy-policy:lastUpdate')}</p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section1.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section1.description1')}</p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section2.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section2.description1')}</p>
        <p>
          {this.props.i18n.t('privacy-policy:section2.description2')}
          <Link to={{ pathname: 'https://www.privacypolicies.com/blog/cookies/' }} target="_blank">
            {this.props.i18n.t('privacy-policy:whatAreCookies')}
          </Link>
          {this.props.i18n.t('privacy-policy:section2.description3')}
        </p>
        <p>{this.props.i18n.t('privacy-policy:section2.description4')}</p>
        <p>{this.props.i18n.t('privacy-policy:section2.description5')}</p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section3.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section3.description1')}</p>
        <p>{this.props.i18n.t('privacy-policy:section3.description2')}</p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section4.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section4.description1')}</p>
        <p>
          {this.props.i18n.t('privacy-policy:section4.description2')}
          <Link to='/contactus'>{this.props.i18n.t('privacy-policy:contactUs')}</Link>
          {this.props.i18n.t('privacy-policy:section4.description3')}
        </p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section5.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section5.description1')}</p>

        <p className={styles.sectionTitle}>{this.props.i18n.t('privacy-policy:section6.title')}</p>
        <p>{this.props.i18n.t('privacy-policy:section6.description1')}</p>
      </div>
    );
  }
}

export default PrivacyPolicy;
