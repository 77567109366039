import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import Check from '../../assets/svg/Check/Check';
import Cross from '../../assets/svg/Cross/Cross';
import Email from '../../assets/svg/Email/Email';
import Info from '../../assets/svg/Info/Info';
import LinkOut from '../../assets/svg/LinkOut/LinkOut';
import Phone from '../../assets/svg/Phone/Phone';
import Website from '../../assets/svg/Website/Website';
import CustomButton from '../../components/custom-button/CustomButton';
import styles from './FindAProviderDetail.module.css';

import client from '../../utils/apollo';
import { GET_PROVIDER_BY_ID } from '../../utils/queries/provider';
import { ApolloQueryResult } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';

interface FindAProviderDetailProps {
  history: any;
  i18n: any;
  match: any;
}

interface FindAProviderDetailState {
  acceptInsurance: string;
  address: string;
  age: number;
  description: string;
  email: string;
  ethnicity: string;
  gender: string;
  language: string;
  licensure: string;
  location: string;
  locationLink: string;
  name: string;
  phone: string;
  profilePicture: Media;
  religion: string;
  specialties: Specialty[];
  treatmentModality: TreatmentModality;
  treatments: Treatment[];
  website: string;
}

interface Media {
  name: string;
  url: string;
}

interface Specialty {
  description: string;
}

interface Treatment {
  type: string;
}

enum TreatmentModality {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ANY = 'any'
}

class FindAProviderDetail extends React.Component<FindAProviderDetailProps, FindAProviderDetailState> {
  constructor(props: FindAProviderDetailProps) {
    super(props);
    this.state = {
      acceptInsurance: '',
      address: '',
      age: 0,
      description: '',
      email: '',
      ethnicity: '',
      gender: '',
      language: '',
      licensure: '',
      location: '',
      locationLink: '',
      name: '',
      phone: '',
      profilePicture: {
        name: '',
        url: ''
      },
      religion: '',
      specialties: [],
      treatmentModality: TreatmentModality.ANY,
      treatments: [],
      website: ''
    };
  }

  componentDidMount() {
    client.query({
      query: GET_PROVIDER_BY_ID,
      variables: {
        id: this.props.match.params.id
      }
    })
      .then((response: ApolloQueryResult<any>) => {
        const { acceptInsurance, address, age, description, email, ethnicity, gender, language,
          licensure, location, locationLink, name, phone, profilePicture, religion, specialties,
          treatmentModality, treatments, website } = response.data.providers[0];

        this.setState({
          acceptInsurance,
          address,
          age,
          description,
          email,
          ethnicity,
          gender,
          language,
          licensure,
          location,
          locationLink,
          name,
          phone,
          profilePicture: {
            name: profilePicture[0].name,
            url: profilePicture[0].url
          },
          religion,
          specialties,
          treatmentModality,
          treatments,
          website
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  toTitleCase = (input: string) => {
    const words = input.split('_');
    words[0] = words[0].split('').map((letter, index) => index == 0 ? letter.toUpperCase() : letter).join('');
    return words.join(' ');
  }

  render() {
    const { acceptInsurance, address, age, description, email, ethnicity, gender, language,
      licensure, location, locationLink, name, phone, profilePicture, religion, specialties,
      treatmentModality, treatments, website } = this.state;
    return (
      <div className='page-container'>
        <div className={styles.iconWithText} onClick={() => this.props.history.push('/findaprovider')}>
          <img src={`${process.env.PUBLIC_URL + '/assets/icons/arrow-left.svg'}`}
            alt="prev-page-button-arrow" />
          <div>{this.props.i18n.t('find-a-provider-detail:backToProviders')}</div>
        </div>
        <div className={styles.providerDetail}>
          <img className={styles.providerProfilePicture}
            src={profilePicture.url}
            alt={profilePicture.name} />
          <div className={styles.nameWrapper}>
            <div className={styles.licensureText}>
              {this.props.i18n.t(`find-a-provider-detail:licensure.${licensure}`)}
            </div>
            <div className={styles.nameText}>{name}</div>
          </div>
          <div className={styles.providerContacts}>
            <div className={styles.contactWrapper}>
              <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:contact')}</div>
              <div className={styles.contactDetails}>
                <Link to={{ pathname: `tel: ${phone}` }} target='_blank' className={styles.contactInfoWrapper}>
                  <Phone className={styles.contactInfoIcon} />
                  {phone}
                </Link>
                <Link to={{ pathname: `mailto: ${email}` }} target='_blank' className={styles.contactInfoWrapper}>
                  <Email className={styles.contactInfoIcon} />
                  {email}
                </Link>
                <Link to={{ pathname: website }} target='_blank' className={styles.contactInfoWrapper}>
                  <Website className={styles.contactInfoIcon} />
                  {website}
                </Link>
              </div>
            </div>
            <div className={styles.locationWrapper}>
              <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:location')}</div>
              <Link to={{ pathname: locationLink }} target='_blank' className={styles.locationInfoAddress}>
                {location}
                <LinkOut className={styles.locationInfoIcon} />
              </Link>
              <div>{address}</div>
            </div>
            <CustomButton ariaLabel="view-location" id='find-a-provider-detail-view-location'
              label={this.props.i18n.t('find-a-provider-detail:viewLocation')} type='button'
              onClick={() => window.open(locationLink, '_blank')} />
          </div>
          <div className={styles.providerAbouts}>
            <div className={styles.providerDetailTwoWrapper}>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:about')}</div>
                <div>
                  <ReactMarkdown>
                    {description}
                  </ReactMarkdown>
                </div>
              </div>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:specialty.title')}</div>
                <ol className={styles.specialtyDetails}>
                  {specialties.map((specialty: Specialty, index) => {
                    return (
                      <li key={index}>{this.props.i18n.t(`find-a-provider-detail:specialty.${specialty.description}`)}</li>
                    );
                  })}
                </ol>
              </div>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:treatment.title')}</div>

                <ol className={styles.treatmentDetails}>
                  {treatments.map((treatment: Treatment, index) => {
                    return (
                      <li key={index}>{this.props.i18n.t(`find-a-provider-detail:treatment.${treatment.type}`)}</li>
                    );
                  })}
                </ol>
                {/* TODO: put next to li in a flexbox after line 206 */}
                {/* <Info className={styles.treatmentInfoIcon} /> */}

              </div>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:treatmentModality.title')}</div>
                <div>{this.props.i18n.t(`find-a-provider-detail:treatmentModality.${treatmentModality}`)}</div>
              </div>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:personal.title')}</div>
                <div className={styles.personalDetails}>
                  <div>
                    {this.props.i18n.t('find-a-provider-detail:personal.gender.title')}: <span>
                      {this.props.i18n.t(`find-a-provider-detail:personal.gender.${gender}`)}
                    </span>
                  </div>
                  <div>
                    {this.props.i18n.t('find-a-provider-detail:personal.age')}: <span>
                      {age}</span>
                  </div>
                  <div>
                    {this.props.i18n.t('find-a-provider-detail:personal.ethnicity')}: <span>
                      {this.toTitleCase(ethnicity)}
                    </span>
                  </div>
                  <div>
                    {this.props.i18n.t('find-a-provider-detail:personal.religion.title')}: <span>
                      {this.props.i18n.t(`find-a-provider-detail:personal.religion.${religion}`)}
                    </span>
                  </div>
                  <div>
                    {this.props.i18n.t('find-a-provider-detail:personal.language.title')}: <span>
                      {this.props.i18n.t(`find-a-provider-detail:personal.language.${language}`)}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.providerSubtitle}>{this.props.i18n.t('find-a-provider-detail:insurance.title')}</div>
                <div className={styles.insuranceDetails}>
                  {acceptInsurance === 'yes'
                    ? <>
                      <Check className={styles.insuranceInfoIcon} />
                      {this.props.i18n.t('find-a-provider-detail:insurance.accept')}
                    </>
                    : <>
                      <Cross className={styles.insuranceInfoIcon} />
                      {this.props.i18n.t('find-a-provider-detail:insurance.notAccept')}
                    </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default FindAProviderDetail;
