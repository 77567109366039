import React from 'react';

interface LinkOutProp {
  className?: string;
}

class LinkOut extends React.Component<LinkOutProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label='link-out' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 6L13 1" stroke="#4A8870" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.66602 1H12.9993V4.33333" stroke="#4A8870" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 8.33333V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H5.66667" stroke="#4A8870" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    );
  }
}

export default LinkOut;
