import React from 'react';
import styles from './ScreeningTest.module.css';

import { Col, Row, Toast } from 'react-bootstrap';

import CustomButton from '../../components/custom-button/CustomButton';
import CustomFormField from '../../components/custom-form-field/CustomFormField';
import CustomFormToggle from '../../components/custom-form-toggle/CustomFormToggle';
import IconButton from '../../components/icon-button/IconButton';

enum ScreeningTestPage {
  LANDING,
  INSTRUCTION,
  QUESTION,
  RESULT
}

interface ScreeningTestProp {
  i18n: any;
  history: any;
}

interface ScreeningTestState {
  answer: string[];
  formInput: FormInput;
  isButtonDisabled: boolean;
  isError: boolean;
  isLoading: boolean;
  isQuestionComplete: boolean;
  page: ScreeningTestPage;
  questionPage: number;
  testScore: number;
}

interface CustomFormFieldState {
  isValid: boolean,
  isDirty: boolean,
  value: string
}

interface FormInput {
  initials: string;
  age: string;
  gender: string;
  email: string;
  phone: string;
}

class ScreeningTest extends React.Component<ScreeningTestProp, ScreeningTestState> {
  questions: [];

  constructor(props: ScreeningTestProp) {
    super(props);
    this.state = {
      answer: [],
      formInput: {
        initials: '',
        age: '',
        gender: '',
        email: '',
        phone: '',
      },
      isButtonDisabled: true,
      isError: false,
      isLoading: false,
      isQuestionComplete: true,
      page: ScreeningTestPage.LANDING,
      questionPage: 1,
      testScore: 0
    };
    this.questions = this.props.i18n.t('screening-test:questions', { returnObjects: true });
  }

  render() {
    return (
      <>
        {this.getCurrPage()}
        <div className={styles.toastWrapper}>
          {this.errorToast()}
        </div>
      </>
    );
  }

  restartTest = () => {
    this.setState({
      page: ScreeningTestPage.LANDING
    });
  }

  takeTheTest = () => {
    this.setState({
      page: ScreeningTestPage.INSTRUCTION
    });
  }

  startTheTest = () => {
    this.setState({
      answer: [],
      page: ScreeningTestPage.QUESTION,
      testScore: 0,
      questionPage: 1,
      formInput: {
        initials: '',
        age: '',
        gender: '',
        email: '',
        phone: '',
      }
    });
  }

  submit = () => {
    this.postScreeningTestResult();
  }

  checkFormValid = () => {
    if (this.ageValidator(this.state.formInput.age) &&
      this.emailValidator(this.state.formInput.email) &&
      this.phoneValidator(this.state.formInput.phone) &&
      this.state.formInput.initials !== '' &&
      this.state.formInput.gender !== '' &&
      this.state.isQuestionComplete) {
      this.setDisableButton(false);
    } else {
      this.setDisableButton(true);
    }
  }

  setAnswer = (event: any) => {
    const answer = this.state.answer;
    const testScore = event.target.value === 'Yes' ? this.state.testScore + 1 : this.state.testScore;
    answer[this.state.questionPage - 1] = event.target.value;
    this.setState({
      answer,
      testScore
    }, this.setQuestionValid);
    setTimeout(() => !this.disableNextQuestion() ? this.setNextQuestionPage() : '', 200);
  }

  disablePrevQuestion = () => {
    return this.state.questionPage === 1;
  }

  disableNextQuestion = () => {
    return this.state.questionPage === this.questions.length;
  }

  setPrevQuestionPage = () => {
    this.setState({
      questionPage: this.state.questionPage - 1
    });
  }

  setNextQuestionPage = () => {
    this.setState({
      questionPage: this.state.questionPage + 1
    });
  }

  setField = (type: string, input: CustomFormFieldState) => {
    this.setState({
      formInput: {
        ...this.state.formInput,
        [type]: input.value
      }
    }, this.checkFormValid);
  }

  setQuestionValid = () => {
    if (this.state.answer.filter(a => a !== undefined).length === this.questions.length) {
      this.setState({
        isQuestionComplete: true
      }, this.checkFormValid);
    } else {
      this.setState({
        isQuestionComplete: false
      });
    }
  }

  ageValidator = (input: string) => {
    const ageRegex = /^[1-9][0-9]?[0-9]?$/;
    return ageRegex.test(input);
  }

  emailValidator = (input: string) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
    return emailRegex.test(input.toLowerCase());
  }

  phoneValidator = (input: string) => {
    const phoneRegex = /^[+][0-9]{11,14}$|^[0][0-9]{9,12}$/;
    return phoneRegex.test(input);
  }

  setDisableButton = (input: boolean) => {
    this.setState({
      isButtonDisabled: input
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading
    });
  }

  postScreeningTestResult = () => {
    this.toggleLoading();
    const url = `${process.env.REACT_APP_MH_STRAPI_ENDPOINT}/screening-test-results`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'name': this.state.formInput.initials,
        'age': this.state.formInput.age,
        'gender': this.state.formInput.gender,
        'email': this.state.formInput.email,
        'phoneNumber': this.state.formInput.phone,
        'testResult': this.state.testScore
      })
    })
      .then(() => {
        this.setState({
          page: ScreeningTestPage.RESULT,
        });
        this.toggleLoading();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isError: true
        });
        this.toggleLoading();
      });
  }

  private getCurrPage() {
    switch (this.state.page) {
      case ScreeningTestPage.LANDING:
        return this.getLandingPage();
      case ScreeningTestPage.INSTRUCTION:
        return this.getInstructionPage();
      case ScreeningTestPage.QUESTION:
        return this.getQuestionPage();
      case ScreeningTestPage.RESULT:
        return this.getResultPage();
      default:
        return (<div className='page-container'>Coming Soon ...</div>);
    }
  }

  private getLandingPage() {
    return (
      <div className='page-container'>
        <h1 className='header-1'>{this.props.i18n.t('navbar.screeningTest')}</h1>
        <Row className={styles.reverseWrap}>
          <Col xs="12" lg="6">
            <div className={styles.section}>
              <div className='header-3'>
                <div>{this.props.i18n.t('screening-test:subtitle1')}</div>
                <div>{this.props.i18n.t('screening-test:subtitle2')}</div>
              </div>
            </div>
            <div className={styles.section}>
              <p>
                {this.props.i18n.t('screening-test:description1')}
              </p>
              <p>
                {this.props.i18n.t('screening-test:description2')}
              </p>
            </div>
            <CustomButton id='take-screening-test' type='button' ariaLabel='Take Screening Test'
              label={this.props.i18n.t('screening-test:takeTheTest')} onClick={this.takeTheTest} />
          </Col>
          <Col xs="12" lg="6">
            <img src={process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-landing.svg'}
              alt='screening-test-landing-image' className={styles.landingImg} />
          </Col>
        </Row>
      </div>
    );
  }

  private getInstructionPage() {
    return (
      <div
        className={`page-container ${styles.screeningPageContainer}`}
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-background.svg'})` }}
      >
        <div className={styles.screeningPage}>
          <div
            className={styles.iconWithText}
            onClick={this.restartTest}
          >
            <img src={`${process.env.PUBLIC_URL + '/assets/icons/arrow-left.svg'}`} alt="prev-page-button-arrow" />
            <div>{this.props.i18n.t('navbar.screeningTest')}</div>
          </div>
          <Row className={styles.reverseWrap}>
            <Col xs="12" lg="6">
              <div className={styles.section}>
                <p>
                  {this.props.i18n.t('screening-test:instructionDesc1')}&nbsp;
                  <strong>{this.props.i18n.t('screening-test:yesOrNo')}</strong>&nbsp;
                  {this.props.i18n.t('screening-test:instructionDesc2')}&nbsp;
                  <strong>{this.props.i18n.t('screening-test:feeling')}</strong>
                </p>
                <p>
                  {this.props.i18n.t('screening-test:instructionDesc3')}
                </p>
              </div>
              <CustomButton id='start-screening-test' type='button' label={this.props.i18n.t('screening-test:start')}
                onClick={this.startTheTest} ariaLabel='Start Screening Test' />
            </Col>
            <Col xs="12" lg="6">
              <img src={process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-instruction.svg'}
                alt='screening-test-instruction-image' className={styles.screeningPageImg} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  private getQuestionPage() {
    return (
      <div
        className={`page-container ${styles.screeningPageContainer}`}
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-background.svg'})` }}
      >
        <div className={styles.screeningPage}>
          <Row className={styles.section}>
            <Col xs='6' lg='6' className={styles.headerOne}>
              {this.props.i18n.t('screening-test:questionHeader')}
            </Col>
            <Col xs='6' lg='6' className={styles.pageIndicator}>
              {`${this.state.questionPage} ${this.props.i18n.t('screening-test:indicator')} ${this.questions.length}`}
            </Col>
            <Col xs='12' lg='12' className={`${styles.headerTwo} ${styles.question}`}>
              {this.questions[this.state.questionPage - 1]}
            </Col>
          </Row>
          <Row className={styles.section}>
            <Col xs='12' lg='6' className={styles.questionOptionContainer}>
              <label className={styles.questionOption}>
                <div className={styles.radioInput}>
                  <input type="radio" name="yesOrNo" value="Yes" onChange={this.setAnswer}
                    checked={this.state.answer[this.state.questionPage - 1] === 'Yes'} />
                  <div className={styles.radioControl}></div>
                </div>
                <div>{this.props.i18n.t('screening-test:yes')}</div>
              </label>
              <label className={styles.questionOption}>
                <div className={styles.radioInput}>
                  <input type="radio" name="yesOrNo" value="No" onChange={this.setAnswer}
                    checked={this.state.answer[this.state.questionPage - 1] === 'No'} />
                  <div className={styles.radioControl}></div>
                </div>
                <div>{this.props.i18n.t('screening-test:no')}</div>
              </label>
            </Col>
            <Col xs='12' lg='6' className={styles.questionNavigationContainer}>
              <div
                className={styles.iconWithText}
                onClick={this.startTheTest}
              >
                <img src={`${process.env.PUBLIC_URL + '/assets/icons/reset.svg'}`} alt="reset" />
                <div>Reset</div>
              </div>
              <div className={styles.prevNextNavigation}>
                <IconButton alt="short-arrow-up"
                  option={{ disable: this.disablePrevQuestion(), height: styles.iconButtonHeight, width: styles.iconButtonWidth }}
                  src={process.env.PUBLIC_URL + '/assets/icons/short-arrow-up.svg'} onClick={this.setPrevQuestionPage} />
                <IconButton alt="short-arrow-down"
                  option={{ disable: this.disableNextQuestion(), height: styles.iconButtonHeight, width: styles.iconButtonWidth }}
                  src={process.env.PUBLIC_URL + '/assets/icons/short-arrow-down.svg'} onClick={this.setNextQuestionPage} />
              </div>
            </Col>
          </Row>
          {this.disableNextQuestion() ? this.getDemographicData() : null}
        </div>
      </div>
    );
  }

  private getDemographicData() {
    return (
      <form onSubmit={(e) => { this.submit(); e.preventDefault(); }}>
        <hr />
        <Row className={styles.section}>
          <Col xs='12' lg='12' className={styles.headerTwo}>
            {this.props.i18n.t('screening-test:demographic')}
          </Col>
          <Col xs='12' lg='12' className={styles.headerOne}>
            {this.props.i18n.t('screening-test:demographic2')}
          </Col>
        </Row>
        <Row className={styles.section}>
          <Col xs='12' sm='6' lg='4' className={styles.formGroupOne}>
            <Row className={styles.formFirstRow}>
              <Col>
                <CustomFormField value={this.state.formInput.initials} name='initials'
                  placeholder={this.props.i18n.t('screening-test:initials')}
                  option={{ height: styles.inputHeight, width: styles.inputWidth }} onChange={this.setField} />
              </Col>
              <Col>
                <CustomFormField value={this.state.formInput.age} errorMessage='Invalid age' name='age'
                  placeholder={this.props.i18n.t('screening-test:age')}
                  option={{ height: styles.inputHeight, width: styles.inputWidth }} onChange={this.setField}
                  validator={this.ageValidator} />
              </Col>
            </Row>
            <CustomFormField value={this.state.formInput.email} errorMessage='Invalid email format' name='email'
              placeholder={this.props.i18n.t('screening-test:email')}
              option={{ height: styles.inputHeight, width: styles.inputWidth }}
              onChange={this.setField} validator={this.emailValidator} />
          </Col>
          <Col xs='12' sm='6' lg='4' className={styles.formGroupTwo}>
            <CustomFormToggle value={this.state.formInput.gender} name='gender' option={{
              option1: this.props.i18n.t('screening-test:genderMale'),
              option2: this.props.i18n.t('screening-test:genderFemale'), height: styles.inputHeight,
              width: styles.inputWidth
            }} onChange={this.setField} />
            <CustomFormField value={this.state.formInput.phone} errorMessage='Invalid phone' name='phone'
              placeholder={this.props.i18n.t('screening-test:phone')}
              option={{ height: styles.inputHeight, width: styles.inputWidth }} onChange={this.setField}
              validator={this.phoneValidator} />
          </Col>
        </Row>
        <Row className={styles.section}>
          <Col lg='auto' className={styles.buttonWithSpinner}>
            {this.state.isLoading
              ? this.spinner()
              : <CustomButton isDisabled={this.state.isButtonDisabled} ariaLabel='Submit Screening Test Demographic Data'
                id='submit-screening-test-result' label='Submit' type='submit' onClick={this.submit} />}
          </Col>
          <Col lg='12'>
            {!this.state.isQuestionComplete ? <div className={styles.errorMessage}>Please fill all questions</div> : null}
          </Col>
        </Row>
      </form>
    );
  }

  private getResultPage() {
    return (
      <div
        className={`page-container ${styles.screeningPageContainer}`}
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-background.svg'})` }}
      >
        <div className={styles.screeningPage}>
          <div
            className={styles.iconWithText}
            onClick={this.restartTest}
          >
            <img src={`${process.env.PUBLIC_URL + '/assets/icons/arrow-left.svg'}`} alt="prev-page-button-arrow" />
            <div>{this.props.i18n.t('navbar.screeningTest')}</div>
          </div>
          <Row className={styles.reverseWrap}>
            <Col xs="12" lg="6">
              <div className={styles.section}>
                <p className={styles.resultHeader}>
                  {this.props.i18n.t('screening-test:result')}
                </p>
                <p>
                  {this.props.i18n.t('screening-test:totalScore')}<strong>{this.state.testScore}</strong>
                </p>
                <p>
                  {this.state.testScore < 6 ?
                    this.props.i18n.t('screening-test:resultDesc6less') :
                    this.props.i18n.t('screening-test:resultDesc6more')}
                </p>
                <p>
                  {this.props.i18n.t('screening-test:disclamer')}
                </p>
              </div>
              {this.state.testScore < 6 ?
                <CustomButton id='start-screening-test' type='button'
                  label={this.props.i18n.t('screening-test:visitInstagram')}
                  onClick={() => window.open('https://www.instagram.com/yayasanmerajuthati/', '_blank')}
                  ariaLabel='Visit Instagram' /> :
                <CustomButton id='start-screening-test' type='button' label={this.props.i18n.t('screening-test:findProvider')}
                  onClick={() => this.props.history.push('/findaprovider')} ariaLabel='Find A Provider' />
              }
            </Col>
            <Col xs="12" lg="6">
              {this.state.testScore < 6 ?
                <img src={process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-result-6-less.svg'}
                  alt='screening-test-result-image' className={styles.screeningPageImg} /> :
                <img src={process.env.PUBLIC_URL + '/assets/img/screening-test/screening-test-result-6-more.svg'}
                  alt='screening-test-result-image' className={styles.screeningPageImg} />
              }
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  private spinner() {
    return (
      <div className={styles.spinnerWrapper}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  private errorToast() {
    return (
      <Toast onClose={() => this.setState({ isError: false })} className={styles.toast}
        show={this.state.isError}>
        <Toast.Header className={styles.toastHeader}>
          <div className={styles.toastHeaderText}>
            <strong>Alert</strong>
          </div>
        </Toast.Header>
        <Toast.Body className={styles.toastBody}>
          Unknown Error Occurred
        </Toast.Body>
      </Toast>
    );
  }
}

export default ScreeningTest;
