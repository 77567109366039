import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MeetTheTeam.module.css';

import { Col, Row } from 'react-bootstrap';
import Email from '../../assets/svg/Email/Email';
import LinkedIn from '../../assets/svg/LinkedIn/LinkedIn';
import MeetTheTeamBackground from '../../assets/svg/MeetTheTeamBackground/MeetTheTeamBackground';

import LoadingScreen from '../loading-screen/LoadingScreen';

import CustomButton from '../../components/custom-button/CustomButton';
// import client from '../../utils/apollo';
// import GET_MEMBERS from '../../utils/queries/members';

interface Member {
  id: string;
  type: string;
  fullName: string;
  division: string;
  position: string;
  email: string;
  linkedin: string;
  profilePicture: any;
  order: number;
}

interface MeetTheTeamProps {
  i18n: any;
}

interface MeetTheTeamState {
  members: Member[];
  isLoading: boolean;
}

class MeetTheTeam extends React.Component<MeetTheTeamProps, MeetTheTeamState> {

  constructor(props: MeetTheTeamProps) {
    super(props);
    this.state = {
      members: [],
      isLoading: true
    };

    // USAGE of graphql query
    // client.query({ query: GET_MEMBERS }).then(data => {
    //   console.log(data);
    // });
  }

  componentDidMount() {
    const mhMembersUrl = `${process.env.REACT_APP_MH_STRAPI_ENDPOINT}/members`;
    fetch(mhMembersUrl)
      .then(response => response.json())
      .then(members => {
        this.setState({
          members,
          isLoading: false
        });
      });
  }

  render() {
    const boardMembers = this.state.members.filter((member: Member) => member.type === 'boe').sort((a, b) => (a.order - b.order));
    const volunteers = this.state.members.filter((member: Member) => (member.type !== 'boe'
      && member.type !== 'employee')).sort((a, b) => (a.order - b.order));
    const employees = this.state.members.filter((member: Member) => member.type === 'employee').sort((a, b) => (a.order - b.order));

    const volunteersMap = {} as { [key: string]: Member[] };
    volunteers.forEach((member: Member) => {
      if (!volunteersMap[member.division]) {
        volunteersMap[member.division] = [member];
      } else {
        volunteersMap[member.division].push(member);
      }
    });

    const employeesMap = {} as { [key: string]: Member[] };
    employees.forEach((member: Member) => {
      if (!employeesMap[member.division]) {
        employeesMap[member.division] = [member];
      } else {
        employeesMap[member.division].push(member);
      }
    });

    if (this.state.isLoading) {
      return (<LoadingScreen />);
    }

    return (
      <div className='page-container'>
        <div className={styles.boardMemberSection}>
          <div className={styles.boardMemberSectionBackground}>
            <MeetTheTeamBackground className={styles.boardMemberBackground} />
          </div>

          <h3 className={styles.header}>
            {this.props.i18n.t('meet-the-team:meetTheTeam')}
          </h3>

          <h1 className={styles.boardMemberHeader}>
            Board Members
          </h1>
          {/* Board Members */}
          <Row>
            {
              boardMembers.map((member: Member) => {
                return (
                  <Col key={member.id} xs={12} md={4} lg={3}>
                    {this.getMemberProfile(member)}
                  </Col>
                );
              })
            }
          </Row>
        </div>

        {/* Volunteers */}
        <div>
          <h1>
            Volunteers
          </h1>
          {
            Object.keys(volunteersMap).map((division: string, idx: number) => {
              return (
                <div key={idx}>
                  <Row>
                    <Col lg={3} className={styles.memberDivision}>
                      {division}
                    </Col>
                    <Col lg={9}>
                      <Row>
                        {
                          volunteersMap[division].map((member: Member) => {
                            return (
                              <Col key={member.id} xs={12} lg={4}>
                                {this.getMemberProfile(member)}
                              </Col>
                            );
                          })
                        }
                      </Row>
                    </Col>
                  </Row>
                  {idx !== Object.keys(volunteersMap).length - 1 ? <hr /> : null}
                </div>
              );
            })
          }
        </div>

        {/* Employees */}
        <div>
          <h1>
            Employees
          </h1>
          {
            Object.keys(employeesMap).map((division: string, idx: number) => {
              return (
                <div key={idx}>
                  <Row>
                    <Col lg={3} className={styles.memberDivision}>
                      {division}
                    </Col>
                    <Col lg={9}>
                      <Row>
                        {
                          employeesMap[division].map((member: Member) => {
                            return (
                              <Col key={member.id} xs={12} lg={4}>
                                {this.getMemberProfile(member)}
                              </Col>
                            );
                          })
                        }
                      </Row>
                    </Col>
                  </Row>
                  {idx !== Object.keys(employeesMap).length - 1 ? <hr /> : null}
                </div>
              );
            })
          }
        </div>
        {/* Join Our Team */}
        <div className={styles.joinTheTeamSection}>
          <div className={styles.joinTheTeamTitle}>
            {this.props.i18n.t('meet-the-team:makeADiff')}
          </div>
          <div>
            {this.props.i18n.t('meet-the-team:makeADiffDesc')}
          </div>
          <div className={styles.joinTheTeamContent}>
            <Link
              to={{ pathname: 'https://docs.google.com/forms/d/e/1FAIpQLSfsi-7209W-R3zicSBbeH1dOW05mNRXWnAjne_T8Q77xzVtYQ/viewform' }}
              target='_blank'>
              <CustomButton
                colorTheme="dark"
                colorType="secondary"
                type="button"
                id="meet-the-team-join-our-team"
                ariaLabel="Join Our Team"
                label={this.props.i18n.t('meet-the-team:joinOurTeam')}
                className={styles.joinTheTeamBtn} />
            </Link>

            <img className={styles.joinTheTeamBackgroundImg}
              src={process.env.PUBLIC_URL + '/assets/img/meet-the-team/join-our-team.svg'}
              alt="join-our-team" />
          </div>
        </div>
      </div>
    );
  }

  private getMemberProfile(member: Member) {
    return (
      <div>
        {
          member.profilePicture ? (
            <div>
              <img src={member.profilePicture.url} alt={member.profilePicture.name} className={styles.memberProfilePicture} />
            </div>)
            : null
        }

        <div className={styles.memberProfileFullName}>
          {member.fullName}
        </div>
        <div className={styles.memberPosition}>
          {member.position}
        </div>
        <div className={styles.memberProfileLinks}>
          <Link to={{ pathname: `mailto: ${member.email}` }}
            target='_blank'>
            <Email />
          </Link>
          {
            member.linkedin ? (
              <Link to={{ pathname: member.linkedin }}
                target='_blank'>
                <LinkedIn />
              </Link>
            ) : null
          }

        </div>
      </div>
    );
  }
}

export default MeetTheTeam;
