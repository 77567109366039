import React  from 'react';
import styles from './CheckboxPanel.module.css';
import Checkbox from './checkbox/Checkbox';

interface CheckboxPanelProps {
  header: string;
  filters: string[];
  onChange: (state: any) => void;
}

interface CheckboxPanelState {
  expand: boolean;
  filters: boolean[];
}

class CheckboxPanel extends React.Component<CheckboxPanelProps, CheckboxPanelState> {
  arrowRef: React.RefObject<HTMLImageElement>
  bodyRef: React.RefObject<HTMLDivElement>
  arrayRef: any

  constructor(props: CheckboxPanelProps) {
    super(props);
    const filtersBoolean = [];
    this.arrayRef = [];
    for (const i in this.props.filters) {
      filtersBoolean.push(false);
    }
    this.state = {
      expand: false,
      filters: filtersBoolean
    };
    this.arrowRef = React.createRef();
    this.bodyRef = React.createRef();
  }

  componentDidUpdate(prevProp: CheckboxPanelProps, prevState: CheckboxPanelState): void {
    if (this.state.filters !== prevState.filters) {
      this.props.onChange(this.state.filters);
      for (let count = 0; count < this.arrayRef.length; count++) {
        this.arrayRef[count].setState({
          checked: this.state.filters[count]
        });
      }
    }
  }

  onChangeCheckbox = (state: any) => {
    const filters = [] as any[];
    this.arrayRef.forEach((element:any) => {
      filters.push(element.state.checked);
    });
    this.setState({
      filters
    });
  }

  addToRef = (el:any) => {
    if (!this.arrayRef.includes(el)) {
      this.arrayRef.push(el);
    }
  }

  createCheckBox = () => {
    const container = [] as any[];
    this.props.filters.forEach(filter => {
      container.push(<Checkbox key={filter} label={filter} onChange={this.onChangeCheckbox}
                               ref={this.addToRef}></Checkbox>);
    });

    return container;
  }

  toggleExpand = () => {
    if (!this.state.expand) {
      this.arrowRef.current!.className = styles.imageExpanded;
      this.bodyRef.current!.className = styles.bodyExpanded;
      this.setState({
        expand: true
      });
    } else {
      this.arrowRef.current!.className = styles.imageNotExpanded;
      this.bodyRef.current!.className = styles.bodyNotExpanded;
      this.setState({
        expand: false
      });
    }
  }

  render() {
    return (
      <div>
        <div className={styles.header}>
          <div className={styles.row} onClick={this.toggleExpand} >
            {this.props.header}
            <img className={styles.image} src={process.env.PUBLIC_URL + '/assets/icons/dropdown.svg'} ref={this.arrowRef}/>
          </div>
          <div className={styles.body} ref={this.bodyRef}>
            {this.createCheckBox()}
          </div>
        </div>
      </div>
    );
  }
}

export default CheckboxPanel;
