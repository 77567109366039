import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import styles from './NewsletterPopup.module.css';

interface NewsletterPopupProps {
  isPopupShown: boolean;
  i18n: any;
}

class NewsletterPopup extends React.Component<NewsletterPopupProps> {
  newsletterEmailInput: React.RefObject<any>;
  state: { isPopupShown: boolean; };

  constructor(props: NewsletterPopupProps) {
    super(props);
    this.state = {
      isPopupShown: props.isPopupShown,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.newsletterEmailInput = React.createRef();
  }

  handleShow() {
    this.setState({
      isPopupShown: true
    });
  }

  handleClose() {
    this.setState({
      isPopupShown: false
    });
  }

  render() {
    return (
      <>
        <Modal show={this.state.isPopupShown} onHide={this.handleClose}>
          <Modal.Header className={styles.modalHeader} closeButton>
          </Modal.Header>
          <Card className={styles.popupCard}>
            <Card.Img className={styles.popupImage} variant="top" src="assets/img/newsletter/newsletter-popup.svg" />
            <Card.Body>
              <Card.Title>{this.props.i18n.t('newsletterPopup.title')}</Card.Title>
              <Card.Text>
                {this.props.i18n.t('newsletterPopup.description')}
              </Card.Text>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder={this.props.i18n.t('newsletterPopup.emailPlaceholder')}
                  aria-label="Your email"
                  aria-describedby="basic-addon2"
                  className={styles.textfield}
                  ref={this.newsletterEmailInput}
                />
                <Button className={styles.submitButton} onClick={this.subscribeNewsletter}>
                  {this.props.i18n.t('newsletterPopup.join')}
                </Button>
              </InputGroup>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }

  private subscribeNewsletter = () => {
    const email = this.newsletterEmailInput.current.value as string;
    fetch(`${process.env.REACT_APP_MH_MAILCHIMP_ENDPOINT}/subscribe`, {
      method: 'POST',
      body: JSON.stringify({
        email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status === 400) {
          // TODO: replace with react bootstrap toast
          alert('Email Required');
        } else {
          this.handleClose();
          // TODO: replace with react bootstrap toast
          alert('Newsletter Subscription Successful');
        }
      })
      .catch(err => {
        console.error(err);
        // TODO: replace with react bootstrap toast
        alert('Unknown Error Occurred');
      });
  }
}

export default NewsletterPopup;
