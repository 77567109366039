import { ApolloQueryResult } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import styles from './BerbagiHatiDetail.module.css';


import CustomButton from '../../components/custom-button/CustomButton';
import DonutChart from '../../components/donut-chart/DonutChart';
import LabeledFormField, { LabeledFormFieldState } from '../../components/labeled-form-field/LabeledFormField';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import TextButton from '../../components/text-button/TextButton';

import client from '../../utils/apollo';
import { GET_CLIENTS_BY_ID } from '../../utils/queries/clients';

interface ClientUpdate {
  title: string;
  description: string;
  date: string;
}
interface CostBreakdown {
  programType: string;
  amount: number;
  category: string;
}

interface BerbagiHatiDetailProp {
  i18n: any;
  history: any;
  match: any;
}

interface BerbagiHatiDetailState {
  clientUpdates: ClientUpdate[];
  costBreakdowns: CostBreakdown[];
  description: string;
  donationForm: FormInput;
  goal: number;
  isDonateDisabled: boolean;
  name: string;
  numberOfSponsors: number;
  profilePicture: Media;
  raised: number;
  status: string;
  story: string;
}

interface FormInput {
  donationAmount: string;
}

interface Media {
  name: string;
  url: string;
}


class BerbagiHatiDetail extends React.Component<BerbagiHatiDetailProp, BerbagiHatiDetailState> {

  constructor(props: BerbagiHatiDetailProp) {
    super(props);
    this.state = {
      clientUpdates: [],
      costBreakdowns: [],
      description: '',
      donationForm: {
        donationAmount: ''
      },
      goal: 0,
      isDonateDisabled: true,
      name: '',
      numberOfSponsors: 0,
      profilePicture: {
        name: '',
        url: ''
      },
      raised: 0,
      status: '',
      story: ''
    };
  }


  componentDidMount() {
    client.query({
      query: GET_CLIENTS_BY_ID,
      variables: {
        id: this.props.match.params.id
      }
    })
      .then((response: ApolloQueryResult<any>) => {
        const {
          client_updates,
          cost_breakdowns,
          description,
          goal,
          name,
          numberOfSponsors,
          profilePicture,
          raised,
          status,
          story
        } = response.data.clients[0];

        this.setState({
          clientUpdates: client_updates,
          costBreakdowns: cost_breakdowns,
          description,
          goal,
          name,
          numberOfSponsors,
          profilePicture,
          raised,
          status,
          story
        });
      });
  }

  render() {
    const translatedCostBreakdownCategory = this.props.i18n.t('berbagi-hati-detail:costBreakdownCategory', { returnObjects: true });
    const costBreakdownColorCategory: { [key: string]: string } = {};
    costBreakdownColorCategory[translatedCostBreakdownCategory['First 7 sessions']] = '#4A8870';
    costBreakdownColorCategory[translatedCostBreakdownCategory['20 sessions for the rest of the year']] = '#2D5C4A';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Medication']] = '#A7D59B';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Cushion fund']] = '#FACE33';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Transportation & accomodation']] = '#ED9C76';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Total session cost']] = '#30886E';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Medication Budget + Psychiatry Appointments']] = '#A7D59B';
    costBreakdownColorCategory[translatedCostBreakdownCategory['Extra therapy sessions']] = '#EEE9B6';

    return (
      <>
        <div className={styles.profileContainer}>
          <div className={styles.iconWithText} onClick={() => this.props.history.push('/berbagihati')}>
            <img src={`${process.env.PUBLIC_URL + '/assets/icons/arrow-left.svg'}`}
              alt="prev-page-button-arrow" />
            <div>{this.props.i18n.t('berbagi-hati-detail:backToBerbagiHati')}</div>
          </div>

          <h1 className={styles.profileHeader}>Berbagi Hati {this.props.i18n.t('berbagi-hati-detail:for')} {this.state.name}</h1>
          <Row>
            <Col md={12} lg={4}>
              <div className={styles.profilePictureContainer}>
                <div>
                  <img src={this.state.profilePicture.url} alt={this.state.profilePicture.name} className={styles.profilePicture} />
                </div>
                <div className={styles.profilePictureDescription}>
                  {this.state.description}
                </div>
              </div>
            </Col>
            <Col md={12} lg={8}>
              {/* Status Section */}
              <div className={this.getClientStatusClassNames()}>
                {this.props.i18n.t(`berbagi-hati-detail:status.${this.state.status}`)}
              </div>
              <div className={styles.advertisement}>
                Sponsor <b>{this.state.name}</b> {this.props.i18n.t('berbagi-hati-detail:advertisementPart2')}
              </div>
              <ProgressBar progress={Math.floor(this.state.raised / this.state.goal * 100)} />
              <div className={styles.progressText}>
                <b>IDR{this.formatNumber(this.state.raised)}</b> {this.props.i18n.t('berbagi-hati-detail:raisedOutOf')}
                &nbsp; <b>IDR{this.formatNumber(this.state.goal)}</b> {this.props.i18n.t('berbagi-hati-detail:goal')}
              </div>
              <div className={styles.numberOfSponsors}>
                {this.state.numberOfSponsors} {this.props.i18n.t('berbagi-hati-detail:peopleDonated')}
              </div>

              {/* Donate Section */}
              <Row className={styles.donationFormContainer}>
                <TextButton ariaLabel='Donate 100,000' id='donate-100,000' label='IDR100,000' type='button'
                  onClick={() => this.setDonationAmount('100000')} />

                <TextButton ariaLabel='Donate 200,000' id='donate-200,000' label='IDR200,000' type='button'
                  onClick={() => this.setDonationAmount('200000')} />

                <TextButton ariaLabel='Donate 400,000' id='donate-400,000' label='IDR400,000' type='button'
                  onClick={() => this.setDonationAmount('400000')} />

                <TextButton ariaLabel='Donate 1,000,000' id='donate-1,000,000' label='IDR1,000,000' type='button'
                  onClick={() => this.setDonationAmount('1000000')} />

                <span className={styles.donateTextOption}>{this.props.i18n.t('berbagi-hati-detail:or')}</span>

                <LabeledFormField
                  errorMessage='Invalid Amount'
                  onChange={this.setField}
                  name='donationAmount'
                  label='IDR'
                  value={this.state.donationForm.donationAmount}
                  validator={this.donationValidator}
                />
              </Row>
              <div className={styles.donateBtnContainer}>
                <CustomButton
                  id='donate-continue' type='button' label='Continue'
                  isDisabled={this.state.isDonateDisabled}
                  onClick={this.navigateToPaymentGateway} ariaLabel='Continue' />
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.detailContainer}>
          <Tabs defaultActiveKey="story">
            <Tab eventKey="story" title={this.props.i18n.t('berbagi-hati-detail:story')} className={styles.story}>
              {this.state.story}
            </Tab>
            <Tab eventKey="costBreakdown" title={this.props.i18n.t('berbagi-hati-detail:costBreakdown')} className={styles.costBreakdown}>
              <DonutChart colorCategory={costBreakdownColorCategory}
                donutChartData={this.convertCostBreakdownsToDonutChartData(this.state.costBreakdowns)}
                selectedCategory='all_expenses' />
            </Tab>
            <Tab eventKey="updates" title={this.props.i18n.t('berbagi-hati-detail:updates')} className={styles.updates}>
              {this.getClientUpdates()}
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }

  private formatNumber(amount: number) {
    return amount.toLocaleString();
  }

  private setDonationAmount = (amount: string) => {
    this.setState({
      donationForm: {
        ...this.state.donationForm,
        donationAmount: amount
      },
      isDonateDisabled: !this.donationValidator(amount)
    });
  }

  private donationValidator = (input: string) => {
    const positiveNumberRegex = new RegExp(/^[1-9][0-9]*$/);
    // TODO: minimum donation amount?
    if (positiveNumberRegex.test(input)) {
      return true;
    }
    return false;
  }

  private setField = (name: string, state: LabeledFormFieldState) => {
    this.setState({
      donationForm: {
        ...this.state.donationForm,
        [name]: state.value
      }
    }, this.checkFormValid);
  }

  private checkFormValid = () => {
    if (this.donationValidator(this.state.donationForm.donationAmount)) {
      this.setState({
        isDonateDisabled: false
      });
    } else {
      this.setState({
        isDonateDisabled: true
      });
    }
  }

  private navigateToPaymentGateway = () => {
    this.props.history.push({
      pathname: '/paymentgateway',
      search: `?type=berbagihati&amount=${this.state.donationForm.donationAmount}&clientId=${this.props.match.params.id}`
    });
  }

  private convertCostBreakdownsToDonutChartData(costBreakdowns: CostBreakdown[]) {
    const data = costBreakdowns.map(costBreakdown => {
      return {
        amount: costBreakdown.amount,
        category: this.props.i18n.t('berbagi-hati-detail:costBreakdownCategory', { returnObjects: true })[costBreakdown.category]
          || this.props.i18n.t('berbagi-hati-detail:other')
      };
    });
    return data;
  }

  private getClientUpdates() {
    const clientUpdates = [...this.state.clientUpdates].sort((a, b) => moment(b.date).diff(a.date));
    return clientUpdates.map((clientUpdate: ClientUpdate, index: number) => {
      return (
        <div key={index} className={styles.clientUpdateContainer}>
          <div className={styles.clientDescriptionContainer}>
            <div className={styles.clientUpdateTitle}>{clientUpdate.title}</div>
            <div className={styles.clientUpdateDescription}>{clientUpdate.description}</div>
          </div>
          <div className={styles.clientUpdateDate}>
            {moment(clientUpdate.date).format('DD MMMM YYYY')}
          </div>
        </div>
      );
    });
  }

  private getClientStatusClassNames() {
    const status = this.state.status;
    let clientStatusClass = styles.statusContainer;
    if (status === 'treatment_in_progress') {
      clientStatusClass += ` ${styles.clientStatusTreatmentInProgress}`;
    } else if (status === 'on_hold') {
      clientStatusClass += ` ${styles.clientStatusOnHold}`;
    } else {
      clientStatusClass += ` ${styles.defaultClientStatus}`;
    }

    return clientStatusClass;
  }
}

export default BerbagiHatiDetail;
