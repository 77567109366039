import React from 'react';
import styles from './ProgressBar.module.css';

interface ProgressBarProp {
  progress: number;
}

class ProgressBar extends React.Component<ProgressBarProp> {
  render() {
    const progressStylePercentage = this.props.progress > 100 ? 100 : this.props.progress;
    return (
      <div className={styles.container}>
        <div className={styles.progressBar}>
          <div className={styles.inside} style={{ width: progressStylePercentage + '%' }} />
        </div>
        <div className={styles.progress}>
          {this.props.progress}%
        </div>
      </div>
    );
  }
}

export default ProgressBar;
