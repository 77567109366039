import { gql } from '@apollo/client';

const GET_TIDAK_SENDIRI_LAGIS = gql`
query GetTidakSendiriLagis($start: Int, $limit: Int) {
  tidakSendiriLagis(start: $start, limit: $limit) {
      id,
      cover {
        name,
        url
      }
      instagramURL
  }
}
`;

export default GET_TIDAK_SENDIRI_LAGIS;
