import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './FindAProvider.module.css';
import CheckboxPanel from '../../components/checkbox/CheckboxPanel';
import CustomButton from '../../components/custom-button/CustomButton';
import CustomCard from '../../components/custom-card/CustomCard';
import Email from '../../assets/svg/Email/Email';
import Phone from '../../assets/svg/Phone/Phone';
import { Link } from 'react-router-dom';

interface FindAProviderProp {
  history: any;
  i18n: any;
}

interface Providers {
  [index: string]: any;
  acceptInsurance: string;
  address: string;
  age: number;
  description: string;
  email: string;
  gender: string;
  id: string;
  language: string;
  licensure: string;
  location: string;
  name: string;
  phone: string;
  profilePicture: Media[];
  religion: string;
  specialties: Specialty[];
  treatments: Treatment[];
}

interface Specialty {
  description: string;
  id: number;
}

interface Treatment {
  type: string;
}

interface Media {
  url: string;
}

class FindAProvider extends React.Component<FindAProviderProp> {
  allProviders: Providers[];
  arrayRef: any;
  customCards: any;
  customCardRef: any;
  filterPanel: any = {
    'licensure': {
      category: this.props.i18n.t('find-a-provider:filter.licensure.title'),
      options: this.props.i18n.t('find-a-provider:filter.licensure.filters', { returnObjects: true }),
      optionsCode: ['psychologist', 'psychiatrist', 'counselor', 'social_worker'],
      optionsActive: [false, false, false, false]
    },
    'gender': {
      category: this.props.i18n.t('find-a-provider:filter.gender.title'),
      options: this.props.i18n.t('find-a-provider:filter.gender.filters', { returnObjects: true }),
      optionsCode: ['male', 'female'],
      optionsActive: [false, false]
    },
    'specialties': {
      category: this.props.i18n.t('find-a-provider:filter.specialty.title'),
      options: this.props.i18n.t('find-a-provider:filter.specialty.filters', { returnObjects: true }),
      optionsCode: ['mood_disorders', 'depression', 'anxiety_disorders',
        'personality_disorders', 'psychotic_disorders', 'schizophrenia',
        'trauma_related_disorders', 'obsessive_compulsive_related_disorders',
        'dissociative_disorders_and_somatic_symptom_related_disorders',
        'sexual_disorders', 'eating_disorders', 'addiction',
        'developmental_disorders', 'children_mental_health', 'teenage_mental_health',
        'elderly_mental_health', 'adult_mental_health', 'psychiatry'],
      optionsActive: [false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false,
        false, false]
    },
    'treatments': {
      category: this.props.i18n.t('find-a-provider:filter.treatmentType.title'),
      options: this.props.i18n.t('find-a-provider:filter.treatmentType.filters', { returnObjects: true }),
      optionsCode: ['cognitive_behavioral_therapy', 'interpersonal_therapy', 'group_therapy',
        'dialectical_behavior_therapy', 'family_therapy', 'psychodynamic_therapy',
        'feminist_therapy', 'others'],
      optionsActive: [false, false, false, false, false, false, false, false]
    },
    'language': {
      category: this.props.i18n.t('find-a-provider:filter.language.title'),
      options: this.props.i18n.t('find-a-provider:filter.language.filters', { returnObjects: true }),
      optionsCode: ['bahasa', 'english'],
      optionsActive: [false, false]
    },
    'religion': {
      category: this.props.i18n.t('find-a-provider:filter.religion.title'),
      options: this.props.i18n.t('find-a-provider:filter.religion.filters', { returnObjects: true }),
      optionsCode: ['islam', 'christian', 'catholic', 'buddha', 'hindu', 'confucianism', 'na'],
      optionsActive: [false, false, false, false, false, false, false]
    },
    'acceptInsurance': {
      category: this.props.i18n.t('find-a-provider:filter.acceptInsurance.title'),
      options: this.props.i18n.t('find-a-provider:filter.acceptInsurance.filters', { returnObjects: true }),
      optionsCode: ['yes', 'no'],
      optionsActive: [false, false]
    }
  }

  constructor(props: FindAProviderProp) {
    super(props);
    this.arrayRef = [];
    this.customCardRef = React.createRef();
    this.allProviders = [];
  }

  addToRef = (el: any) => {
    if (!this.arrayRef.includes(el)) {
      this.arrayRef.push(el);
    }
  }

  allFilterOff = () => {
    let filterOff = true;
    Object.keys(this.filterPanel).forEach(key => {
      this.filterPanel[key].optionsActive.forEach((filter: any) => {
        if (filter) {
          filterOff = false;
        }
      });
    });
    return filterOff;
  }

  clearFilters = () => {
    Object.keys(this.filterPanel).forEach((element, index) => {
      const tempActive = [] as any[];
      this.filterPanel[element].options.forEach(() => {
        tempActive.push(false);
      });
      this.filterPanel[element].optionsActive = tempActive;
      this.arrayRef[index].setState({
        filters: this.filterPanel[element].optionsActive
      });
    });
  }

  componentDidMount() {
    const providersUrl = `${process.env.REACT_APP_MH_STRAPI_ENDPOINT}/providers`;
    fetch(providersUrl)
      .then(response => response.json())
      .then(providers => {
        this.allProviders = providers;
        this.customCards = this.createProviders(this.allProviders);
        this.forceUpdate();
      })
      .catch(err => {
        console.error(err);
        this.allProviders = [];
        this.customCards = this.createProviders(this.allProviders);
      });
  }

  createCheckboxPanel = () => {
    const component = [] as any[];
    Object.keys(this.filterPanel).forEach((element) => {
      component.push(
        <CheckboxPanel key={this.filterPanel[element].category}
          header={this.filterPanel[element].category}
          filters={this.filterPanel[element].options}
          onChange={this.onChangeFilter} ref={this.addToRef}></CheckboxPanel>);
    });
    return component;
  }

  createProviders = (providers: Providers[]) => {
    const providersCustomCards = [] as any[];

    providers.forEach((provider: Providers) => {
      if (this.allFilterOff() || this.filterProvider(provider)) {
        const specialties = provider.specialties.map(specialty => this.filterPanel['specialties']
          .options[this.filterPanel['specialties'].optionsCode.indexOf(specialty.description)]).join(', ');

        providersCustomCards.push(
          <CustomCard className={styles.provider}
            key={provider.id} imgSrc={provider.profilePicture[0].url} title={provider.name}
            desc={this.filterPanel['licensure'].options[this.filterPanel['licensure']
              .optionsCode.indexOf(provider.licensure)] +
              '\n\n' + this.props.i18n.t('find-a-provider:filter.specialty.title') + ': ' +
              specialties}
            onClick={() => this.customCardOnClick(provider.id)}>
            <div>
              <Link to={{ pathname: `tel: ${provider.phone}` }} target='_blank' className={styles.cardRow}>
                <Phone className={styles.cardImage} />
                {provider.phone}
              </Link>
              <Link to={{ pathname: `mailto: ${provider.email}` }} target='_blank' className={styles.cardRow}>
                <Email className={styles.cardImage} />
                {provider.email}
              </Link>
            </div>
          </CustomCard>
        );
      }
    });
    if (providersCustomCards.length == 0) {
      providersCustomCards.push(
        <div key='no-result' className={styles.noResult}>
          <img src={process.env.PUBLIC_URL + '/assets/img/find-a-provider/no_result_illust.svg'}
            className={styles.noResultImg} />
          <div className={styles.noResultTitle}>
            {this.props.i18n.t('find-a-provider:searchNotFoundTitle')}
          </div>
          <div className={styles.noResultDesc}>
            {this.props.i18n.t('find-a-provider:searchNotFoundDescription1')}
            <span> <Link to='/contactus'>{this.props.i18n.t('find-a-provider:contactUs')}</Link> </span>
            {this.props.i18n.t('find-a-provider:searchNotFoundDescription2')}
          </div>
        </div>
      );
    }
    return providersCustomCards;
  }

  customCardOnClick = (id: string) => {
    this.props.history.push(`/findaprovider/${id}`);
  }

  filterProvider = (provider: Providers) => {
    let providerGoesThrough = false;
    Object.keys(this.filterPanel).forEach((element) => {
      if (element === 'specialties') {
        provider[element].forEach((specialty: Specialty) => {
          if (this.filterPanel[element].optionsActive[this.filterPanel[element]
            .optionsCode.indexOf(specialty.description)]) {
            providerGoesThrough = true;
          }
        });
      } else if (element === 'treatments') {
        provider[element].forEach((treatment: Treatment) => {
          if (this.filterPanel[element].optionsActive[this.filterPanel[element]
            .optionsCode.indexOf(treatment.type)]) {
            providerGoesThrough = true;
          }
        });
      } else if (this.filterPanel[element].optionsActive[this.filterPanel[element]
        .optionsCode.indexOf(provider[element])]) {
        providerGoesThrough = true;
      }
    });
    return providerGoesThrough;
  }

  onChangeFilter = () => {
    Object.keys(this.filterPanel).forEach((element, index) => {
      this.filterPanel[element].optionsActive = this.arrayRef[index].state.filters;
    });
    this.customCards = this.createProviders(this.allProviders);
    this.forceUpdate();
  }

  render() {
    return (
      <div className='page-container'>
        <h1> {this.props.i18n.t('find-a-provider:title')} </h1>
        <div className={styles.description}>
          {this.props.i18n.t('find-a-provider:description')}
        </div>
        <Row className={styles.row}>
          <Col xs={12} lg={3}>
            <div className={styles.filterTitle}>Filter</div>
            <div className={styles.filters}>
              {this.createCheckboxPanel()}
            </div>
            <CustomButton id='find-a-provider-clear-filter' type='button' label={this.props.i18n.t('find-a-provider:clear_filters')}
              onClick={this.clearFilters} ariaLabel="Clear Filters"
              imgSrc={process.env.PUBLIC_URL + '/assets/icons/undo-icon.svg'}>
            </CustomButton>
          </Col>
          <Col xs={12} lg={9} className={styles.providers}>
            {this.customCards}
          </Col>
        </Row>
      </div>
    );
  }
}

export default FindAProvider;
