import React from 'react';

interface EmailProp {
  className?: string;
}

class Email extends React.Component<EmailProp> {
  render() {
    return (
      <svg className={this.props.className} aria-label="email" width="24" height="24" viewBox="0 0 18 18" fill="#808284" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.68898 3.94916C10.0632 3.94916 11.358 4.55835 12.307 5.51066V5.51348C12.307 5.05616 12.6147 4.71023 13.0399 4.71023H13.1479C13.819 4.71023 13.9534 5.34304 13.9534 5.54273L13.9562 12.6499C13.9095 13.1157 14.4372 13.3564 14.7302 13.0572C15.8693 11.8855 17.2345 7.02773 14.0209 4.21523C11.0239 1.59004 7.00148 2.02373 4.86285 3.49748C2.58979 5.06798 1.13685 8.53691 2.54816 11.7972C4.08941 15.3516 8.49548 16.4119 11.1179 15.3544C12.4454 14.8184 13.0574 16.6105 11.6775 17.1972C9.59798 18.0842 3.80254 17.9937 1.09579 13.3064C-0.732898 10.14 -0.636148 4.57016 4.21598 1.68454C7.92454 -0.524399 12.8177 0.0876013 15.7675 3.16729C18.85 6.38985 18.6717 12.4193 15.6623 14.7632C14.2994 15.8292 12.2755 14.7925 12.2901 13.2394L12.2749 12.7332C11.326 13.6731 10.0632 14.2244 8.68898 14.2244C5.97041 14.2244 3.57698 11.8304 3.57698 9.1146C3.57698 6.3696 5.97041 3.95029 8.68898 3.95029V3.94916ZM12.1084 8.91829C12.0055 6.92873 10.5289 5.7306 8.74466 5.7306H8.67716C6.62066 5.7306 5.47823 7.3506 5.47823 9.18716C5.47823 11.2465 6.85804 12.547 8.66873 12.547C10.6898 12.547 12.0167 11.0682 12.1135 9.31879L12.1084 8.91829Z" />
      </svg>
    );
  }
}

export default Email;
