import React from 'react';
import styles from './Jobs.module.css';

class Jobs extends React.Component {
  render() {
    return (
      <div className='page-container'>
        Coming Soon ...
      </div>
    );
  }
}

export default Jobs;
