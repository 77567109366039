import axios from 'axios';
import React, { FormEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GeneralDonation from '../../assets/svg/GeneralDonation/GeneralDonation';

import GeneralDonationProfile from '../../assets/svg/GeneralDonationProfile/GeneralDonationProfile';

import Checkbox from '../../components/checkbox/checkbox/Checkbox';
import CustomButton from '../../components/custom-button/CustomButton';
import CustomFormField, { CustomFormFieldState } from '../../components/custom-form-field/CustomFormField';
import IconButton from '../../components/icon-button/IconButton';
import LabeledFormField from '../../components/labeled-form-field/LabeledFormField';
import PhoneNumber, { PhoneNumberState } from '../../components/phone-number/PhoneNumber';
import TextButton from '../../components/text-button/TextButton';

import client from '../../utils/apollo';
import { GET_CLIENTS_BY_ID } from '../../utils/queries/clients';

import styles from './PaymentGateway.module.css';

interface Client {
  age: number;
  description: string;
  isComplete: boolean;
  name: string;
  numberOfSponsors: number;
  profilePicture: Cover;
  raised: number;
}

interface Cover {
  name: string;
  url: string;
}


interface PaymentGatewayProps {
  i18n: any;
  history: any;
}

interface PaymentGatewayState {
  formInput: FormInput;
  editDonationProfileMode: boolean;
  editGeneralDonationMode: boolean;
  isDisabled: boolean;
  paymentType: PaymentType;
  client: Client;
  acceptPaymentTermsAndAgreement: boolean;
}

interface FormInput {
  // Donor Information (tracked internally by Merajut Hati)
  name: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  additionalNotes: string;

  // Billing Information (for payment purposes)
  firstName: string;
  lastName: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  securityCode: string;
  address: string;
  city: string;
  postalCode: string;

  // Donation Amounts
  bhDonationAmount: string;
  generalDonationAmount: string;
  totalDonationAmount: string;

  clientId: string;
}

enum PaymentType {
  GENERAL = 'general',
  BERBAGI_HATI = 'berbagihati',
}

class PaymentGateway extends React.Component<PaymentGatewayProps, PaymentGatewayState> {
  constructor(props: PaymentGatewayProps) {
    super(props);

    // parse query params
    const url = new URL(window.location.toString());
    const params = new URLSearchParams(url.search);

    let qpPaymentType = PaymentType.GENERAL;
    const qpType = params.get('type') as PaymentType;
    if (Object.values(PaymentType).includes(qpType)) {
      qpPaymentType = qpType;
    }

    const qpClientId = params.get('clientId') || '';
    // TODO: query for a specific client by id

    const qpAmount = Number(params.get('amount'));
    const qpBerbagiHatiDonationAmount = qpPaymentType === PaymentType.BERBAGI_HATI && Number.isFinite(qpAmount) ? qpAmount : 0;
    const qpGeneralDonationAmount = qpPaymentType === PaymentType.GENERAL && Number.isFinite(qpAmount) ? qpAmount : 0;
    const qpTotalDonationAmount = qpPaymentType === PaymentType.BERBAGI_HATI ? qpBerbagiHatiDonationAmount : qpGeneralDonationAmount;

    this.state = {
      formInput: {
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        additionalNotes: '',
        firstName: '',
        lastName: '',
        cardNumber: '',
        expMonth: '',
        expYear: '',
        securityCode: '',
        address: '',
        city: '',
        postalCode: '',
        bhDonationAmount: qpBerbagiHatiDonationAmount.toString(),
        generalDonationAmount: qpGeneralDonationAmount.toString(),
        totalDonationAmount: qpTotalDonationAmount.toString(),
        clientId: qpClientId
      },
      editDonationProfileMode: false,
      editGeneralDonationMode: qpPaymentType === PaymentType.GENERAL,
      isDisabled: true,
      paymentType: qpPaymentType,
      client: {} as Client,
      acceptPaymentTermsAndAgreement: false
    };
  }

  componentDidMount = () => {
    if (this.state.formInput.clientId) {
      client.query({
        query: GET_CLIENTS_BY_ID,
        variables: {
          id: this.state.formInput.clientId
        }
      })
        .then(res => {
          const client = res.data.clients[0];
          this.setState({
            client
          });
        });
    }
  }

  render() {
    return (
      <div className='page-container'>
        <div>Payment integration coming soon! Please use QRIS to donate</div>
        <div>
          <img className={styles.qrisImage} src={process.env.PUBLIC_URL + '/assets/png/qris.png'} alt="qris" />
        </div>
        <div>
          <Link to='/contactus'>Contact Us</Link>
        </div>
      </div>
    );
  }

  // TODO: Uncomment after we sort out the legal documents with Midtrans
  // render() {
  //   return (
  //     <div className='page-container'>
  //       <form onSubmit={this.submit}>
  //         <h1>{this.props.i18n.t('payment-gateway:donationSummary')}</h1>
  //         <Row>
  //           <Col lg={6}>
  //             {/* Donation Profile */}
  //             {this.getBerbagiHatiDonationComponent(this.state.paymentType === PaymentType.BERBAGI_HATI)}

  //             {/* General Donation */}
  //             {this.getGeneralDonationComponent()}

  //             {/* Total Amount */}
  //             <div className={styles.totalDonationAmountContainer}>
  //               <span className={styles.totalAmountText}>
  //                 {this.props.i18n.t('payment-gateway:totalAmount')}
  //               </span>
  //               <span className={styles.totalDonationAmount}>
  //                 IDR{this.formatNumber(this.state.formInput.totalDonationAmount)}
  //               </span>
  //             </div>

  //             {/* Available Payment Methods */}
  //             <div className={styles.availablePaymentsContainer}>
  //               <h3>{this.props.i18n.t('payment-gateway:availablePaymentMethods')}</h3>
  //               <div className={styles.availablePaymentMethodsImgContainer}>
  //                 <img className={styles.availablePaymentMethodsImg}
  //                   src={`${process.env.PUBLIC_URL}/assets/card-type/visa.png`} />

  //                 <img className={styles.availablePaymentMethodsImg}
  //                   src={`${process.env.PUBLIC_URL}/assets/card-type/mastercard.png`} />

  //                 <img className={styles.availablePaymentMethodsImg}
  //                   src={`${process.env.PUBLIC_URL}/assets/card-type/jcb.png`} />

  //                 <img className={styles.availablePaymentMethodsImg}
  //                   src={`${process.env.PUBLIC_URL}/assets/card-type/american-express.png`} />
  //               </div>
  //             </div>
  //           </Col>
  //           <Col lg={6} className={styles.donorInformationContainer}>
  //             {/* Donor Information */}
  //             <h2>{this.props.i18n.t('payment-gateway:donorInformation')}</h2>
  //             <div className={styles.donorInformationDesc}>
  //               {this.props.i18n.t('payment-gateway:donorInformationDescription')}
  //             </div>
  //             <div className={styles.donationFormContainer}>
  //               <CustomFormField name='name' placeholder={this.props.i18n.t('payment-gateway:name')} onChange={this.setField}
  //                 value={this.state.formInput.name}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <CustomFormField name='email' placeholder={this.props.i18n.t('payment-gateway:email')} onChange={this.setField}
  //                 value={this.state.formInput.email}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <PhoneNumber name='phoneNumber' onChange={this.setField} />
  //               <CustomFormField name='companyName' placeholder={this.props.i18n.t('payment-gateway:companyName')}
  //                 value={this.state.formInput.companyName} isOptional={true} onChange={this.setField}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <CustomFormField name='additionalNotes' placeholder={this.props.i18n.t('payment-gateway:additionalNotes')}
  //                 value={this.state.formInput.additionalNotes} isOptional={true} onChange={this.setField}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //             </div>

  //             {/* Billing Information */}
  //             <h2>{this.props.i18n.t('payment-gateway:billingInformation')}</h2>
  //             <div className={styles.donationFormContainer}>
  //               <CustomFormField name='firstName' placeholder={this.props.i18n.t('payment-gateway:firstName')} onChange={this.setField}
  //                 value={this.state.formInput.firstName}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <CustomFormField name='lastName' placeholder={this.props.i18n.t('payment-gateway:lastName')} onChange={this.setField}
  //                 value={this.state.formInput.lastName}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <CustomFormField type='tel' pattern='\d*' maxLength={19}
  //                 autoComplete='cc-number'
  //                 name='cardNumber' placeholder={this.props.i18n.t('payment-gateway:cardNumber')} onChange={this.setField}
  //                 value={this.state.formInput.cardNumber}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //               <Row>
  //                 <Col xs={4}>
  //                   <CustomFormField type='tel' pattern='\d*' maxLength={2}
  //                     autoComplete="cc-exp-month"
  //                     name='expMonth' placeholder='MM' onChange={this.setField}
  //                     value={this.state.formInput.expMonth}
  //                     option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //                 </Col>
  //                 <Col xs={4}>
  //                   <CustomFormField type='tel' pattern='\d*' maxLength={2}
  //                     autoComplete="cc-exp-year"
  //                     name='expYear' placeholder='YY' onChange={this.setField}
  //                     value={this.state.formInput.expYear}
  //                     option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //                 </Col>
  //                 <Col xs={4}>
  //                   <CustomFormField type='tel' pattern='\d*' maxLength={4}
  //                     autoComplete="cc-csc"
  //                     name='securityCode' placeholder='CVV' onChange={this.setField}
  //                     value={this.state.formInput.securityCode}
  //                     option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //                 </Col>
  //               </Row>
  //             </div>

  //             {/* Billing Address */}
  //             <h2>{this.props.i18n.t('payment-gateway:billingAddress')}</h2>
  //             <div className={styles.donationFormContainer}>
  //               <CustomFormField name='address' placeholder={this.props.i18n.t('payment-gateway:address')} onChange={this.setField}
  //                 value={this.state.formInput.address}
  //                 option={{ width: styles.donationFormFieldWidth, height: '' }} />

  //               <Row>
  //                 <Col xs={6}>
  //                   <CustomFormField name='city' placeholder={this.props.i18n.t('payment-gateway:city')} onChange={this.setField}
  //                     value={this.state.formInput.city}
  //                     option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //                 </Col>
  //                 <Col xs={6}>
  //                   <CustomFormField name='postalCode' placeholder={this.props.i18n.t('payment-gateway:postalCode')}
  //                     value={this.state.formInput.postalCode}
  //                     onChange={this.setField}
  //                     option={{ width: styles.donationFormFieldWidth, height: '' }} />
  //                 </Col>
  //               </Row>
  //             </div>

  //             {/* Accept Payment Terms and Agreement */}
  //             <div>
  //               {this.props.i18n.t('payment-gateway:checkoutPolicy1')}<b>{this.props.i18n.t('payment-gateway:checkoutPolicy2')}</b>
  //               {this.props.i18n.t('payment-gateway:checkoutPolicy3')}
  //               <Link
  //                 to={{
  //                   pathname: 'https://docs.google.com/document/d/1pePL4jOjX5rI7qEybDHZV4v5T3wixi3p1ItdWKYZVEQ/edit?usp=sharing'
  //                 }}
  //                 target='_blank'>
  //                 {this.props.i18n.t('payment-gateway:checkoutPolicy4')}
  //               </Link>&nbsp;
  //               {this.props.i18n.t('payment-gateway:checkoutPolicy5')}
  //             </div>

  //             <Checkbox
  //               label={this.props.i18n.t('payment-gateway:acceptPaymentTermsAndAgreement')}
  //               onChange={(e) => this.setState({ acceptPaymentTermsAndAgreement: e }, this.checkFormValid)}
  //             />
  //             {/* Submit */}
  //             <div className={styles.submitDonationContainer}>
  //               <CustomButton
  //                 type='submit' id='payment-gateway-submit'
  //                 ariaLabel='Submit Payment' label='Submit'
  //                 isDisabled={this.state.isDisabled} />
  //             </div>
  //           </Col>
  //         </Row>
  //       </form>
  //     </div>
  //   );
  // }

  setField = (type: string, input: CustomFormFieldState | PhoneNumberState) => {
    let bhDonationAmount;
    let generalDonationAmount;
    let totalDonationAmount;
    switch (type) {
      case 'bhDonationAmount':
        bhDonationAmount = Number(input.value);
        generalDonationAmount = Number(this.state.formInput.generalDonationAmount);
        totalDonationAmount = this.getTotalDonationAmount(bhDonationAmount, generalDonationAmount);
        this.setState({
          formInput: {
            ...this.state.formInput,
            [type]: input.value,
            totalDonationAmount
          }
        }, this.checkFormValid);
        return;
      case 'generalDonationAmount':
        bhDonationAmount = Number(this.state.formInput.bhDonationAmount);
        generalDonationAmount = Number(input.value);
        totalDonationAmount = this.getTotalDonationAmount(bhDonationAmount, generalDonationAmount, false);
        this.setState({
          formInput: {
            ...this.state.formInput,
            [type]: input.value,
            totalDonationAmount
          }
        }, this.checkFormValid);
        return;
      default:
        this.setState({
          formInput: {
            ...this.state.formInput,
            [type]: input.value
          }
        }, this.checkFormValid);
    }
  }

  submit = (e: FormEvent) => {
    e.preventDefault();

    const donateEndpoint = `${process.env.REACT_APP_MH_DONATE_ENDPOINT}/donate`;
    const berbagiHatiDonationAmount = Number(this.state.formInput.bhDonationAmount);
    const generalDonationAmount = Number(this.state.formInput.generalDonationAmount);
    const payload = {
      berbagiHatiDonationAmount,
      generalDonationAmount,
      paymentType: 'credit_card',
      customerDetails: {
        cardNumber: this.state.formInput.cardNumber,
        cardExpMonth: this.state.formInput.expMonth,
        cardExpYear: this.state.formInput.expYear,
        cardCvv: this.state.formInput.securityCode,
        billingAddress: {
          firstName: this.state.formInput.firstName,
          lastName: this.state.formInput.lastName,
          email: this.state.formInput.email,
          phoneNumber: this.state.formInput.phoneNumber,
          address: this.state.formInput.address,
          city: this.state.formInput.city,
          postalCode: this.state.formInput.postalCode,
          countryCode: 'IDN' // TODO: research on why this exist (should this be grabbed from phone number country code?)
        }
      },
      name: this.state.formInput.name,
      companyName: this.state.formInput.companyName,
      additionalNotes: this.state.formInput.additionalNotes,
      clientId: this.state.formInput.clientId
    };

    axios.post(donateEndpoint, payload)
      .then(res => {
        const donationAmount = Number((berbagiHatiDonationAmount + generalDonationAmount).toFixed(0));
        this.props.history.push(`/thankyou?name=${payload.name}&donate=${donationAmount}`);
        console.log('donation successful', res);
      })
      .catch(error => {
        // TODO: add better logging here
        alert('Donation Failed');
        console.error(error);
      });
  }

  private checkFormValid = () => {
    // Berbagi Hati
    if (this.state.paymentType === PaymentType.BERBAGI_HATI) {
      if (this.donationValidator(this.state.formInput.bhDonationAmount)
        && (this.donationValidator(this.state.formInput.generalDonationAmount) || !this.state.editGeneralDonationMode)
        && this.donationValidator(this.state.formInput.totalDonationAmount)
        && this.state.formInput.name
        && this.state.formInput.email
        && this.state.formInput.phoneNumber
        && this.state.formInput.firstName
        && this.state.formInput.lastName
        && this.state.formInput.cardNumber
        && this.state.formInput.expMonth
        && this.state.formInput.expYear
        && this.state.formInput.securityCode
        && this.state.formInput.address
        && this.state.formInput.city
        && this.state.formInput.postalCode
        && this.state.acceptPaymentTermsAndAgreement) {
        this.setState({
          isDisabled: false
        });
      } else {
        this.setState({
          isDisabled: true
        });
      }

      // General Donation
    } else {
      if (this.donationValidator(this.state.formInput.generalDonationAmount)
        && this.donationValidator(this.state.formInput.totalDonationAmount)
        && this.state.formInput.name
        && this.state.formInput.email
        && this.state.formInput.phoneNumber
        && this.state.formInput.firstName
        && this.state.formInput.lastName
        && this.state.formInput.cardNumber
        && this.state.formInput.expMonth
        && this.state.formInput.expYear
        && this.state.formInput.securityCode
        && this.state.formInput.address
        && this.state.formInput.city
        && this.state.formInput.postalCode
        && this.state.acceptPaymentTermsAndAgreement) {
        this.setState({
          isDisabled: false
        });
      } else {
        this.setState({
          isDisabled: true
        });
      }
    }
  }

  private setGeneralDonationAmount(amount: string) {
    const bhDonationAmount = Number(this.state.formInput.bhDonationAmount);
    const generalDonationAmount = Number(amount);
    const totalDonationAmount = this.getTotalDonationAmount(bhDonationAmount, generalDonationAmount, false);

    this.setState({
      formInput: {
        ...this.state.formInput,
        generalDonationAmount: amount,
        totalDonationAmount
      }
    });
  }

  private getTotalDonationAmount(bhDonationAmount: number, generalDonationAmount: number, isBHDonationAmountChange = true): string {
    if (isBHDonationAmountChange) {
      return Number.isFinite(bhDonationAmount)
        ? (bhDonationAmount + generalDonationAmount).toFixed() : this.state.formInput.totalDonationAmount;
    } else {
      return Number.isFinite(generalDonationAmount)
        ? (bhDonationAmount + generalDonationAmount).toFixed() : this.state.formInput.totalDonationAmount;
    }
  }

  private setEditDonationProfileMode(editDonationProfileMode: boolean) {
    this.setState({
      editDonationProfileMode
    });
  }

  private setEditGeneralDonationMode(editGeneralDonationMode: boolean) {
    this.setState({
      editGeneralDonationMode
    });
  }

  private cancelGeneralDonation() {
    const donationAmount = Number(this.state.formInput.bhDonationAmount);
    const generalDonationAmount = 0;
    const totalDonationAmount = this.getTotalDonationAmount(donationAmount, generalDonationAmount, false);
    this.setState({
      formInput: {
        ...this.state.formInput,
        generalDonationAmount: '',
        totalDonationAmount
      },
      editGeneralDonationMode: false
    });
  }

  private getBerbagiHatiDonationComponent(showComponent = true) {
    if (showComponent) {
      return (
        <>
          <div className={styles.donationProfileContainer}>
            <Row>
              <Col xs={3}>
                {this.getBerbagiHatiDonationProfilePicture()}
              </Col>
              <Col xs={9}>
                <h3 className={styles.donationProfileHeader}>
                  Berbagi Hati
                  {this.state.editDonationProfileMode ?
                    null :
                    <IconButton onClick={() => this.setEditDonationProfileMode(true)}
                      src={process.env.PUBLIC_URL + 'assets/icons/edit.svg'} alt='edit' />
                  }
                </h3>
                <div className={styles.donationProfileDescription}>
                  {this.getBerbagiHatiDonationProfileDescription()}
                </div>
                <div className={styles.donationAmount}>
                  {
                    this.state.editDonationProfileMode ?
                      <LabeledFormField
                        errorMessage='Invalid Amount'
                        onChange={this.setField}
                        name={'bhDonationAmount'}
                        label={'IDR'}
                        value={this.state.formInput.bhDonationAmount}
                        validator={this.donationValidator} /> :
                      <>
                        IDR{this.formatNumber(this.state.formInput.bhDonationAmount)}
                      </>
                  }
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    }

    return null;
  }

  private getBerbagiHatiDonationProfilePicture(): JSX.Element {
    return this.state.client.profilePicture ?
      (<img src={this.state.client.profilePicture.url} alt={this.state.client.profilePicture.name}
        className={styles.donationProfileImg} />)
      : (<GeneralDonationProfile className={styles.donationProfileImg} />);
  }

  private getBerbagiHatiDonationProfileDescription(): string {
    return this.state.client.name ? this.state.client.name : this.props.i18n.t('payment-gateway:unspecifiedDonationDesc');
  }

  private getGeneralDonationComponent() {
    return (
      <>
        <div className={styles.generalDonationContainer}>
          <Row>
            {
              this.state.editGeneralDonationMode ?
                <>
                  <Col xs={3}>
                    <GeneralDonation className={styles.generalDonationImg} />
                  </Col>
                  <Col xs={9}>
                    <h3 className={styles.generalDonationHeader}>
                      {this.props.i18n.t('payment-gateway:generalDonation')}
                      {
                        this.state.paymentType === PaymentType.BERBAGI_HATI ?
                          <IconButton onClick={() => this.cancelGeneralDonation()}
                            src={process.env.PUBLIC_URL + 'assets/icons/close.svg'} alt='cancel' />
                          : null
                      }
                    </h3>
                    <Row>
                      <Col className={styles.generalDonationFormContainer}>
                        <TextButton type='button'
                          id='payment-gateway-general-donate-100,000'
                          ariaLabel='Donate 100,000'
                          label='IDR100,000'
                          onClick={() => this.setGeneralDonationAmount('100000')} />

                        <TextButton type='button'
                          id='payment-gateway-general-donate-200,000'
                          ariaLabel='Donate 200,000'
                          label='IDR200,000'
                          onClick={() => this.setGeneralDonationAmount('200000')} />

                        <TextButton type='button'
                          id='payment-gateway-general-donate-400,000'
                          ariaLabel='Donate 400,000'
                          label='IDR400,000'
                          onClick={() => this.setGeneralDonationAmount('400000')} />

                        <TextButton type='button'
                          id='payment-gateway-general-donate-1,000,000'
                          ariaLabel='Donate 1,000,000'
                          label='IDR1,000,000'
                          onClick={() => this.setGeneralDonationAmount('1000000')} />

                        <LabeledFormField
                          errorMessage='Invalid Amount'
                          onChange={this.setField}
                          name='generalDonationAmount'
                          label='IDR'
                          value={this.state.formInput.generalDonationAmount}
                          validator={this.donationValidator}
                        />
                      </Col>
                    </Row>
                  </Col>
                </> :
                <>
                  <Col xs={3}>
                    <span className={styles.addGeneralDonationBtn} onClick={() => this.setEditGeneralDonationMode(true)}>
                      +
                    </span>
                  </Col>
                  <Col xs={9}>
                    <h3 className={styles.generalDonationHeader}>
                      {this.props.i18n.t('payment-gateway:addGeneralDonation')}
                    </h3>
                    <div className={styles.donationProfileDescription}>
                      {this.props.i18n.t('payment-gateway:addGeneralDonationDesc')}
                    </div>
                  </Col>
                </>
            }
          </Row>
        </div>
      </>
    );
  }

  // TODO: refactor functions below into a utility function or validator
  private formatNumber(amount: string) {
    return Number(amount).toLocaleString();
  }

  private donationValidator = (input: string) => {
    const positiveNumberRegex = new RegExp(/^[1-9][0-9]*$/);
    // TODO: minimum donation amount?
    if (positiveNumberRegex.test(input)) {
      return true;
    }
    return false;
  }
}

export default PaymentGateway;
