import { gql } from '@apollo/client';

const GET_MAINTENANCES = gql`
  query GetMaintenances {
    maintenances {
      isOnMaintenance
    }
  }
`;

export default GET_MAINTENANCES;
